import React, { useRef } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LazyImageWrapper from '../common/LazyImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import thaicloth1 from '../../assets/img/Marchandise/thaicloth1.jpg'
import thaicloth2 from '../../assets/img/Marchandise/thaicloth2.jpg'
import thaicloth3 from '../../assets/img/Marchandise/thaicloth3.jpg'
import thaicloth4 from '../../assets/img/Marchandise/thaicloth4.jpg'
import thaicloth5 from '../../assets/img/Marchandise/thaicloth5.jpg'
import thaicloth6 from '../../assets/img/Marchandise/thaicloth6.jpg'
import thaicloth7 from '../../assets/img/Marchandise/thaicloth7.jpg'
import thaicloth8 from '../../assets/img/Marchandise/thaicloth8.jpg'
import Merchandise from '../common/Merchandise';
const Sec_7_Merchan = ({place}) => {
    const sliderRef = useRef(null);
    const slides = [
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth1, price: 599, oldPrice: 1199, rating: 4.6 },
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth2, price: 499, oldPrice: 999, rating: 4.7 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth3, price: 599, oldPrice: 1199, rating: 4.8 },
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth4, price: 399, oldPrice: 799, rating: 4.8 },
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth5, price: 499, oldPrice: 999, rating: 4.8 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth6, price: 499, oldPrice: 999, rating: 4.8 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth7, price: 599, oldPrice: 1199, rating: 4.8 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth8, price: 399, oldPrice: 799, rating: 4.8 },
    ];
    const settings = {
        dots: false, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 1000, // Transition speed
        slidesToShow: 3, // Show 3 slides at a time
        slidesToScroll: 1, // Scroll 1 slide at a time
        autoplay: 3000,
        arrows: true,
        prevArrow: <button className="custom-prev">Prev</button>, // Custom previous button
        nextArrow: <button className="custom-next">Next</button>, // Custom next button
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    const settings2 = {
        dots: false, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 1000, // Transition speed
        slidesToShow: 3, // Show 3 slides at a time
        slidesToScroll: 1, // Scroll 1 slide at a time
        autoplay:10,
        arrows: true,
        prevArrow: <button className="custom-prev">Prev</button>, // Custom previous button
        nextArrow: <button className="custom-next">Next</button>, // Custom next button
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <>
            <section className='section-3 d-flex flex-column justify-content-center align-items-center py-5 px-1' id='Merchandise'>

                <div className=" d-flex flex-column justify-content-center w-75 w-sm-100">
                    <h2 className='fw-bold'>{place} Merchandise</h2>
                    <p className='fs-2 my-5' style={{ textAlign: 'center' }}>{place} Tour Packages - Holiday inspired merchandise and gifts at affordable prices! Discover T-shirts, posters, stickers, home decor, and more—designed and sold exclusively by Chhuttiyan.com. Get ready for your next adventure in style!   </p>
                    <div className="d-flex justify-content-end w-100 pe-2">
                        <h2 className="text-orange fs-2 fw-600">
                            View All<i class="bi bi-arrow-right"></i>
                        </h2>
                    </div>
                    <div className="w-90 w-sm-100">
                        <Slider ref={sliderRef}  {...settings}>
                            {slides.map((slide, index) => (
                                <div className="card border-0 p-3" key={index}>
                                    <div className='border'>
                                        <LazyImageWrapper
                                            src={slide.img}
                                            alt="Image"
                                            className="img-fluid"
                                        />
                                    <div className="card-body  border-gray">
                                        <h2 className='fs-3 text-gray'>
                                            {slide.title}
                                        </h2>

                                        <h5 className='fs-3 my-1'><span className='me-1'>{slide.rating}</span>
                                            {[...Array(5)].map((_, i) => (
                                                <FontAwesomeIcon
                                                    key={i}
                                                    icon={faStar}
                                                    color={i < slide.rating ? "#FFD700" : "#D3D3D3"}
                                                    className=''
                                                />
                                            ))}
                                            </h5>
                                            <div className="d-flex justify-content-between">
                                                <h2 className='fs-1 text-orange'>
                                                    ₹{slide.price} <small className='fs-4 text-dark border-pass'>/  ₹{slide.oldPrice} </small>
                                                </h2>
                                                <span className='fs-2 text-green fw-600 border-0 display-pc ' style={{ textDecoration: 'none !important' }}>50% OFF</span>
                                            </div>

                                            <div className="d-flex justify-content-between">
                                                <h2 className='fs-2 mb-2 text-green fw-500 display-mobile'>
                                                    50% OFF
                                                </h2>
                                                <h5 className='fs-3 mb-2 text-gray'>Hot Deal</h5>
                                            </div>
                                        <div className="d-flex justify-content-between w-100 pe-2">
                                            <button className="btn btn-purple fs-3">Buy Now</button>
                                            <i className="bi bi-heart fs-2"></i>
                                        </div>

                                    </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="d-flex mt-3 justify-content-center">
                            <button
                                className="bg-transparent border-0 fs-2 me-3"
                                onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                            >
                                <i className="bi bi-arrow-left-circle text-orange"></i>
                            </button>
                            <button
                                className="bg-transparent border-0 fs-2"
                                onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                            >
                                <i className="bi bi-arrow-right-circle text-orange  "></i>
                            </button>
                        </div>
                    </div>

                    <div className="row  w-90 w-sm-100 g-2">
                       <Merchandise/>
                    </div>
                </div>
            </section>


        
        </>
    )
}

export default Sec_7_Merchan
