import React, { useState } from "react";
import axios from "axios";

const CCAvenuePayment = () => {
    const [amount, setAmount] = useState("");

    const handlePayment = async () => {
        try {
            const response = await axios.post(
                "https://chhuttiyan.com/backend/public/ccavenue/initiatePayment",
                { amount },
                { headers: { "Content-Type": "application/json" } }
            );

            if (!response.data || !response.data.enc_request) {
                alert("Invalid payment response. Please try again.");
                return;
            }
            
            const { enc_request, access_code, ccavenueUrl } = response.data;
            console.log(response.data);
            // Create form dynamically
            const form = document.createElement("form");
            form.method = "POST";
            form.action = ccavenueUrl;

            form.appendChild(createHiddenInput("encRequest", enc_request));
            form.appendChild(createHiddenInput("access_code", access_code));
         

            document.body.appendChild(form);
            form.submit();

        } catch (error) {
            console.error("Payment Error:", error);
            alert("Payment initiation failed. Check console for details.");
        }
    };

    const createHiddenInput = (name, value) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        return input;
    };

    return (
        <div>
            <h2>CCAvenue Payment</h2>
            <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Enter Amount"
            />
            <button onClick={handlePayment}>Pay Now</button>
        </div>
    );
};

export default CCAvenuePayment;
