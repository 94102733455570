import React, { useRef } from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import post from '../../assets/img/icone/post.png'
import compass from '../../assets/img/icone/compass.png'
import cycle from '../../assets/img/icone/bicycle.png'
import boat from '../../assets/img/icone/boat.png'

import bangkok from '../../assets/img/thailand/bangkok.jpg'
import { useNavigate } from 'react-router-dom';
const Sec_5_Tourpack = () => {
    const navigate = useNavigate()
    const sliderRef = useRef(null);
    const slides = [
        { title: "Bangkok", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.6 },
        { title: "Pattaya", img: bangkok, price: 3999, oldPrice: 6999, rating: 4.7 },
        { title: "Singapore", img: bangkok, price: 4999, oldPrice: 8999, rating: 4.8 },
        { title: "Bali", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.5 },
        { title: "Malaysia", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.5 },
        { title: "Bali", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.5 },
    ];
    const settings = {
        dots: false, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 1000, // Transition speed
        slidesToShow: 3, // Show 3 slides at a time
        slidesToScroll: 1, // Scroll 1 slide at a time
        autoplay: 3000,
        arrows: true,
        prevArrow: <button className="custom-prev">Prev</button>, // Custom previous button
        nextArrow: <button className="custom-next">Next</button>, // Custom next button
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <>
            <section className='section-3 d-flex justify-content-center align-items-center py-5 p-2'>

                <div className=" d-flex flex-column justify-content-center w-75 w-sm-100">
                    <h2 className='fw-bold'>Tour Packages </h2>
                    <p className='fs-2 my-5' style={{ textAlign: 'center' }}>If you are looking for a memorable Chandigarh trip, Chhuttiyan.com holiday packages are the right choice for you. Chhuttiyan.com offers the widest range of customizable Chandigarh tour to suit every kind of traveler from Chandigarh, Ambala, Ludhiana, Jalandhar, Amritsar, All Our India  </p>
                    <div className="d-flex justify-content-end w-90 pe-2">
                        <h2 className="text-orange fs-2 fw-600">
                            View All<i class="bi bi-arrow-right"></i>
                        </h2>
                    </div>
                    <div className="w-90 w-sm-100">
                        <Slider ref={sliderRef}  {...settings}>
                            {slides.map((slide, index) => (
                                <div className="card p-3 border-0 " key={index}>
                                    <div
                                        className="cardtourimg  d-flex flex-column justify-content-center"
                                        style={{
                                            background: `url(${slide.img}) center/cover no-repeat`,
                                            height: "200px"
                                            
                                        }}
                                    >
                                        <h2 className="text-white">{slide.title}</h2>
                                        <button className="btn btn-theme fs-4">Explore</button>
                                    </div>
                                    <div className="card-body border border-gray shadow shadow-md p-smm-1 p-2">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h2 className='fs-1 text-orange'>
                                                ₹{slide.price} <small className='fs-4 text-dark'>/ night</small>
                                            </h2>
                                            <h5 className='fs-2'> {slide.rating}
                                                <FontAwesomeIcon

                                                    icon={faStar}
                                                    color='#FFD700'
                                                    className='ms-1'
                                                />
                                            </h5>

                                          </div>
                                       <div className="d-flex align-items-center justify-content-between">
                                        <h2 className="text-muted text-decoration-line-through fs-3">
                                            ₹{slide.oldPrice}
                                        </h2>
     
                                        </div>
                                        <h5 className='fs-3 my-2 text-gray'>If you are looking for a memorable Chandigarh trip </h5>
                                        <div className="d-flex justify-content-start my-3">
                                            <img src={post} className='me-3 my-2 bookingicone touricone' height={40} />
                                            <img src={compass} className='me-3 my-2 bookingicone touricone' height={40} />
                                            <img src={cycle} className='me-3 my-2 bookingicone touricone' height={40} />
                                            <img src={boat} className='me-3 my-2 bookingicone touricone' height={40} />
                                        </div>
                                        <button className="btn btn-purple fs-3" onClick={() => { navigate("/thailand-tour-packages", { state: { section: 'toursection' } }); }}>Book Now</button>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        <div className="d-flex mt-3 justify-content-center">
                            <button
                                className="bg-transparent border-0 fs-2 me-3"
                                onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                            >
                                <i className="bi bi-arrow-left-circle text-orange"></i>
                            </button>
                            <button
                                className="bg-transparent border-0 fs-2"
                                onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                            >
                                <i className="bi bi-arrow-right-circle text-orange  "></i>
                            </button>
                        </div>
                    </div>
                    <div className="row w-90 w-sm-100">
                        {slides.map((slide, index) => (
                            <div className="col-lg-4 col-md-6 col-6">
                                <div className="card p-3 border-0" key={index}>
                                    <div
                                        className="cardtourimg  d-flex flex-column justify-content-center"
                                        style={{
                                            background: `url(${slide.img}) center/cover no-repeat`,
                                            height: "200px",
                                        }}
                                    >
                                        <h2 className="text-white">{slide.title}</h2>
                                        <button className="btn btn-theme fs-4">Explore</button>
                                    </div> 
                                    <div className="card-body border border-gray shadow shadow-md p-smm-1 p-3">
                                        <div className="d-flex align-items-center justify-content-between  ">
                                            <h2 className='fs-1 text-orange'>
                                                ₹{slide.price} <small className='fs-4 text-dark'>/ night</small>
                                            </h2>
                                            <h5 className='fs-2'> {slide.rating}
                                                <FontAwesomeIcon
                                        
                                                    icon={faStar}
                                                    color='#FFD700'
                                                    className='ms-1'
                                                />
                                            </h5>
                                    

                                        </div>
                                        <h2 className="text-muted text-decoration-line-through fs-3">
                                            ₹{slide.oldPrice}
                                        </h2>
                                        <h5 className='fs-3 my-2 text-gray'>If you are looking for a memorable Chandigarh trip </h5>
                                        <div className="d-flex justify-content-start my-3">
                                            <img src={post} className='me-3 my-2 bookingicone touricone' height={40} />
                                            <img src={compass} className='me-3 my-2 bookingicone touricone' height={40} />
                                            <img src={cycle} className='me-3 my-2 bookingicone touricone' height={40} />
                                            <img src={boat} className='me-3 my-2 bookingicone touricone' height={40} />
                                        </div>
                                        <button className="btn btn-purple fs-3" id='' onClick={() => { navigate("/thailand-tour-packages", { state: { section: 'toursection' } }); }}>Book Now</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sec_5_Tourpack
