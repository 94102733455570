import React from 'react'
import contactbg from '../../assets/img/background/contactbg.jpg'
const Section_8_Contact = () => {
  return (
    <>
          <section className='section-7 d-flex justify-content-center align-items-center py-5 border-top border-dark' id='contact' style={{ background: `url(${contactbg})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="w-75 w-md-100 w-sm-100 px-2">
                  <div className="d-flex">
                      <div className='w-75 text-end display-pc' >
                          {/* <img src={contactbg} className='me-1 contactimg' /> */}
                      </div>
                      <div className='w-50 w-md-100 w-sm-100'>
                          <div className='bg-orange h-100 rounded rounded-4 p-4'>
                              <h2 className='fs-1 '>Get In Touch</h2>
                              <div className="row">
                                  <div className="col-6 p-2">
                                      <h3 className='fs-3 fw-400'>Given Name</h3>
                                      <input type='text' className='fs-3 form-control py-2  ' placeholder='Given Name' />
                                  </div>
                                  <div className="col-6 p-2">
                                      <h3 className='fs-3 fw-400'>Family Name</h3>
                                      <input type='text' className='fs-3 form-control py-2  ' placeholder='Family Name' />
                                  </div>
                                  <div className="col-6 p-2">
                                      <h3 className='fs-3 fw-400'>Email</h3>
                                      <input type='text' className='fs-3 form-control py-2  ' placeholder='Email' />
                                  </div>
                                  <div className="col-6 p-2">
                                      <h3 className='fs-3 fw-400'>Phone</h3>
                                      <input type='text' className='fs-3 form-control py-2  ' placeholder='Phone' />
                                  </div>
                                  <div className="col-12 p-2">
                                      <h3 className='fs-3 fw-400'>Subject</h3>
                                      <input type='text' className='fs-3 form-control py-2  ' placeholder='Subject' />
                                  </div>
                                  <div className="col-12 p-2">
                                      <h3 className='fs-3 fw-400'>Message</h3>
                                      <textarea className='form-control fs-3' placeholder='Message'></textarea>
                                  </div>
                                  <div className="col-12 p-2">
                                      <button className='btn btn-purple fs-3 w-50'> Send</button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section> 
    </>
  )
}

export default Section_8_Contact
