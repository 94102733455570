import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import LazyImageWrapper from '../common/LazyImg';

import phone from '../../assets/img/icone/phone.png'
import whatapp from '../../assets/img/icone/whatapp.png'
import email from '../../assets/img/icone/email.png'
import { useNavigate } from 'react-router-dom';
const Career_popup = () => {
    const [visible, setVisible] = useState(true);
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
            setTimeout(() => {
                setShow(false)
            }, 8000)
        }, 15000)
    }, [])

    return (
        <>
            <Modal show={show} onHide={() => { setShow(false) }} dialogClassName="modal-dynamic" centered>

                <Modal.Body className='p-2 '>
                    <div className="d-sm-flex d-block  justify-content-between align-items-start position-relative">
                        <div className="row " >

                            <div className="col-sm-12 col-12" >

                                <div className=' px-3 text-center order-1 '>
                                    <h2 className='fs-2 fw-600 my-2 text-dark '>Chhuttiyan.com Career</h2>
                                    <h2 className=' fw-600 my-2 text-dark '>Apply For Job</h2>
                                    <h2 className='fs-1 fw-600 my-2 text-dark text-dark '>Salary <span className='text-dark fw-bold'>₹25k-₹35k/- Per Month</span></h2>

      
                                    <button className='btn fs-2 bg-orange text-dark my-2 my-3 ' onClick={() => { navigate('/career') }}>Explore Jobs</button>
                              
            
                                    <h2 className='fs-2 fw-600 my-2 text-orange'>
                                        <span className='text-decoration-underline btn px-0'>
                                            <a href="tel:9530700321" style={{ textDecoration: "none" }}>
                                                <LazyImageWrapper
                                                    src={phone}
                                                    alt="Phone Icon"
                                                    className="bookingicone contacticone me-2"
                                                />

                                            </a></span>
                                        <span className='text-decoration-underline btn px-0'>
                                            <a
                                                href="https://wa.me/9530700321"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none" }}
                                            >
                                                <LazyImageWrapper
                                                    src={whatapp}
                                                    alt="WhatsApp Icon"
                                                    className="bookingicone contacticone mx-2"
                                                />
                                            </a></span>
                                        <span className='text-decoration-underline btn px-0'>

                                            <LazyImageWrapper
                                                src={email}
                                                alt="Image"
                                                className="bookingicone contacticone ms-2"

                                            /></span></h2>
                                    <h2 className='fs-4 fw-600 mt-2 p-1 text-dark'>Powered By</h2>
                                    <h2 className='fs-2 fw-600 mb-2 p-1 text-dark'>Chhuttiyan.com</h2>
                                </div>
                            </div>
                        </div>

                        <div className='position-absolute close-btn'>
                            <button className='btn bg-transparent p-0 ' onClick={() => { setShow(false) }}><i className='bi bi-x fs-1 fw-bold text-dark '></i></button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default Career_popup
