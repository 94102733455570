import React from 'react'
import diving from '../../assets/img/icone/diving.png'
import hotel from '../../assets/img/icone/hotel.png'
import flight from '../../assets/img/icone/flight.png'
import packages from '../../assets/img/icone/packages.png'
import car from '../../assets/img/icone/car.png'
import bike from '../../assets/img/icone/bike.png'
import cloths from '../../assets/img/icone/cloths.png'
import { useNavigate } from 'react-router-dom'
const Booking_mobile = () => {

    const navigate = useNavigate()
  return (
    <>
          <div className='display-mobile  w-100 p-1 bg-light py-3'>

              <div className="row">
                  <div className="col-4 p-1">
                      <div className="p-4 bg-white d-flex flex-column justify-content-center rounded rounded-4 shadow shadow-sm">
                          <img src={diving} className='me-3 bookingicone mb-2' height={50} />
                          <h4 className='fs-2'>ACTIVITIES</h4>
                      </div>
                  </div>
                  <div className="col-4 p-1">
                      <div className="p-4 bg-white d-flex flex-column justify-content-center rounded rounded-4 shadow shadow-sm">
                          <img src={hotel} className='me-3 bookingicone mb-2' height={50} />
                          <h4 className='fs-2'>HOTELS</h4>
                      </div>
                  </div>
                  <div className="col-4 p-1">
                      <div className="p-4 bg-white d-flex flex-column justify-content-center rounded rounded-4 shadow shadow-sm">
                          <img src={flight} className='me-3 bookingicone mb-2' height={50} />
                          <h4 className='fs-2'>FLIGHT</h4>
                      </div>
                  </div>
                  <div className="col-4 p-1">
                      <div className="p-4 bg-white d-flex flex-column justify-content-center rounded rounded-4 shadow shadow-sm btn" onClick={() => { navigate("/tour-packages", { state: { section: 'toursection' } }) }}>
                          <img src={packages} className='me-3 bookingicone mb-2' height={50} />
                          <h4 className='fs-2'>PACKAGES</h4>
                      </div>
                  </div>
                  <div className="col-4 p-1">
                      <div className="p-4 bg-white d-flex flex-column justify-content-center rounded rounded-4 shadow shadow-sm">
                          <img src={car} className='me-3 bookingicone mb-2' height={50} />
                          <h4 className='fs-2'>CAR/BIKES</h4>
                      </div>
                  </div>
                  <div className="col-4 p-1">
                      <div className="p-4 bg-white d-flex flex-column justify-content-center rounded rounded-4 shadow shadow-sm">
                          <img src={cloths} className='me-3 bookingicone mb-2' height={50} />
                          <h4 className='fs-2'>MERCHANDISES</h4>
                      </div>
                  </div>

              </div>
          </div>
    </>
  )
}

export default Booking_mobile
