import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import LazyImageWrapper from '../common/LazyImg';
import packageads from '../../assets/img/background/bg4.jpg'
import phone from '../../assets/img/icone/phone.png'
import whatapp from '../../assets/img/icone/whatapp.png'
import email from '../../assets/img/icone/email.png'
import { useNavigate } from 'react-router-dom';
const Notify_popup = ({ show, id, onClose }) => {
    const [visible, setVisible] = useState(true);
    const navigate = useNavigate()


    return (
        <>
            <Modal show={show} onHide={onClose} className='modal-notification' centered>

                <Modal.Body className='p-2 '>
                    <div className="d-sm-flex d-block  justify-content-between align-items-center position-relative m-3 ">
                        {id == 2 ? <div className="row w-100" >

                            <div className="col-sm-12 col-12" >

                                <div className=' px-3 text-center order-1 '>
                                    <i class="bi bi-check-circle banner-text text-green"></i>
                                    <h2 className=' fw-600 my-2 fs-1 mt-0'>Logout Successfully</h2>
                                   
                                    <h2 className='fs-2 fw-600 my-2 text-orange'>
                                        <span className='text-decoration-underline btn px-0'>
                                            <a href="tel:9530700321" style={{ textDecoration: "none" }}>
                                                <LazyImageWrapper
                                                    src={phone}
                                                    alt="Phone Icon"
                                                    className="bookingicone contacticone me-2"
                                                />
                                            </a></span>
                                        <span className='text-decoration-underline btn px-0'>
                                            <a
                                                href="https://wa.me/9530700321"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none" }}
                                            >
                                                <LazyImageWrapper
                                                    src={whatapp}
                                                    alt="WhatsApp Icon"
                                                    className="bookingicone contacticone mx-2"
                                                />
                                            </a></span>
                                        <span className='text-decoration-underline btn px-0'>

                                            <LazyImageWrapper
                                                src={email}
                                                alt="Image"
                                                className="bookingicone contacticone ms-2"

                                            /></span></h2>
                                    <h2 className='fs-4 fw-600 mt-2 p-1 text-dark'>Powered By</h2>
                                    <h2 className='fs-2 fw-600 mb-2 p-1 text-dark'>Chhuttiyan.com</h2>

                                </div>
                            </div>
                        </div> : id==1? <div className="row w-100" >

                            <div className="col-sm-12 col-12" >

                                <div className=' px-3 text-center order-1 '>

                                    <h2 className=' fw-600 my-2 fs-1 '>Welcome To Chhuttiyan.com</h2>
                                    <h2 className=' fw-600 my-2 fs-2 '>Thailand Tour Packages Start Rs. 13999 Only</h2>

                                    <button className='btn bg-orange  fs-2-sm' onClick={() => { navigate('/thailand-tour-packages') }}>Explore Tour Packages</button>
                                        <h2 className='fs-2 fw-600 my-2 text-orange'>
                                            <span className='text-decoration-underline btn px-0'>
                                                <a href="tel:9530700321" style={{ textDecoration: "none" }}>
                                                    <LazyImageWrapper
                                                        src={phone}
                                                        alt="Phone Icon"
                                                        className="bookingicone contacticone me-2"
                                                    />
                                                </a></span>
                                            <span className='text-decoration-underline btn px-0'>
                                                <a
                                                    href="https://wa.me/9530700321"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <LazyImageWrapper
                                                        src={whatapp}
                                                        alt="WhatsApp Icon"
                                                        className="bookingicone contacticone mx-2"
                                                    />
                                                </a></span>
                                            <span className='text-decoration-underline btn px-0'>

                                                <LazyImageWrapper
                                                    src={email}
                                                    alt="Image"
                                                    className="bookingicone contacticone ms-2"

                                                /></span></h2>
                                    <h2 className='fs-4 fw-600 mt-2 p-1 text-dark'>Powered By</h2>
                                    <h2 className='fs-2 fw-600 mb-2 p-1 text-dark'>Chhuttiyan.com</h2>

                                </div>
                            </div>
                            </div> : id == 3 ? <div className="row w-100" >

                                <div className="col-sm-12 col-12" >

                                    <div className=' px-3 text-center order-1 '>
                                        <i class="bi bi-check-circle banner-text text-green"></i>
                                        <h2 className=' fw-600 my-2 fs-1 mt-0'>Updated Successfully</h2>
                                        <h2 className='fs-4 fw-600 mt-2 p-1 text-dark'>Powered By</h2>
                                        <h2 className='fs-2 fw-600 mb-2 p-1 text-dark'>Chhuttiyan.com</h2>

                                    </div>
                                </div>
                            </div> :''}
                     

                        <div className='position-absolute close-btn'>
                            <button className='btn bg-transparent p-0 ' onClick={onClose}><i className='bi bi-x fs-1 fw-bold  '></i></button>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            {/* <div className='search-pc'>
                    <div
                        className={`btn bg-dark-purple floating-help  bg-blue px-2 shadow shadow-md `} >
                        <div className='d-flex flex-column me-3'>
                            <img src={logo} className='bookingicone' />
                            <i class="bi bi-facebook fs-2 mx-2"></i>
                            <i class="bi bi-youtube fs-2 mx-2"></i>
                            <i class="bi bi-instagram fs-2 mx-2"></i>
                            <i class="bi bi-twitter fs-2 mx-2"></i>
                            <i class="bi bi-pinterest fs-2 mx-2"></i>
                        </div>
           
                    </div>
            </div> */}
        </>
    )
}

export default Notify_popup
