import React, { useRef } from "react"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import star from '../../assets/img/icone/star.png'
import { useNavigate } from "react-router-dom";
function Sec_4_Tourslider() {
    const navigate = useNavigate();
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 6000,
    };

    return (
        <>
            <Slider ref={sliderRef} {...settings} className="w-100">
                <section className="section-4 d-flex flex-column justify-content-center align-items-center py-5 position-relative">
                    {/* Slide 1 */}
                    <div className="header-padding d-flex flex-column justify-content-center">
                        <div className="border bg-white d-flex section-4-container">
                            <div className="d-flex flex-column">
                                <h2 className="fw-bold fs-1">Thailand-Bangkok, Pattaya</h2>
                                <div className="d-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <img key={i} src={star} className="me-1 my-2 ratingstars" height={40} />
                                    ))}
                                </div>
                                <p className="fs-3 my-5 text-center">
                                    Thailand Tour Package Starts at Rs. 13,999 per person on twin sharing basis, 03 nights & 04 days in 3 star hotels; includes Bangkok Pattaya & all transfers.
                                    Family packages start at Rs. 39,999 for 05 nights & 07 days in 3 & 4 star hotels, twin sharing basis, bouffet breakfast, lunch, Sea Surfing,
                                    Dolphin Show, Candle Light dinner on Cruise all transfers in private cars.
                                </p>
                                <button className="btn-purple fs-2 btn" onClick={()=>{navigate('/thailand-tour-packages')}}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center" id='packages'>
                        <button
                            className="bg-transparent border-0 fs-2 me-3"
                            onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                        >
                            <i className="bi bi-arrow-left-circle text-white"></i>
                        </button>
                        <button
                            className="bg-transparent border-0 fs-2"
                            onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                        >
                            <i className="bi bi-arrow-right-circle text-white"></i>
                        </button>
                    </div>
                </section>
                <section className="section-4 d-flex flex-column justify-content-center align-items-center py-5 position-relative">
                    {/* Slide 2 */}
                    <div className="header-padding d-flex flex-column justify-content-center">
                        <div className="border bg-white d-flex section-4-container">
                            <div className="d-flex flex-column">
                                <h2 className="fw-bold fs-1">Singapore - Paradise Awaits</h2>
                                <div className="d-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <img key={i} src={star} className="me-1 my-2 ratingstars" height={40} />
                                    ))}
                                </div>
                                <p className="fs-3 my-5 text-center">
                                    Singapore Tour Package Starts at Rs. 21,999 per person on twin sharing basis, 04 nights & 05 days in 4 star hotels; includes Maldives resorts & all transfers.
                                    Honeymoon packages start at Rs. 69,999 for 07 nights & 08 days, includes luxurious water villas, candlelight dinner, and scuba diving.
                                </p>
                                <button className="btn-purple fs-2 btn" onClick={() => { navigate('/singapore-tour-packages') }} >Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center" id='packages'>
                        <button
                            className="bg-transparent border-0 fs-2 me-3"
                            onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                        >
                            <i className="bi bi-arrow-left-circle text-white"></i>
                        </button>
                        <button
                            className="bg-transparent border-0 fs-2"
                            onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                        >
                            <i className="bi bi-arrow-right-circle text-white"></i>
                        </button>
                    </div>
                </section>
                <section className="section-4 d-flex flex-column justify-content-center align-items-center py-5 position-relative">
                    {/* Slide 2 */}
                    <div className="header-padding d-flex flex-column justify-content-center">
                        <div className="border bg-white d-flex section-4-container">
                            <div className="d-flex flex-column">
                                <h2 className="fw-bold fs-1">Croatia: Truly Asia Adventure</h2>
                                <div className="d-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <img key={i} src={star} className="me-1 my-2 ratingstars" height={40} />
                                    ))}
                                </div>
                                <p className="fs-3 my-5 text-center">
                                    Croatia Tour Package Starts at Rs. 29,999 per person on twin sharing basis, 04 nights & 05 days in 4 star hotels; includes Maldives resorts & all transfers.
                                    Honeymoon packages start at Rs. 49,999 for 07 nights & 08 days, includes luxurious water villas, candlelight dinner, and scuba diving.
                                </p>
                                <button className="btn-purple fs-2 btn" onClick={() => { navigate('/croatia-tour-packages') }}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center" id='packages'>
                        <button
                            className="bg-transparent border-0 fs-2 me-3"
                            onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                        >
                            <i className="bi bi-arrow-left-circle text-white"></i>
                        </button>
                        <button
                            className="bg-transparent border-0 fs-2"
                            onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                        >
                            <i className="bi bi-arrow-right-circle text-white"></i>
                        </button>
                    </div>
                </section>
                <section className="section-4 d-flex flex-column justify-content-center align-items-center py-5 position-relative">
                    {/* Slide 2 */}
                    <div className="header-padding d-flex flex-column justify-content-center">
                        <div className="border bg-white d-flex section-4-container">
                            <div className="d-flex flex-column">
                                <h2 className="fw-bold fs-1">America: Glamorous Holiday</h2>
                                <div className="d-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <img key={i} src={star} className="me-1 my-2 ratingstars" height={40} />
                                    ))}
                                </div>
                                <p className="fs-3 my-5 text-center">
                                    America Tour Package Starts at Rs. 89,999 per person on twin sharing basis, 04 nights & 05 days in 4 star hotels; includes Maldives resorts & all transfers.
                                    Honeymoon packages start at Rs. 199,999 for 07 nights & 08 days, includes luxurious water villas, candlelight dinner, and scuba diving.
                                </p>
                                <button className="btn-purple fs-2 btn" onClick={() => { navigate('/america-tour-packages') }}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center" id='packages'>
                        <button
                            className="bg-transparent border-0 fs-2 me-3"
                            onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                        >
                            <i className="bi bi-arrow-left-circle text-white"></i>
                        </button>
                        <button
                            className="bg-transparent border-0 fs-2"
                            onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                        >
                            <i className="bi bi-arrow-right-circle text-white"></i>
                        </button>
                    </div>
                </section>
                <section className="section-4 d-flex flex-column justify-content-center align-items-center py-5 position-relative">
                    {/* Slide 2 */}
                    <div className="header-padding d-flex flex-column justify-content-center">
                        <div className="border bg-white d-flex section-4-container">
                            <div className="d-flex flex-column">
                                <h2 className="fw-bold fs-1">Serbia: A Hidden Gem</h2>
                                <div className="d-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <img key={i} src={star} className="me-1 my-2 ratingstars" height={40} />
                                    ))}
                                </div>
                                <p className="fs-3 my-5 text-center">
                                    Serbia Tour Package Starts at Rs. 29,999 per person on twin sharing basis, 04 nights & 05 days in 4 star hotels; includes Maldives resorts & all transfers.
                                    Honeymoon packages start at Rs. 49,999 for 07 nights & 08 days, includes luxurious water villas, candlelight dinner, and scuba diving.
                                </p>
                                <button className="btn-purple fs-2 btn" onClick={() => { navigate('/serbia-tour-packages') }}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center" id='packages'>
                        <button
                            className="bg-transparent border-0 fs-2 me-3"
                            onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                        >
                            <i className="bi bi-arrow-left-circle text-white"></i>
                        </button>
                        <button
                            className="bg-transparent border-0 fs-2"
                            onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                        >
                            <i className="bi bi-arrow-right-circle text-white"></i>
                        </button>
                    </div>
                </section>
                <section className="section-4 d-flex flex-column justify-content-center align-items-center py-5 position-relative">
                    {/* Slide 2 */}
                    <div className="header-padding d-flex flex-column justify-content-center">
                        <div className="border bg-white d-flex section-4-container">
                            <div className="d-flex flex-column">
                                <h2 className="fw-bold fs-1">London : Explore the Iconic City</h2>
                                <div className="d-flex">
                                    {[...Array(5)].map((_, i) => (
                                        <img key={i} src={star} className="me-1 my-2 ratingstars" height={40} />
                                    ))}
                                </div>
                                <p className="fs-3 my-5 text-center">
                                    London Tour Package Starts at Rs. 89,999 per person on twin sharing basis, 04 nights & 05 days in 4 star hotels; includes Maldives resorts & all transfers.
                                    Honeymoon packages start at Rs. 199,999 for 07 nights & 08 days, includes luxurious water villas, candlelight dinner, and scuba diving.
                                </p>
                                <button className="btn-purple fs-2 btn" onClick={() => { navigate('/london-tour-packages') }}>Read More</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mt-3 justify-content-center" id='packages'>
                        <button
                            className="bg-transparent border-0 fs-2 me-3"
                            onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                        >
                            <i className="bi bi-arrow-left-circle text-white"></i>
                        </button>
                        <button
                            className="bg-transparent border-0 fs-2"
                            onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                        >
                            <i className="bi bi-arrow-right-circle text-white"></i>
                        </button>
                    </div>
                </section>
            </Slider>



        </>
    )
}
export default Sec_4_Tourslider