import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";

const PaymentResponse = () => {
    const location = useLocation();
    const [paymentData, setPaymentData] = useState(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const encResp = queryParams.get("encResp");

        if (encResp) {
            axios.post("https://chhuttiyan.com/backend/public/ccavenue/verifyPayment", { encResp })
                .then((res) => {
                    setPaymentData(res.data.paymentData);
                })
                .catch((error) => console.error("Error decrypting:", error));
        }
    }, [location]);

    return (
        <div>
            <h2>Payment Response</h2>
            {paymentData ? (
                <pre>{JSON.stringify(paymentData, null, 2)}</pre>
            ) : (
                <p>Processing payment response...</p>
            )}
        </div>
    );
};

export default PaymentResponse;
