import React, {useEffect} from 'react'
import Section_8_Contact from '../components/Sec_8_Contact'
import Header from '../common/Header'
import Footer from '../common/Footer'
import { useLocation } from 'react-router-dom';
const Contact = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
      <>
          <Header />
          <div className='paddingtop'>
          <Section_8_Contact /> 
          </div>
          <Footer/>
    </>
  )
}

export default Contact
