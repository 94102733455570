import React, { useState, useRef, useEffect } from 'react'
import LazyImageWrapper from '../common/LazyImg';
import Select from "react-select";
import { Tooltip } from 'react-tooltip'
import { motion } from 'framer-motion';
import { Form, Modal } from 'react-bootstrap';

import "flatpickr/dist/themes/material_green.css";
import Header from '../common/Header'
import Footer from '../common/Footer'
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import packageimg from '../../assets/img/thailand/package1.jpg'
import packageimg2 from '../../assets/img/thailand/package2.jpg'
import packageimg3 from '../../assets/img/thailand/package5.jpg'
import packageads from '../../assets/img/thailand/packagead1.jpg'


import Loader from '../common/Loader';
import Loader2 from '../common/Loader2';
import Signup from '../common/Signup';

import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptData } from '../Utility/Encryption';

const Tour_Booking = () => {

    const isAuth = sessionStorage.getItem('isAuth');
    const userid = decryptData(sessionStorage.getItem('uid')) ;
    let { packageid } = useParams()
    const pdfRef = useRef();
    const navigate = useNavigate()
    const [notifyid, setNotifyId] = useState(1);
    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)
    const [loader, setLoader] = useState(false)
    const [btndisabled, setBtnDisabled] = useState(false)
    const [selectedAirport, setSelectedAirport] = useState(null);
    const [selectedThailandAirport, setselectedThailandAirport] = useState(null);
    const [selectedTime, setselectedTime] = useState(null);
    const [bookingid, setBookingId] = useState(-1)
    const [paymentid, setPaymentId] = useState('')
    const [step, setStep] = useState(1)

    const [passengerindex, setPassengerIndex] = useState(1)
    const [child, setChild] = useState(0)
    const [guest, setGuest] = useState(1)
    const [passenger, setPassenger] = useState({});
    const [packageData, setPackage] = useState({});
    const [form, setForm] = useState({ 'adult': guest, 'child': child, 'package_id': packageid, 'booking_id': bookingid, 'mobile_code': '+91', 'paymentId': '', step1: 0, step2: 0, step3: 0, step4: 0 });
    const [flight, setFlight] = useState('');
    const [errors, setErrors] = useState({});
    const [bordercolor, setBordercolor1] = useState('');
    const [bordercolor2, setBordercolor2] = useState('');
    const [bordercolor3, setBordercolor3] = useState('');

    const formatDate = (dateString) => {
        if (!dateString) return ""; // Handle empty value
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB").replace(/\//g, "-"); // Convert / to -
    };


    useEffect(() => {
        setForm({
            ...form,
            'adult': guest,
            'child': child
        });
    }, [guest, child]);

    const getPackage = async() => {
        try {
            const response = await axios.get(`https://chhuttiyan.com/backend/public/getpackage/${packageid}`)
            if (response.data.status == 'success') {
                setPackage(response.data.data)
            }
            else {
                setPackage([])
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getPackage();
    },[])
    // passenger form
    const handlePassenger = (e) => {
        const { name, value } = e.target;
        const passengerKey = `passenger_${passengerindex}`;

        setPassenger((prevPassengers) => ({
            ...prevPassengers,
            [passengerKey]: {
                ...prevPassengers[passengerKey], // Preserve existing data
                [name]: value,
                'booking_id': bookingid
            },
        }));
        
        validateField(name, value);
    };
  
    const handleAirport = (name, selectedOption) => {
        // Update form state (storing only value)
        setForm((prevForm) => ({
            ...prevForm,
            [name]: selectedOption ? selectedOption.value : "",
        }));

        // Update relevant state variables
        if (name === "starting_from") {
            setSelectedAirport(selectedOption);
        } else if (name === "destination") {
            setselectedThailandAirport(selectedOption);
        } else if (name === "arrival_time") {
            setselectedTime(selectedOption);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,

        });
        validateField(name, value);
    };
    const validateField = (name, value) => {
        let newErrors = { ...errors };
       
        if (/^email_\d$/.test(name) || name === "email") {
            const allowedDomains = ["gmail.com", "outlook.com", "hotmail.com", "yahoo.co.in", "yahoo.com"];
            const emailRegex = /^[a-zA-Z0-9._%+-]+@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
            const match = value.match(emailRegex);

            if (!match) {
                newErrors[name] = "Please Enter Valid Email"; // Set error for the specific email field
            } else {
                const domain = match[1];
                if (!allowedDomains.includes(domain)) {
                    newErrors[name] = "Please Enter Valid Email"; // Set error only for the current field
                } else {
                    delete newErrors[name]; // Remove error for the specific field if valid
                }
            }
        }

        if (/^phone_\d$/.test(name) || name === "mobile") {
            const mobileRegex = /^[6-9]\d{9}$/; // Indian mobile number validation (10 digits, starting with 6-9)

            if (!mobileRegex.test(value)) {
                newErrors[name] = "Please Enter Valid Mobile No."; // Set error only for the specific field
            } else {
                delete newErrors[name]; // Remove error if valid
            }
        }

        setErrors(newErrors);
    };


    const handleSubmit = async (e, id) => {
        e.preventDefault()
        if (id == 3) {
            setLoader(true)
            try {
                const response = await axios.post(`https://chhuttiyan.com/backend/public/booking/${id}`, passenger)
                if (response.data.status == 'success') {
                    BookingData()
                    setShow2(false)
                    setLoader(false)
                    setBordercolor1('')
                    setBordercolor2('')
                } else {
                    console.log('Data insert Error')
                }
            } catch (error) {
                console.log(error)
            }
        } else if (id == 5) {
            if (form.step1 == 0) {
                setBordercolor1('border-danger border-4')
                HandleScroll("idstep1")
                return
            } else if (form.step2 == 0) {
                setBordercolor2('border-danger border-4')
                HandleScroll("idstep2")
                return
            } else if (form.step3 == 0) {
                setBordercolor3('border-danger border-4')
                HandleScroll("idstep3")
                return
            } else {
                setBordercolor1('')
                setBordercolor2('')
                setBordercolor3('')
                return
            }
            setLoader(true)
            if (form.booking_method == 'Booked Online') {
                handlePayment()
            } else {

                try {
                    const response = await axios.post(`https://chhuttiyan.com/backend/public/payment`, { 'booking_id': bookingid, 'paymentId':'', 'booking_method': 'Booked Through Company' })
                    if (response.data.status == 'success') {
                        setTimeout(() => {
                            setLoader(false)
                            setNotifyId(2)
                        }, 2000)

                        BookingData()
                    } else {
                        console.log('Data insert Error')
                    }
                } catch (error) {
                    console.log(error)
                }
                setNotifyId(2);
                setShow(true)
            }
            // setShow3(true);
        } else {
            setLoader(true)
            try {
                const response = await axios.post(`https://chhuttiyan.com/backend/public/booking/${id}`, form)
                if (response.data.status == 'success') {
                    setBookingId(response.data.data)
                    BookingData()
                    setLoader(false)
                    setBordercolor1('')
                    setBordercolor2('')
                } else {
                    console.log('Data insert Error')
                }
            } catch (error) {
                console.log(error)
            }
        }

    }
  
    const handleSteps = (id, sectionid) => {

        if (id == 2 && form.step1 == 0) {
            setBordercolor1('border-danger border-4')
            HandleScroll(sectionid)
        } else if (id == 3) {
            if (form.step1 == 0) {
                sectionid = 'idstep1'
                setBordercolor1('border-danger border-4')
            } else if (form.step2 == 0) {
                sectionid = 'idstep2'
                setBordercolor2('border-danger border-4')
            } else  {
                setBordercolor2('')
                setBordercolor1('')
            }
            HandleScroll(sectionid)
        }
    }
    const HandleScroll=(sectionid)=>{
        const section = document.getElementById(sectionid);
        if (section) {
            const headerOffset = 270; // Adjust this based on your header height
            const elementPosition = section.getBoundingClientRect().top + window.scrollY;
            const offsetPosition = elementPosition - headerOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });
        }
    }
    // Booking Details
    const BookingData = async () => {
        if (bookingid != 0) {
            try {
                const response = await axios.get(`https://chhuttiyan.com/backend/public/getbooking/${bookingid}`)
                if (response.data.status == 'success') {
                    setForm(response.data.data)

                } else {
                    console.log("booking data fetching error");
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    useEffect(() => {
        BookingData()
    }, [bookingid])
    // Payment Gateway
    // const handlePayment = async () => {
    //     setShow(true)
    //     // setShow3(true)
    //     const response = await fetch('https://chhuttiyan.com/backend/public/createOrder');
    //     const data = await response.json();

    //     const options = {
    //         key: 'rzp_test_E7c1OxwzqpZUy5',
    //         amount: ((parseInt(packageData.price) * (guest + child) - ((parseInt(packageData.price) * guest) / 2) + (18 / 100) * ((parseInt(packageData.price) * guest) / 2) + (2 / 100) * ((parseInt(packageData.price) * guest) / 2))).toFixed(2) * 100,
    //         currency: data.currency,
    //         order_id: data.orderId,
    //         name: 'chhuttiyan.com',
    //         description: 'Tour Package Booking',
    //         "image": packageimg,
    //         "theme": {
    //             "color": "#8F50FA" // Set your preferred color
    //         },
    //         handler: async (response_payment) => {
    //             if (response_payment.razorpay_payment_id) {
    //                 setPaymentId(response_payment.razorpay_payment_id);

    //                 try {
    //                     const response = await axios.post(`https://chhuttiyan.com/backend/public/payment`, { 'booking_id': bookingid, 'paymentId': response_payment.razorpay_payment_id, 'booking_method': 'Booked Online' })
    //                     if (response.data.status == 'success') {
    //                         setTimeout(() => {
    //                             setLoader(false)
    //                             setNotifyId(2)
    //                         }, 2000)

    //                         BookingData()
    //                     } else {
    //                         console.log('Data insert Error')
    //                     }
    //                 } catch (error) {
    //                     console.log(error)
    //                 }
    //                 //   await sendEmailWithModalContent();

    //             } else {
    //                 console.log("Payment verification failed.");
    //             }
    //         },
    //         prefill: {
    //             name: 'Test User',
    //             email: 'chhuttiyan@gmail.com',
    //             contact: '9530700321',
    //         },
    //         notes: {
    //             custom_field2: "Custom Value 2",
    //         },

    //     };

    //     const rzp1 = new window.Razorpay(options);
    //     rzp1.open();

    //     // Handle payment failure
    //     rzp1.on('payment.failed', function (response) {

    //         alert('Payment failed. Please try again or contact support.');

    //         window.location.href = '/payment-failed';
    //     });
    // };

    const handlePayment = async () => {
        try {
            let amount = (
                (parseInt(packageData.price) * (guest + child)) -
                (parseInt(packageData.price) * guest) / 2 +
                (18 / 100) * ((parseInt(packageData.price) * guest) / 2) +
                (2 / 100) * ((parseInt(packageData.price) * guest) / 2)
            );
            amount = Math.round(amount);
  
            const response = await axios.post(
                "https://chhuttiyan.com/backend/public/ccavenue/initiatePayment",
                { amount },
                { headers: { "Content-Type": "application/json" } }
            );

            if (!response.data || !response.data.enc_request) {
                alert("Invalid payment response. Please try again.");
                return;
            }

            const { enc_request, access_code, ccavenueUrl } = response.data;
   
            // Create form dynamically
            const form = document.createElement("form");
            form.method = "POST";
            form.action = ccavenueUrl;

            form.appendChild(createHiddenInput("encRequest", enc_request));
            form.appendChild(createHiddenInput("access_code", access_code));


            document.body.appendChild(form);
            form.submit();

        } catch (error) {
            console.error("Payment Error:", error);
            alert("Payment initiation failed. Check console for details.");
        }
    };

    const createHiddenInput = (name, value) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        return input;
    };

    
    const sendEmailWithModalContent = async () => {

        const modalElement = document.querySelector('.modal-dynamic .download');

        if (!modalElement) {
            alert("Unable to capture modal content!");
            return;
        }

        try {
            // Temporarily make the modal visible (but still hidden from UI)
            modalElement.style.position = "relative";
            modalElement.style.top = "0px";
            modalElement.style.left = "0px";
            modalElement.style.visibility = "visible";
            modalElement.style.opacity = "1";

            // Small delay to allow rendering
            await new Promise(resolve => setTimeout(resolve, 300));

            // Capture modal content as an image
            const canvas = await html2canvas(modalElement, {
                useCORS: true,
                logging: true,
                scale: 2
            });

            // Convert canvas to a Blob (PNG format)
            const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));

            // Hide the modal again after capturing
            modalElement.style.position = "absolute";
            modalElement.style.top = "-9999px";
            modalElement.style.left = "-9999px";
            modalElement.style.visibility = "hidden";
            modalElement.style.opacity = "0";

            // Prepare data for email
            const emailData = new FormData();
            emailData.append('booking_request', blob, 'booking_request.png');
            emailData.append('booking_id', bookingid);

            // Send email request
            const response = await axios.post('https://chhuttiyan.com/backend/public/bookingrequest', emailData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            // alert(response.data.status === "success" ? "Email sent" : "Failed to send email!");
            setNotifyId(2)
            setShow3(false)
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email.');
        }
    };



    // Authentication
    const handleClose = () => {
        setShow4(false)
        window.location.reload()
    }
    const handleClose2 = () => {
        setShow4(false)
    }
    // useEffect(() => {
    //     if (!isAuth) {
    //         setShow4(true)
    //     } else {
    //         setShow4(false)
    //     }
    // },[])
    useEffect(() => {
        if (isAuth) {
            setForm({
                ...form,
                'userid':userid
            })
        } 
    },[isAuth])


    const adultage = Array.from({ length: 89 }, (_, i) => {
        const age = i + 12;
        return { key: `${age} years`, value: `${age} years` };
    });

    const childage = [
        { key: "1month", value: "1 month" },
        { key: "2months", value: "2 months" },
        { key: "3months", value: "3 months" },
        { key: "4months", value: "4 months" },
        { key: "5months", value: "5 months" },
        { key: "6months", value: "6 months" },
        { key: "7months", value: "7 months" },
        { key: "8months", value: "8 months" },
        { key: "9months", value: "9 months" },
        { key: "10months", value: "10 months" },
        { key: "11months", value: "11 months" },
        ...Array.from({ length: 12 }, (_, i) => {
            const age = i + 1;
            return { key: `${age} years`, value: `${age} years` };
        }),
    ];
    const countryCodes = [
        { code: "+1", name: "United States" },
        { code: "+44", name: "United Kingdom" },
        { code: "+61", name: "Australia" },
        { code: "+33", name: "France" },
        { code: "+49", name: "Germany" },
        { code: "+81", name: "Japan" },
        { code: "+86", name: "China" },
        { code: "+7", name: "Russia" },
        { code: "+971", name: "United Arab Emirates" },
        { code: "+92", name: "Pakistan" },
        { code: "+880", name: "Bangladesh" },
        { code: "+966", name: "Saudi Arabia" },
        { code: "+20", name: "Egypt" },
        { code: "+27", name: "South Africa" },
        { code: "+34", name: "Spain" },
        { code: "+39", name: "Italy" },
        { code: "+55", name: "Brazil" },
        { code: "+62", name: "Indonesia" },
        { code: "+64", name: "New Zealand" },
        { code: "+82", name: "South Korea" },
        { code: "+234", name: "Nigeria" }
    ];
    const airports = [
        { label: "Indira Gandhi International Airport (DEL)", value: "Delhi - DEL" }, // Delhi
        { label: "Chhatrapati Shivaji Maharaj International Airport (BOM)", value: "Mumbai - BOM" }, // Mumbai
        { label: "Kempegowda International Airport (BLR)", value: "Bangalore - BLR" }, // Bangalore
        { label: "Chennai International Airport (MAA)", value: "Chennai - MAA" }, // Chennai
        { label: "Netaji Subhas Chandra Bose International Airport (CCU)", value: "Kolkata - CCU" }, // Kolkata
        { label: "Rajiv Gandhi International Airport (HYD)", value: "Hyderabad - HYD" }, // Hyderabad
        { label: "Cochin International Airport (COK)", value: "Kochi - COK" }, // Kochi
        { label: "Trivandrum International Airport (TRV)", value: "Thiruvananthapuram - TRV" }, // Thiruvananthapuram
        { label: "Sardar Vallabhbhai Patel International Airport (AMD)", value: "Ahmedabad - AMD" }, // Ahmedabad
        { label: "Goa International Airport (GOI)", value: "Goa - GOI" }, // Goa (Dabolim)
        { label: "Mangalore International Airport (IXE)", value: "Mangalore - IXE" }, // Mangalore
        { label: "Pune International Airport (PNQ)", value: "Pune - PNQ" }, // Pune
        { label: "Jaipur International Airport (JAI)", value: "Jaipur - JAI" }, // Jaipur
        { label: "Biju Patnaik International Airport (BBI)", value: "Bhubaneswar - BBI" }, // Bhubaneswar
        { label: "Lokpriya Gopinath Bordoloi International Airport (GAU)", value: "Guwahati - GAU" }, // Guwahati
        { label: "Lal Bahadur Shastri International Airport (VNS)", value: "Varanasi - VNS" }, // Varanasi
        { label: "Sri Guru Ram Dass Jee International Airport (ATQ)", value: "Amritsar - ATQ" }, // Amritsar
        { label: "Chaudhary Charan Singh International Airport (LKO)", value: "Lucknow - LKO" }, // Lucknow
        { label: "Kannur International Airport (CNN)", value: "Kannur - CNN" }, // Kannur
        { label: "Visakhapatnam International Airport (VTZ)", value: "Visakhapatnam - VTZ" }, // Visakhapatnam
        { label: "Dr. Babasaheb Ambedkar International Airport (NAG)", value: "Nagpur - NAG" } // Nagpur
    ];

    const thailandAirports = [
        { label: "Suvarnabhumi Airport (BKK)", value: "Bangkok - BKK" },
        { label: "Don Mueang International Airport (DMK)", value: "Bangkok - DMK" },
        { label: "Phuket International Airport (HKT)", value: "Phuket - HKT" },
        { label: "Chiang Mai International Airport (CNX)", value: "Chiang Mai - CNX" },
        { label: "Hat Yai International Airport (HDY)", value: "Hat Yai - HDY" },
        { label: "Krabi International Airport (KBV)", value: "Krabi - KBV" },
        { label: "Samui International Airport (USM)", value: "Koh Samui - USM" },
        { label: "U-Tapao–Rayong–Pattaya International Airport (UTP)", value: "Pattaya - UTP" }
    ];
    const generateTimeOptions = () => {
        const times = [];
        let hour = 0;
        let minute = 0;

        while (hour < 24) {
            const ampm = hour < 12 ? "AM" : "PM";
            const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour; // Convert 24-hour to 12-hour format
            const formattedMinute = minute === 0 ? "00" : minute;

            times.push({
                label: `${formattedHour}:${formattedMinute} ${ampm}`,
                value: `${formattedHour}:${formattedMinute} ${ampm}`
            });

            minute += 30;
            if (minute === 60) {
                minute = 0;
                hour += 1;
            }
        }

        return times;
    };

    const timeOptions = generateTimeOptions();

    return (
        <>
            <Header />

            <Tooltip id="my-tooltip" className='fs-4' />
            <section className='paddingtop  section-3  header-padding mt-5 bg-white' id='packagebooking' style={{ backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className=''>
                    <motion.h2
                        className="fw-800 fs-1"

                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -30 }}
                        transition={{
                            duration: 1,
                        }}
                    >
             
                        {packageData ? packageData?.package_name :'Tour Package'}
                    </motion.h2>
                    <div className='d-flex justify-content-start my-2'>
                        <h2 className=' text-gray fw-600 fs-2 me-2'>Customizable |</h2>
                        <h2 className=' text-gray fw-600 fs-2'>{packageData.night ? packageData.night : "3"}N/{packageData.day ? packageData.day:'4'}D | Airport To Airport Pickup-Drop</h2>
                        {/* <h3 className='fs-4 p-1 ms-3 border border-purple border-2 text-purple fw-600 rounded-3' >3N/4D</h3> */}
                    </div>
                    <div className='border rounded-4'>
                        <div className="row g-2">
                            <div className="col-12 border-end p-2 d-flex align-items-start justify-content-start">
                                <div className="row">


                                    <div className="col-sm-5 col-12 p-3">

                                        <LazyImageWrapper
                                            src={packageimg}
                                            alt="Image"
                                            className="img-fluid"
                                        />

                                        <div className='border mt-3 py-3 px-2 display-pc'>
                                            <h1 className='beyond fs-1'>Embrace love  with chhuttiyan.com</h1>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-6  p-3">
                                        <div className='row g-2'>
                                            <div className="col-12">
                                                <LazyImageWrapper
                                                    src={packageimg2}
                                                    alt="Image"
                                                    className="img-fluid mb-3"
                                                />
                                                <LazyImageWrapper
                                                    src={packageimg3}
                                                    alt="Image"
                                                    className="img-fluid "
                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-sm-4 col-6  p-2">
                                        <LazyImageWrapper
                                            src={packageads}
                                            alt="Image"
                                            className="img-fluid "
                                        />

                                        <div className='border mt-3 py-3 px-2 display-mobile'>
                                            <h1 className='beyond fs-1'>Embrace Thailand</h1>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* {JSON.stringify(form)}
                            {JSON.stringify(form.booking_method)} */}
                            <div className="col-sm-9 col-12 border-end p-2 bg-light ">
                                <div className='px-3'>
                                    <h1 className='fs-2 fw-bold'>Mandatory Details</h1>
                                    <div className="d-flex justify-content-between">
                                        <h1 className='fs-2 fw-bold text-gray '>STEP 1</h1>
                                        <h1 className='fs-2 fw-bold text-gray '>{form.step1 && form.step1 == '1' ? 'Completed' : 'Pending'}</h1>

                                    </div>
                                </div>
                                <form onSubmit={(e) => { handleSubmit(e, 1) }} >
                                    <div className={`row border  p-3 bg-white  ${bordercolor}`} id='idstep1'>
                                        <div className=" col-12">
                                            <div className='p-1  border mb-2'>
                                                <div className='d-sm-flex d-block justify-content-start py-2'>
                                                    <Form.Check
                                                        type="radio"
                                                        id="book-online"
                                                        name="flight_booking"
                                                        label="Flight Manage By Own"
                                                        value='Flight Manage By Own'
                                                        className="fs-3  me-3"
                                                        onChange={() => { setFlight('Flight Manage By Own') }}
                                                        required
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="book-online"
                                                        name="flight_booking"
                                                        label="Flight With Chhuttiyan.com"
                                                        value='Flight With Chhuttiyan.com'
                                                        className="fs-3 "
                                                        onChange={() => { setFlight('Flight With Chhuttiyan.com') }}
                                                        required
                                                    />
                                                </div>
                                  
                                                <h3 className={`fs-4 text-green ${flight === 'Flight Manage By Own' ? 'display-block' : 'display-none'}`}>Note : You have to reach your given destination airport at given time on your own</h3>
                                  
                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-12">
                                            <div className='p-1'>
                                                <h4 className='text-dark fs-4'>Starting From</h4>
                                                <Select
                                                    options={airports}
                                                    value={selectedAirport}
                                                    onChange={(selectedOption) => handleAirport("starting_from", selectedOption)}
                                                    placeholder="Select Airport"
                                                    name='starting_from'
                                                    isSearchable
                                                    className={`fs-3 ${bordercolor}`}
                                                />
                                            </div>
                                        </div>

                                        <div className={`col-sm-4 col-12 ${flight ==='Flight Manage By Own' ? 'display-block':'display-none'}`}>
                                            <div className="p-1">
                                                <h4 className="text-dark fs-4">Destination (Thailand)</h4>
                                                <Select
                                                    options={thailandAirports} // Thailand Airport List
                                                    value={selectedThailandAirport}
                                                    onChange={(selectedOption) => handleAirport("destination", selectedOption)}
                                                    placeholder="Select Thai Airport"
                                                    name="destination"
                                                    isSearchable
                                                    className={`fs-3 ${bordercolor}`}
                                                    required={form.flight == 'Flight Manage By Own' ? true : false}
                                                />
                                            </div>
                                        </div>
                                        <div className={`col-sm-4 col-12 ${flight === 'Flight Manage By Own' ? 'display-block' : 'display-none'}`}>
                                            <div className="p-1">
                                                <h4 className="text-dark fs-4">Arrival Time</h4>
                                                <Select
                                                    options={timeOptions} // Time List from 12AM to 11:30PM
                                                    value={selectedTime}
                                                    onChange={(selectedOption) => handleAirport("arrival_time", selectedOption)}
                                                    placeholder="Select Time"
                                                    name="arrival_time"
                                                    isSearchable
                                                    className={`fs-3 ${bordercolor}`}
                                                    required={form.flight == 'Flight Manage By Own' ? true : false}
                                                />
                                            </div>
                                        </div>

                                   
                                        <div className="col-sm-4 col-6">

                                            <div className='p-1'>
                                                <h4 className='text-dark fs-4'>Starting Date</h4>
                                                <input type='date' name='starting_date' className='form-control fs-3 text-gray' onChange={handleChange} required />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-6">
                                            <div className='p-1 '>
                                                <h4 className="text-dark fs-4">Guests</h4>
                                                <div className="dropdown">
                                                    <button
                                                        className="btn form-select text-start border fs-3 bg-white"
                                                        type="button"
                                                        id="dropdownMenuButton1"
                                                        data-bs-toggle="dropdown"
                                                        aria-expanded="false"

                                                    >
                                                        {guest} Adults {child != 0 ? ", " + child + ' Child' : ''}
                                                    </button>
                                                    <ul className="dropdown-menu w-100 p-2" aria-labelledby="dropdownMenuButton1">
                                                        <li className="d-flex justify-content-between">
                                                            <h4 className="fs-3">Adults</h4>
                                                            <div
                                                                className="d-flex"
                                                                onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
                                                            >
                                                                <button className="btn " onClick={guest > 0 ? () => setGuest(guest - 1) : undefined} type='button'><i class="bi bi-dash-square fs-3 text-purple"></i></button>
                                                                <h4 className="fs-3 mx-1">{guest}</h4>
                                                                <button className="btn " onClick={guest < 9 ? () => setGuest(guest + 1) : undefined} type='button'><i class="bi bi-plus-square fs-3 text-purple"></i></button>
                                                            </div>
                                                        </li>
                                                        <li className="d-flex justify-content-between">
                                                            <h4 className="fs-3">Child</h4>
                                                            <div
                                                                className="d-flex"
                                                                onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
                                                            >
                                                                <button className="btn " onClick={child > 0 ? () => setChild(child - 1) : undefined} type='button'><i class="bi bi-dash-square fs-3 text-purple"></i></button>
                                                                <h4 className="fs-3 mx-1">{child}</h4>
                                                                <button className="btn " onClick={() => { setChild(child + 1) }} type='button'><i class="bi bi-plus-square fs-3 text-purple"></i></button>
                                                            </div>

                                                        </li>
                                                        {child != 0 ? <h4 className="fs-4 text-green">No Extra Charges For Child Below 3 Years</h4> : ''}


                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-sm-4 col-12 ${flight === 'Flight Manage By Own' ? 'display-block' : 'display-none'}`}>
                                            <div className='p-1'>
                                                <h4 className='text-dark fs-4'>Flight PNR</h4>
                                                <input type='text' name='flight_pnr' className='form-control fs-3 text-gray' placeholder='Enter Flight PNR No.' onChange={handleChange} required={form.flight =='Flight Manage By Own'?true:false} />
                                            </div>
                                        </div>
                                        <div className="col-sm-4 col-12">
                                            <div className='p-1 '>
                                                <button className='btn bg-orange fs-3' type='submit'>{loader?<><Loader2/></>:form.step1==1?"Submitted":'Submit'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form onSubmit={(e) => { handleSubmit(e, 2) }} >
                                    <div className={`p-3 shadow shadow-sm border mt-3 bg-white position-relative ${bordercolor2}`} id='idstep2'>
                                        <div className="d-flex justify-content-between">
                                            <h1 className='fs-2 fw-bold text-gray '>STEP 2</h1>
                                            <h1 className='fs-2 fw-bold text-gray '>{form.step2 && form.step2 == '1' ? 'Completed' : 'Pending'}</h1>
                                            
                                        </div>
                                        <h1 className='fs-2 fw-bold'>Contact Details</h1>
                                        <div className="row g-2">
                                            <div className="col-sm-4">
                                                <div className='p-1'>
                                                    <h5 className='fs-3'>Name</h5>
                                                    <input type='text' className='form-control fs-3 ' placeholder=' Name' name='name' onChange={handleChange} required readOnly={form.step1 && form.step1 == 1 ? false : true} onClick={() => { handleSteps(2,'idstep1')}}/>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='p-1'>
                                                    <h5 className='fs-3'>Middle Name</h5>
                                                    <input type='text' className='form-control fs-3 ' placeholder=' Middle Name' maxLength={1} name="middle_name" onChange={handleChange} required readOnly={form.step1 && form.step1 == 1 ? false : true} onClick={() => { handleSteps(2, 'idstep1') }} />
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='p-1'>
                                                    <h5 className='fs-3'>Family Name</h5>
                                                    <input type='text' className='form-control fs-3 ' placeholder='Family Name' name='family_name' onChange={handleChange} required readOnly={form.step1 && form.step1 == 1 ? false : true} onClick={() => { handleSteps(2, 'idstep1') }} />
                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-3">
                                                <div className='p-1'>
                                                    <h5 className='fs-3'> Code</h5>
                                                    <select className='form-select fs-3 border border-gray' name='mobile_code' onChange={handleChange} required readOnly={form.step1 && form.step1 == 1 ? false : true} onClick={() => { handleSteps(2, 'idstep1') }}>
                                                        <option defaultValue>+91 India</option>
                                                        {countryCodes.map((item, index) => (
                                                            <option key={index} value={item.code}>{item.code} {item.name}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-sm-4 col-9">
                                                <div className='p-1'>
                                                    <h5 className='fs-3'>Mobile Number</h5>
                                                    <input type='text' className='form-control fs-3 ' placeholder='Mobile Number' name='mobile' onChange={handleChange} required readOnly={form.step1 && form.step1 == 1 ? false : true} onClick={() => { handleSteps(2, 'idstep1') }} />

                                                    {errors.mobile && <small className="text-danger fs-5">{errors.mobile}</small>}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='p-1 '>
                                                    <h5 className='fs-3'>Email</h5>
                                                    <input type='email' className='form-control fs-3' placeholder='Enter Your Email' name='email' onChange={handleChange} required readOnly={form.step1 && form.step1 == 1 ? false : true} onClick={() => { handleSteps(2, 'idstep1') }} />
                                                    {errors.email && <small className="text-danger fs-5">{errors.email}</small>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={`position-absolute  w-100 h-100 bg-dark absolute-div ${form.step1 && form.step1 == 1 ? 'd-none' : ''}`}>
                                            <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white">
                                                <h2 className='fs-2'>Complete Step 1 to Unlock</h2>
                                            </div>
                                        </div> */}
                                        <button className="btn bg-orange fs-3 mt-3" type='submit' disabled={(form.step1 && form.step1 == 1) || Object.keys(errors).length > 0 ? false :true}>{loader ? <><Loader2 /></> : form.step2 == 1 ? "Submitted" : 'Submit'}</button>
                                    </div>

                                </form>
                                <div className={`p-3 shadow shadow-sm border mt-3 bg-white position-relative ${bordercolor3}`} id='idstep3'>
                                    <div className="d-flex justify-content-between">
                                        <h1 className='fs-2 fw-bold text-gray '>STEP 3</h1>
                                        <h1 className='fs-2 fw-bold text-gray '>{form.step3 && form.step3 == '1' ? 'Completed' : 'Pending'}</h1>
                                    </div>
                                    <h1 className='fs-2 fw-bold'> {guest + child} Passengers | {guest} Adults, {child} Child </h1>
                                    {form.step3 && form.step3 == '1' ? <>
                                        <h1 className='btn fs-2 px-0 text-orange mb-0' onClick={() => { setPassengerIndex(1); setShow2(true) }}>
                                            Edit Passengers Details
                                        </h1>

                                        {Object.entries(passenger).map(([passengerKey, passengerInfo]) => (
                                            <div key={passengerKey} className="passenger-card border p-3 ">
                                                <h3 className='fs-2 fw-bold mb-2'>{passengerKey.replace("_", " ")}</h3>
                                                <div className="row ">
                                                    {Object.entries(passengerInfo)
                                                        .filter(([key]) => !key.includes("email_") && key !== "booking_id") // Exclude "type_*" and "booking_id"
                                                        .map(([key, value]) => {
                                                            const cleanedKey = key.replace(/_\d+$/, ""); // Remove numeric suffix

                                                            // Construct full name dynamically
                                                            if (cleanedKey === "first_name") {
                                                                const middleName = Object.entries(passengerInfo).find(([k]) => k.startsWith("middle_name"))?.[1] || "";
                                                                const familyName = Object.entries(passengerInfo).find(([k]) => k.startsWith("family_name"))?.[1] || "";

                                                                const fullName = `${value} ${middleName.toUpperCase()} ${familyName}`.trim();

                                                                return (
                                                                    <div className="col-sm-3 col-6" key="full_name">
                                                                        <div className='p-1'>
                                                                            <h4 className='fs-3  '><span className='fw-bold'>full Name</span> </h4>
                                                                            <h4 className='fs-2-sm   mt-1'> {fullName} </h4>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }

                                                            // Format date if the key is "dob"
                                                            return (
                                                                cleanedKey !== "middle_name" && cleanedKey !== "family_name" && (
                                                                    <div className="col-sm-3 col-6col-sm-3 col-6" key={key}>
                                                                        <div className='p-1'>
                                                                            <h4 className='fs-3 '>
                                                                                <span className='fw-bold'>{cleanedKey == 'type' ? 'passenger' : cleanedKey}</span>
                                                                            </h4>
                                                                            <h4 className='fs-2-sm mb-2 mt-1'> {cleanedKey !== 'dob' ? value : formatDate(value)}</h4>
                                                                        </div>

                                                                    </div>
                                                                )
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        ))}
                                    </> :
                                        <>
                                            <h1 className='btn fs-2 px-0 text-orange mb-0' onClick={() => {
                                                if (form.step2 && form.step2 == 1) {
                                                    setPassengerIndex(1);
                                                    setShow2(true);
                                                }
                                                handleSteps('3', 'idstep2')
                                            }}>
                                                Add Passengers Details
                                            </h1>

                                        </>}



                                    {/* <div className={`position-absolute  w-100 h-100 bg-dark absolute-div ${form.step2 && form.step2 == 1 ? 'd-none' : ''}`}>
                                        <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white">
                                            <h2 className='fs-2'>Complete Step 2 to Unlock</h2>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='p-3 shadow shadow-sm border mt-3 bg-white position-relative' id='step4'>
                                    <div className="d-flex justify-content-between">
                                        <h1 className='fs-2 fw-bold text-gray '>STEP 4</h1>
                                        <h1 className='fs-2 fw-bold text-gray '>{form.step4 && form.step4 == '1' ? 'Completed' : 'Optional'}</h1>
                                    </div>
                                    <h1 className='fs-2 fw-bold'>Special Request <span className='fw-400'>( Optional )</span></h1>
                                    <form onSubmit={(e) => { handleSubmit(e, 4) }}>
                                        <div className="d-flex justify-content-between">
                                            <textarea type='text' className='form-control fs-3 me-2' name='request' placeholder='Enter Special request (if Any)' onChange={handleChange}></textarea>
                                        </div>
                                        {/* <div className={`position-absolute  w-100 h-100 bg-dark absolute-div ${form.step3 && form.step3 == 1 ? 'd-none' : ''}`}>
                                            <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white">
                                                <h2 className='fs-2'>Complete Step 3 to Unlock</h2>
                                            </div>
                                        </div> */}
                                        <button className="btn bg-orange fs-3 mt-3" disabled={form.step3 && form.step3 == 1 ? false : true}>{loader ? <><Loader2 /></> : form.step4 == 1 ? "Submitted" : 'Submit'}</button>
                                    </form>
                                </div>
                                {/* <div className='p-3 shadow shadow-sm border mt-3 bg-white'>
                                    <h1 className='fs-2 fw-bold text-gray'>Explore Specially Designed Merchandise For Thailand</h1>
                                    <h1 className='fs-2 fw-bold mb-2'>Add Merchandise <span className='fw-400'>( Optional )</span></h1>
                                    <div className="row g-2">
                                        <Merchandise />
                                    </div>
                                </div> */}

                                <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>
                                    <h1 className='fs-3 fw-bold'>Cancellation & Date Change</h1>
                                    <h1 className='fs-3 '>Package Cancellation Policy</h1>
                                    <h1 className='fs-4 text-green'>Cancellation Possible Before 10 Days From Starting Date*</h1>
                                    <h1 className='fs-4 text-gray'>After that Package is Non-Refundable.</h1>

                                    <div className='bg-light'>
                                        <ul>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>These are non-refundable amounts as per the current components attached. In the case of component change/modifications, the policy will change accordingly.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Please note, TCS once collected cannot be refunded in case of any cancellation / modification. You can claim the TCS amount as adjustment against Income Tax payable at the time of filing the return of income.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Cancellation charges shown is exclusive of all taxes and taxes will be added as per applicable.</h1>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>

                                    <h1 className='fs-3 '>Package Date Change Policy</h1>
                                    <h1 className='fs-4 text-green'>Date Change Possible Before 10 Days From Starting Date*</h1>
                                    <h1 className='fs-4 text-gray'>After that Package date cannot be changed</h1>

                                    <div className='bg-light'>
                                        <ul>
                                            <li className='my-3'>
                                                <h1 className='fs-5 text-gray'>These are non-refundable amounts as per the current components attached. In the case of component change/modifications, the policy will change accordingly.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-5 text-gray'>Date Change fees don't include any fare change in the components on the new date. Fare difference as applicable will be charged separately.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-5 text-gray'>Date Change will depend on the availability of the components on the new requested date.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-5 text-gray'>Please note, TCS once collected cannot be refunded in case of any cancellation / modification. You can claim the TCS amount as adjustment against Income Tax payable at the time of filing the return of income.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-5 text-gray'>Cancellation charges shown is exclusive of all taxes and taxes will be added as per applicable.</h1>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-12">
                                <form onSubmit={(e) => { handleSubmit(e, 5) }}>
                                    <div className='p-3 shadow shadow-sm border  bg-white'>

                                        <h1 className='fs-4'> Total Amount - ₹{parseInt(packageData.price) * (guest + child)} <span className='ms-3 p-1 border bg-orange text-white fs-5 border-0'>50% OFF</span></h1>
                                        <div className="">
                                            <h1 className='fs-1 text-dark fw-bold'>{packageData.price ? '₹'+( parseInt(packageData.price) * (guest + child) - ((parseInt(packageData.price) * ((guest + child))) / 2)).toFixed(0):'Loading'} </h1>

                                        </div>

                                        <h1 className='fs-4 my-1 text-green'>Extra 5% Discount On Online Booking</h1>


                                    </div>
                                    <div className='p-3 shadow shadow-sm border  bg-white'>

                                        <h1 className='fs-2 fw-bold'>Fare Breakup</h1>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h1 className='fs-4 text-dark fw-bold'>Total Basic Cost</h1>
                                                <h1 className='fs-4 text-gray '>  ₹{parseInt(packageData.price)} x {guest + child}</h1>

                                            </div>
                                            <h1 className='ms-3 p-1 fs-3 fw-bold'>₹{parseInt(packageData.price) * (guest + child)} </h1>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h1 className='fs-4 text-dark fw-bold'>Welcome Discount</h1>
                                                <h1 className='fs-4 text-green '>50% OFF Your Savings </h1>

                                            </div>
                                            <h1 className='ms-3 p-1 fs-3 fw-bold'>-₹{(parseInt(packageData.price) * (guest + child)) / 2} </h1>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <h1 className='fs-4 text-dark fw-bold'>Fee & Taxes</h1>
                                                <h1 className='fs-4 text-gray '>PlatForm Fee + 18% GST</h1>

                                            </div>
                                            <h1 className='ms-3 p-1 fs-3 fw-bold'>+{((18 / 100) * ((parseInt(packageData.price) * (guest + child)) / 2) + (2 / 100) * ((parseInt(packageData.price) * (guest + child)) / 2)).toFixed(0)} </h1>
                                        </div>


                                    </div>
                                    <div className='p-3 shadow shadow-sm border  bg-white'>

                                        <h1 className='fs-4'>Grand Total Amount - {guest} Adults {child != 0 ? ', ' + child + ' child' : ''}</h1>
                                        <div className="">
                                            <h1 className='fs-2-lg text-dark fw-bold'>{packageData.price ?'₹'+((parseInt(packageData.price) * (guest + child) - ((parseInt(packageData.price) * (guest + child)) / 2) + (18 / 100) * ((parseInt(packageData.price) * (guest + child)) / 2) + (2 / 100) * ((parseInt(packageData.price) * (guest + child)) / 2))).toFixed(0):'Loading'} </h1>
                                            <h1 className='pb-2 fs-5 text-gray'>( Inclusive Of All Taxs )</h1>
                                        </div>
                                        <Form.Check
                                            type="radio"
                                            id="book-online"
                                            name="booking_method"
                                            label="Book Online"
                                            value='Booked Online'
                                            className="fs-3 my-3"
                                            onChange={handleChange}
                                            required
                                        />

                                        <Form.Check
                                            type="radio"
                                            id="book-directly"
                                            name="booking_method"
                                            label="Book Through Company "
                                            value='Booked Through Company'
                                            className="fs-3"
                                            onChange={handleChange}
                                            required
                                        />

                                    </div>
                                    <div className='p-3 shadow shadow-sm border  bg-white'>

                                        <h1 className='fs-2 fw-bold'>Important Information</h1>
                                        <Form.Check
                                            type="checkbox"
                                            id="book-directly"
                                            name="booking-method"
                                            label={
                                                <>
                                                    I agree to the{" "}
                                                    <a
                                                        href="/terms_and_condition"
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className='fs-4'
                                                    >
                                                        terms and conditions
                                                    </a>{" "}
                                                    of chhuttiyan.com
                                                </>
                                            }
                                            className="fs-4 mt-3"
                                            required
                                        />
                                        <div className="d-flex justify-content-between mt-3">
                                            <button className="btn bg-orange fs-3 w-100 rounded-4" type='submit' >Book Now</button>
                                            {/* <button className="btn bg-orange fs-3 w-100 rounded-4" type='submit' disabled={form.booking_id != -1 ? form.step1 == 0 || form.step2 == 0 || form.step3 == 0 ? true : false : true} >Book Now</button> */}
                                            {/* <button className="btn bg-orange fs-3 w-100 rounded-4" type='submit'  >Continue</button>  */}
                                            {/* <button className="btn bg-orange fs-3 w-100 rounded-4" type='submit'  >Continue</button> */}
                                        </div>

                                    </div>
                                </form>
                                <div className='p-3 shadow shadow-sm border mt-3 bg-white display-mobile'>
                                    <h1 className='fs-3 fw-bold'>Cancellation & Date Change</h1>
                                    <h1 className='fs-3 '>Package Cancellation Policy</h1>
                                    <h1 className='fs-4 text-green'>Cancellation Possible Before 10 Days From Starting Date*</h1>
                                    <h1 className='fs-4 text-gray'>After that Package is <span className='text-dark'>Non-Refundable.</span></h1>

                                    <div className='bg-light'>
                                        <ul>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>These are non-refundable amounts as per the current components attached. In the case of component change/modifications, the policy will change accordingly.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Please note, TCS once collected cannot be refunded in case of any cancellation / modification. You can claim the TCS amount as adjustment against Income Tax payable at the time of filing the return of income.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Cancellation charges shown is exclusive of all taxes and taxes will be added as per applicable.</h1>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='p-3 shadow shadow-sm border mt-3 bg-white display-mobile'>

                                    <h1 className='fs-2 '>Package Date Change Policy</h1>
                                    <h1 className='fs-3 text-green'>Date Change Possible Before 10 Days From Starting Date*</h1>
                                    <h1 className='fs-3 text-gray'>After that Package date cannot be changed</h1>

                                    <div className='bg-light'>
                                        <ul>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>These are non-refundable amounts as per the current components attached. In the case of component change/modifications, the policy will change accordingly.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Date Change fees don't include any fare change in the components on the new date. Fare difference as applicable will be charged separately.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Date Change will depend on the availability of the components on the new requested date.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Please note, TCS once collected cannot be refunded in case of any cancellation / modification. You can claim the TCS amount as adjustment against Income Tax payable at the time of filing the return of income.</h1>
                                            </li>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Cancellation charges shown is exclusive of all taxes and taxes will be added as per applicable.</h1>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='p-3 shadow shadow-sm border  bg-white'>


                                    <h1 className="fw-bold fs-2">
                                        Similar Packages
                                    </h1>

                                    <div className="card p-1 border-0 mt-2 shadow shadow-md " >
                                        <div
                                            className="  p-2"
                                            style={{
                                                background: `url(${packageimg}) center/cover no-repeat`,
                                                height: "200px",
                                            }}
                                        >
                                            <h1 className='fs-3 text-white '>Premium Packages</h1>
                                        </div>
                                        <div className="card-body  border-gray ">
                                            <div className=" pb-3 border-bottom">
                                                <h3 className='fs-3'>Romantic Week in Pattaya & Bangkok </h3>
                                                <div className='d-flex justify-content-between align-items-start  mt-2'>
                                                    <h3 className='fs-4 text-gray fw-600'>2N Pattaya | 1N Bangkok </h3>
                                                </div>
                                                <h3 className='fs-4 p-1 w-25 border border-purple border-2 text-purple fw-600 rounded-3' >2D/3N</h3>
                                            </div>

                                            <div className='border rounded rounded-3 bg-light d-block p-2 '>

                                                <h2 className='fs-2'>₹12999/<span className='text-gray fs-4 fw-400'>per person</span></h2>
                                                <h2 className='fs-4 fw-400 text-gray '>Orignal Price ₹parseInt(packageData.price)</h2>
                                                <h2 className='fs-4 fw-400 text-gray'>Inclusive of all taxes</h2>

                                            </div>
                                        </div>
                                        <div className='card-footer bg-white  py-3 d-flex align-items-center justify-content-between'>
                                            <button className='btn fs-3 bg-orange' onClick={() => { window.open('/thailand-package-booking', '_blank') }}>Book Now</button>
                                            <h2 className='fs-4 fw-400 text-green'>50% OFF Coupon</h2>
                                        </div>
                                    </div>
                                    {/* <img className='img-fluid mt-3' src={packageads2} /> */}
                                    <img className='img-fluid mt-3' src={packageads} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
            <Modal show={show} dialogClassName="modal" centered >

                <Modal.Body className='p-4 border-0'>
                    {notifyid == 1 ?
                        <div className="d-flex flex-column p-4 border-0">
                            <h3 className='fs-1 mb-0'>Processing</h3>
                            <h3><Loader /></h3>
                        </div>
                        : notifyid == 2 ?
                            <div className="d-flex flex-column border-0">
                                <h2><i class="bi bi-check-circle banner-text text-green mb-0"></i></h2>
                                <h2 className=' fw-600  fs-1 mt-0'>Booking Request Submitted</h2>
                                <h2 className=' fw-400  fs-3 '>We Will Contact You Shortly</h2>
                                {/* <button className='btn text-orange fs-2 text-decoration-underline ' onClick={()=>{navigate('/')}}>Manage Booking</button> */}
                                <button className='btn text-orange fs-2-sm py-0 ' onClick={() => { navigate('/') }}>Go To Home</button>
                            </div>
                            : ''
                    }

                </Modal.Body >

            </Modal>
            <Modal show={show2} onHide={() => { setShow2(false) }} dialogClassName="modal-dynamic" centered >
                <Modal.Header className='p-3'>
                    <div className='container-fluid px-0 d-sm-flex d-block justify-content-between'>
                        <div>
                            <h1 className='fs-2 fw-bold text-gray '>Add Passenger Details</h1>
                            <h1 className='fs-1 fw-bold my-2 '>Passenger {passengerindex} <span className='fs-4 text-gray'>/{guest + child}</span></h1>
                             {/* {JSON.stringify(passenger)}
                             {JSON.stringify(errors)} */}
                        </div>
                        <div className='d-flex justify-content-start mt-1 display-pc'>
                            {[...Array(guest + child)].map((item, index) => (
                                <button className='fs-3 border btn me-1 ' onClick={() => { setPassengerIndex(index + 1) }}>Passenger {index + 1}</button>
                            ))}
                        </div>

                    </div>

                </Modal.Header>
                <Modal.Body className='p-1'>
                    <div className="container-fluid px-0">
                        <form onSubmit={(e) => { setPassengerIndex(1); handleSubmit(e, 3) }} >
                            <div className="row">
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Passenger</h4>
                                    <select className='form-select fs-4 py-2 border border-gray' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`type_${passengerindex}`] : ''} name={`type_${passengerindex}`} onChange={handlePassenger} required>
                                        <option defaultValue> Passenger </option>
                                        <option value="adult">Adult</option>
                                        <option value="child">Child</option>
                                    </select>

                                </div>
                                <div className="col-sm-3 col-6 p-2 display-mobile">
                                    <h4 className='fs-4'>Age</h4>
                                    <select className='form-select fs-4 py-2 border border-gray' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`age_${passengerindex}`] : ''} name={`age_${passengerindex}`} onChange={handlePassenger} required>
                                        {passenger[`passenger_${passengerindex}`]?.[`type_${passengerindex}`] && passenger[`passenger_${passengerindex}`]?.[`type_${passengerindex}`] == 'child' ? childage.map((item, index) => (
                                            <option key={index} value={item.key}>{item.value}</option>
                                        )) : adultage.map((item, index) => (
                                            <option key={index} value={item.key}>{item.value}</option>
                                        ))}
                                    </select>

                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>First Name</h4>
                                    <input type='text' className='form-control fs-4 py-2' placeholder='First Name' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`first_name_${passengerindex}`] : ''} name={`first_name_${passengerindex}`} onChange={handlePassenger} required />
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Middle Name</h4>
                                    <input type='text' className='form-control fs-4 py-2' maxLength={1} placeholder='Middle Name' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`middle_name_${passengerindex}`] : ''} name={`middle_name_${passengerindex}`} onChange={handlePassenger} required />
                                </div>
                                <div className="col-sm-3 col-12 p-2">
                                    <h4 className='fs-4'>Family Name</h4>
                                    <input type='text' className='form-control fs-4 py-2' placeholder='Family Name' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`family_name_${passengerindex}`] : ''} name={`family_name_${passengerindex}`} onChange={handlePassenger} required />
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Mobile No.</h4>
                                    <input type='number' className='form-control fs-4 py-2' placeholder='Mobile No.' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`phone_${passengerindex}`] : ''} name={`phone_${passengerindex}`} onChange={handlePassenger} required />
                                    {errors[`phone_${passengerindex}`] && <small className="text-danger fs-5">{errors[`phone_${passengerindex}`]}</small>}
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Email</h4>
                                    <input type='text' className='form-control fs-4 py-2' placeholder='Email' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`email_${passengerindex}`] : ''} name={`email_${passengerindex}`} onChange={handlePassenger} required />
                                    {errors[`email_${passengerindex}`] && <small className="text-danger fs-5">{errors[`email_${passengerindex}`]}</small>}
                                </div>
                             
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>DOB</h4>
                                    <input type='date' className='form-control fs-4 py-2' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`dob_${passengerindex}`] : ''} name={`dob_${passengerindex}`} onChange={handlePassenger} required />
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Gender</h4>
                                    {/* <input type='text' className='form-control fs-4 py-2' placeholder='Enter Your Name' /> */}
                                    <select className='form-select fs-4 py-2 border' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`gender_${passengerindex}`] : ''} name={`gender_${passengerindex}`} onChange={handlePassenger} required>
                                        <option className='' defaultValue='Gender'>Gender</option>
                                        <option className='' value="Male">Male</option>
                                        <option className='' value="Female">Female</option>
                                    </select>
                                </div>
                                <div className="col-sm-3 col-6 p-2 display-pc">
                                    <h4 className='fs-4'>Age</h4>
                                    <select className='form-select fs-4 py-2 border border-gray' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`age_${passengerindex}`] : ''} name={`age_${passengerindex}`} onChange={handlePassenger} required>
                                        {passenger[`passenger_${passengerindex}`]?.[`type_${passengerindex}`] && passenger[`passenger_${passengerindex}`]?.[`type_${passengerindex}`] == 'child' ? childage.map((item, index) => (
                                            <option key={index} value={item.key}>{item.value}</option>
                                        )) : adultage.map((item, index) => (
                                            <option key={index} value={item.key}>{item.value}</option>
                                        ))}
                                    </select>

                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Passport Number</h4>
                                    <input type='text' className='form-control fs-4 py-2' placeholder='Passport Number' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`passport_${passengerindex}`] : ''} name={`passport_${passengerindex}`} onChange={handlePassenger} required />
                                </div>

                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Passport Issuing Country</h4>
                                    <input type='text' className='form-control fs-4 py-2' placeholder='Passport Issuing Country' value={passenger[`passenger_${passengerindex}`] ? passenger[`passenger_${passengerindex}`][`pass_country_${passengerindex}`] : ''} name={`pass_country_${passengerindex}`} onChange={handlePassenger} required />
                                </div>
                                <div className="col-sm-12 p-2">
                                    <div className='bg-light'>
                                        <ul className='ps-3'>
                                            <li className='my-3'>
                                                <h1 className='fs-4 text-gray'>Please review all information carefully before submitting to ensure a smooth and hassle-free processing of your request </h1>
                                            </li>

                                        </ul>
                                    </div>
                                    <Form.Check
                                        type="checkbox"
                                        id="book-directly"
                                        name="booking-method" // Shared name for the group
                                        label="I confirm that I have read and I accept Cancellation Policy, User Agreement, Terms of Service and Privacy Policy of Chhuttiyan.com"
                                        className="fs-4 mt-3"
                                        required
                                    />
                                    <div className="d-flex justify-content-start mt-3 w-50">
                                        {/* {step + "," + (guest + child)} */}
                                        {step == 1 && step != (guest + child) ?
                                            <> <button className="btn bg-orange fs-3 w-25 w-sm-100 rounded rounded-3" type='button' onClick={(e) => { e.preventDefault(); setPassengerIndex(passengerindex + 1); setStep(step + 1) }}>Next</button></>
                                            : (guest + child) == 1 ?
                                                <> <button className="btn bg-orange fs-3 w-25 w-sm-100 rounded rounded-3" type='submit' disabled={Object.keys(errors).length > 0}>Submit</button></>
                                                : step == (guest + child) ? <>
                                                    <button className="btn bg-orange fs-3 w-25 me-3 w-sm-100 rounded rounded-3" type='button' onClick={() => { setPassengerIndex(passengerindex - 1); setStep(step - 1) }}>Prev</button>
                                                    <button className="btn bg-orange fs-3 w-25 w-sm-100 rounded rounded-3" type='submit' disabled={Object.keys(errors).length > 0}>Submit</button></>

                                                    : <><button className="btn bg-orange fs-3 w-25 me-3 w-sm-100 rounded rounded-3" type='button' onClick={(e) => { e.preventDefault(); setPassengerIndex(passengerindex - 1); setStep(step - 1) }}>Prev</button>
                                                        <button className="btn bg-orange fs-3 w-25 w-sm-100 rounded rounded-3" type='button' onClick={(e) => { e.preventDefault(); setPassengerIndex(passengerindex + 1); setStep(step + 1) }}>Next</button></>}

                                        {/* <button className="btn bg-orange fs-3 w-25 w-sm-100 rounded rounded-3" type='submit'>Submit</button> */}
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </Modal.Body >
            </Modal >
            <Modal show={show3} onHide={() => { setShow3(false) }} dialogClassName="modal-dynamic hidden-modal" centered>

                <Modal.Body className=''>
                    <div className="container-fluid download p-5 " >
                        <form onSubmit={(e) => { setPassengerIndex(1); handleSubmit(e, 3) }}>
                            <div className='container-fluid px-0 d-sm-flex d-block justify-content-between pb-3'>
                                <h1 className='fs-2 fw-bold  '>Booking Request</h1>
                                <h1 className='fs-2 fw-bold text-gray'>Chhuttiyan.com</h1>
                            </div>
                            <div className="border p-3">
                                <h3 className='fs-2 fw-bold mb-2 text-gray'>1. Package Detail</h3>
                                <div className="d-flex justify-content-between mb-3">
                                    <h3 className='fs-2-lg fw-bold  mb-2'>Thailand- Bangkok,Pattaya (3N/4D)</h3>
                                </div>
                                <div className='container-fluid'>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Booking Date </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Booking Mode </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Booking Status </h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{new Date().toISOString().split('T')[0].split('-').reverse().join('-')}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.booking_method ? form.booking_method : ''}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.booking_status ? form.booking_status : ''}</h3></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th> <h3 className='fs-2 fw-600 text-orange me-3'>Total Amount </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange me-3'>Payment Status  </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange me-3'>Payment Id  </h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>₹{((parseInt(packageData.price) * (guest + child) - ((parseInt(packageData.price) * guest) / 2) + (18 / 100) * ((parseInt(packageData.price) * guest) / 2))).toFixed(0)}/-</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.booking_method == 'Booked Online' ? "Paid" : 'Pending'}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.booking_method == 'Booked Online' ? paymentid : 'Pending'}</h3></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            <div className="border p-3">
                                <h3 className='fs-2 fw-bold  mb-2 text-gray'>2. Basic Details</h3>
                                <div className="container-fluid">
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Starting Form </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Starting Date </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Passengers </h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.starting_from ? form.starting_from : ''}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'> {form.starting_date ? formatDate(form.starting_date) : ''}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{guest ? guest + (" Adult") : ''} {child ? child + (" Child") : ''}</h3></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="border p-3">
                                <h3 className='fs-2 fw-bold text-gray mb-2'>3. Contact Details</h3>
                                <div className="container-fluid">
                                    <table className='table table-bordered'>
                                        <thead>
                                            <tr>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Name</h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Mobile </h3></th>
                                                <th> <h3 className='fs-2 fw-600 text-orange '>Email </h3></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.name ? form.name : ''} {form.middle_name ? form.middle_name + '.' : ''} {form.family_name ? form.family_name : ''}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.mobile_code ? form.mobile_code : ''} {form.mobile ? form.mobile : ''}</h3></td>
                                                <td><h3 className='fs-2 fw-600 text-dark mb-0'>{form.email ? form.email : ''}</h3></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                            <div className="border p-3">
                                <h3 className='fs-2 fw-bold text-gray mb-2'>4. Passenger Details</h3>
                                {Object.entries(passenger).map(([passengerKey, passengerInfo]) => (
                                    <div key={passengerKey} className="passenger-card border p-3 ">
                                        <h3 className='fs-2 fw-bold mb-2'>{passengerKey.replace("_", " ")}</h3>
                                        <div className="row ">
                                            {Object.entries(passengerInfo)
                                                .filter(([key]) => !key.includes("type_") && key !== "booking_id") // Exclude "type_*" and "booking_id"
                                                .map(([key, value]) => {
                                                    const cleanedKey = key.replace(/_\d+$/, ""); // Remove numeric suffix

                                                    // Construct full name dynamically
                                                    if (cleanedKey === "first_name") {
                                                        const middleName = Object.entries(passengerInfo).find(([k]) => k.startsWith("middle_name"))?.[1] || "";
                                                        const familyName = Object.entries(passengerInfo).find(([k]) => k.startsWith("family_name"))?.[1] || "";

                                                        const fullName = `${value} ${middleName.toUpperCase()} ${familyName}`.trim();

                                                        return (
                                                            <div className="col-3" key="full_name">
                                                                <div className='p-1'>
                                                                    <h4 className='fs-2-sm text-orange fw-600'><span className='fw-bold'>Full Name</span> </h4>
                                                                    <h4 className='fs-2-sm  border p-2 fw-600'> {fullName} </h4>
                                                                </div>
                                                            </div>
                                                        );
                                                    }

                                                    // Format date if the key is "dob"
                                                    return (
                                                        cleanedKey !== "middle_name" && cleanedKey !== "family_name" && (
                                                            <div className="col-3" key={key}>
                                                                <div className='p-1'>
                                                                    <h4 className='fs-2-sm text-orange fw-600'>
                                                                        <span className='fw-bold'>{cleanedKey}</span>
                                                                    </h4>
                                                                    <h4 className='fs-2-sm mb-2 border p-2 fw-600 '> {cleanedKey !== 'dob' ? value : formatDate(value)}</h4>
                                                                </div>

                                                            </div>
                                                        )
                                                    );
                                                })}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="border p-3">
                                <h3 className='fs-2 fw-bold text-gray'>5. Special Request</h3>
                                <h3 className='fs-2 fw-600'>{form.request ? form.request : ''}</h3>
                            </div>

                        </form>

                    </div>
                    {/* <button className='btn btn-primary' onClick={sendEmailWithModalContent}>send email</button> */}
                </Modal.Body >
            </Modal >
            <Footer />
            <Signup show={show4} userType='Guest' authtype='login' onClose={handleClose} onClose2={handleClose2} />
        </>
    )
}

export default Tour_Booking
