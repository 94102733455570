import React, { createContext, useState, useContext } from 'react';

// Create a Context for user data
const UserContext = createContext();


export const UserProvider = ({ children }) => {
    const [userId, setUserId] = useState({});  


    const setUser = (id) => {
        setUserId(id);  
    };

    const clearUser = () => {
        setUserId(null);
    };

    return (
        <UserContext.Provider value={{ userId, setUser, clearUser }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook to access user data from anywhere
export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error("useUser must be used within a UserProvider");
    }
    return context;
};