import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import Header from '../common/Header';
import { Modal } from 'react-bootstrap'
import LazyImageWrapper from '../common/LazyImg';
import phone from '../../assets/img/icone/phone.png'
import whatapp from '../../assets/img/icone/whatapp.png'
import email from '../../assets/img/icone/email.png'
import logo from '../../assets/img/logo/logo2.png';
import support from '../../assets/img/icone/support.png';
import { useNavigate } from 'react-router-dom';
import Booking_pc from '../common/Booking-pc';
import Booking_mobile from '../common/Booking-mobile';
function Sec_1_Home() {
  const [index, setIndex] = useState(0);
  const [packagename, setPackages] = useState('International')
  const [sidepopup, sidePopUp] = useState('d-block')
  const [show, setShow] = useState(false);
  const navigate = useNavigate()

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true)
  //     setTimeout(() => {
  //       setShow(false)
  //     }, 8000)
  //   }, 15000)
  // }, [])

  const contentPairs = [
    { title: "Thailand", subtitle: "Bangkok Pattaya" },
    { title: "Singapore", subtitle: "Sentosa, Merlion" },
    { title: "London", subtitle: "Explore U.K." },
    { title: "CROATIA", subtitle: "Beograd, Danube" },
    { title: "America", subtitle: "New York, California" },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % contentPairs.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup the interval
  }, [contentPairs.length]);
  const handleIndexClick = (newIndex) => {
    setIndex(newIndex); // Update the index when a number is clicked
  };

  const handlePackages = (name) => {
    setPackages(name);
  }
  const handleSearch = () => {
    navigate("/thailand-tour-packages", { state: { section: 'toursection' } });
  };

  const countries = [
    { key: "argentina", value: "Argentina" },
    { key: "australia", value: "Australia" },
    { key: "austria", value: "Austria" },
    { key: "belgium", value: "Belgium" },
    { key: "brazil", value: "Brazil" },
    { key: "canada", value: "Canada" },
    { key: "chile", value: "Chile" },
    { key: "china", value: "China" },
    { key: "colombia", value: "Colombia" },
    { key: "czech_republic", value: "Czech Republic" },
    { key: "denmark", value: "Denmark" },
    { key: "egypt", value: "Egypt" },
    { key: "finland", value: "Finland" },
    { key: "france", value: "France" },
    { key: "germany", value: "Germany" },
    { key: "greece", value: "Greece" },
    { key: "hungary", value: "Hungary" },
    { key: "india", value: "India" },
    { key: "indonesia", value: "Indonesia" },
    { key: "ireland", value: "Ireland" },
    { key: "israel", value: "Israel" },
    { key: "italy", value: "Italy" },
    { key: "japan", value: "Japan" },
    { key: "malaysia", value: "Malaysia" },
    { key: "mexico", value: "Mexico" },
    { key: "netherlands", value: "Netherlands" },
    { key: "new_zealand", value: "New Zealand" },
    { key: "nigeria", value: "Nigeria" },
    { key: "norway", value: "Norway" },
    { key: "pakistan", value: "Pakistan" },
    { key: "peru", value: "Peru" },
    { key: "philippines", value: "Philippines" },
    { key: "poland", value: "Poland" },
    { key: "portugal", value: "Portugal" },
    { key: "russia", value: "Russia" },
    { key: "saudi_arabia", value: "Saudi Arabia" },
    { key: "singapore", value: "Singapore" },
    { key: "south_africa", value: "South Africa" },
    { key: "south_korea", value: "South Korea" },
    { key: "spain", value: "Spain" },
    { key: "sweden", value: "Sweden" },
    { key: "switzerland", value: "Switzerland" },
    { key: "thailand", value: "Thailand" },
    { key: "turkey", value: "Turkey" },
    { key: "ukraine", value: "Ukraine" },
    { key: "uk", value: "Uk" },
    { key: "usa", value: "Usa" },
    { key: "vietnam", value: "Vietnam" }
  ];
  return (
    <>
      {/* START SECTION 1 */}
      <Header />
      <section className='section-1 section-home position-relative' id="main-section" >
        <div className="paddingtop padding-top-mobile header-padding h-100vh d-flex flex-column justify-content-center ">
          <div className="row w-100 p-2 g-6">
            <div className="col-lg-8 col-md-7 col-sm-6 text-sm-start text-center ">
              <motion.h1
                className="text-white banner-text fw-800"
                key={contentPairs[index].title} 
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{
                  duration: 1,
                }}
              >
                {contentPairs[index].title}
              </motion.h1>

              <motion.h1
                className="text-white banner-text beyond"
                key={contentPairs[index].subtitle} // Ensure unique animation per pair
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{
                  duration: 1,
                  delay: 0.5, // Subtitle appears slightly after title
                }}
              >
                {contentPairs[index].subtitle}
              </motion.h1>
              <button className='btn btn-purple fs-3 mt-4' onClick={() => { navigate(`/${contentPairs[index].title.toLowerCase() + '-tour-packages'}`); }}>Book Now</button>
            </div>

            <div className="col-lg-4 col-md-5 col-sm-6 text-center " >
              <div className='my-3 d-flex display-mobile' >
                {contentPairs.map((_, idx) => (
                  <button
                    key={idx}
                    onClick={() => handleIndexClick(idx)}
                    style={{
                      backgroundColor: idx === index ? "#8F50FA" : "#000",
                      color: idx === index ? "#fff" : "#fff",
                      border: "none",
                      borderRadius: "50%",
                      width: "30px",
                      height: "30px",
                      margin: "0 5px",
                      cursor: "pointer",
                    }}
                    className='fs-5'
                  >
                    {idx + 1}
                  </button>
                ))}
              </div>
              <div className="h-100">
                <div className="d-flex  mb-2 bg-white p-2 rounded rounded-2">
                  <div className="dropdown w-100">

                    <button className="  fs-4 fs-sm-4 border-0 text-dark fw-600 w-100 text-start d-flex justify-content-between bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Tour Packages  <span className='form-control text-dark fw-600 w-50 fs-4 fs-sm-4 border d-flex justify-content-between'>{packagename} <i class="bi bi-chevron-down " ></i></span>
                    </button>
                    <ul className="dropdown-menu w-100 bg-light" aria-labelledby="dropdownMenuButton1">
                      <li>
                        <a className="dropdown-item fs-2 fw-600 text-gray" href="#">
                          International Packages
                        </a>
                      </li>
                      <li><a className="dropdown-item fs-2 fw-600" onClick={() => handlePackages('Thailand')}>
                        Thailand
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-purple" href="#">
                        Coming Soon
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-gray" href="#">
                        Singapore
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 border-bottom border-dark border-2 text-gray" href="#" >
                        Malaysia
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 border-bottom border-dark border-2 text-gray" href="#">
                        Dubai
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 border-bottom border-dark border-2 text-gray" href="#">
                        Serbia
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-gray" href="#">
                        London
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-gray" href="#">
                        USA
                      </a></li>

                    </ul>
                  </div>
                  <span className="btn input-group-text fs-4 fs-sm-4 btn-theme ms-3" id="basic-addon2" onClick={handleSearch}>
                    Search
                  </span>
                </div>
                <div className="d-flex  mb-2 bg-white p-2 rounded rounded-2">
                  <div className="dropdown w-100">
                    <button className="  fs-4 fs-sm-4 border-0 text-dark fw-600 w-100 text-start d-flex justify-content-between bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Tour Packages  <span className='form-control text-dark fw-600 w-50 fs-4 fs-sm-4  border d-flex justify-content-between'>Domestic <i class="bi bi-chevron-down " ></i></span>
                    </button>
                    <ul className="dropdown-menu w-100 bg-light" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item fs-2 fw-600 text-gray" href="#">
                        Domestic Tour Packages
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600" href="#">
                        Ladakh
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600" href="#">
                        Rajasthan
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 " href="#">
                        Himachal
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 " href="#">
                        Spiti  Valley
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 " href="#">
                        Goa
                      </a></li>
                    </ul>
                  </div>
                  <span className="btn input-group-text fs-4 fs-sm-4 btn-theme ms-3" id="basic-addon2">
                    Search
                  </span>
                </div>
                <div className="d-flex  mb-2 bg-white p-2 rounded rounded-2">
                  <div className="dropdown w-100">
                    <button className="  fs-4 fs-sm-4 border-0 text-dark fw-600 w-100 text-start d-flex justify-content-between bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Hotel Booking  <span className='form-control text-dark fw-600 w-50 fs-4 fs-sm-4  border d-flex justify-content-between'>Country<i class="bi bi-chevron-down " ></i></span>
                    </button>
                    <ul className="dropdown-menu w-100 bg-light" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        India
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        International
                      </a></li>

                    </ul>
                  </div>
                  <span className="btn input-group-text fs-4 fs-sm-4 btn-theme ms-3" id="basic-addon2">
                    Search
                  </span>
                </div>
                <div className="d-flex  mb-2 bg-white p-2 rounded rounded-2">
                  <div className="dropdown w-100">
                    <button className="  fs-4 fs-sm-4 border-0 text-dark fw-600 w-100 text-start d-flex justify-content-between bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Visa Service  <span className='form-control text-dark fw-600 w-50 fs-4 fs-sm-4  border d-flex justify-content-between'>Country<i class="bi bi-chevron-down " ></i></span>
                    </button>
                    <ul className="dropdown-menu w-100 bg-light" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        USA
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        UK
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        Canada
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        Australia
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        New Zealand
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        Japan
                      </a></li>
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        South Korea
                      </a></li>
                      <li>
                        <select className='form-select fs-3 fw-600 text-dark'>
                          <option defaultValue>Other Countries</option>
                          {countries.map((item, index) => (
                            <option value={item.value} key={index}>{item.key}</option>
                          ))}
                        </select>
                      </li>


                    </ul>
                  </div>
                  <span className="btn input-group-text fs-4 fs-sm-4 btn-theme ms-3" id="basic-addon2">
                    Search
                  </span>
                </div>
                <div className="d-flex  mb-2 bg-white p-2 rounded rounded-2">
                  <div className="dropdown w-100">
                    <button className="  fs-4 fs-sm-4 border-0 text-dark fw-600 w-100 text-start d-flex justify-content-between bg-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Events  <span className='form-control text-dark fw-600 w-50 fs-4 fs-sm-4  border d-flex justify-content-between'>Activities<i class="bi bi-chevron-down " ></i></span>
                    </button>
                    <ul className="dropdown-menu w-100 bg-light" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item fs-3 fw-600 text-dark" href="#">
                        Coming Soon
                      </a></li>
                    </ul>
                  </div>
                  <span className="btn input-group-text fs-4 fs-sm-4 btn-theme ms-3" id="basic-addon2">
                    Search
                  </span>
                </div>

              </div>

            </div>

          </div>
          <div className=" stick header-padding display-pc">
            <div className='my-3 d-flex ' >
              {contentPairs.map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => handleIndexClick(idx)}
                  style={{
                    backgroundColor: idx === index ? "#8F50FA" : "#000",
                    color: idx === index ? "#fff" : "#fff",
                    border: "none",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    margin: "0 5px",
                    cursor: "pointer",
                  }}
                  className='fs-5'
                >
                  {idx + 1}
                </button>
              ))}
            </div>
            <Booking_pc />

          </div>
        </div>

        <div className={`search-pc fixed-left ${sidepopup}`}>
          <div
            className={`btn bg-dark-purple floating-help  text-white bg-blue px-2 shadow shadow-lg `} >
            <div className={`d-flex me-1 align-items-start `}>
              <div className='d-flex flex-column'>
                <img src={logo} className='bookingicone' />
                <i class="bi bi-facebook fs-3 my-1 socialicone"></i>
                <i class="bi bi-youtube fs-3 my-1 socialicone"></i>
                <i class="bi bi-instagram fs-3 my-1 socialicone"></i>
                <i class="bi bi-twitter fs-3 my-1 socialicone"></i>
                <i class="bi bi-pinterest fs-3 my-1 socialicone"></i>
              </div>

            </div>
          </div>
        </div>

        <div className={`btn bg-white floating-help2  text-white  px-2 shadow shadow-lg  `} onMouseEnter={()=>{setShow(true)}}>
          <div className='d-flex flex-column p-1 py-2 '>
            <img src={support} className='supportimg' />
            <h4 className="fs-4 text-dark">Support ? </h4>
          </div>
        </div>
        <Modal show={show} onHide={() => { setShow(false) }} ClassName="modal-fit-content" centered >

          <Modal.Body className='p-2 '>
            <div className="d-sm-flex d-block  justify-content-between align-items-start position-relative">
              <div className="row " >

                <div className="col-sm-12 col-12" >

                  <div className=' px-3 text-center order-1 '>
                    <h2 className='fs-2 fw-600 my-2 text-dark '>Need Help ? </h2>
                    <h2 className=' fw-600 my-2 text-dark fs-2-lg'>Not Found What You Are Looking For</h2>

                    <a href="https://wa.me/9530700321" className='d-flex  ' style={{ textDecoration: "none" }}>
                        <LazyImageWrapper
                          src={whatapp}
                          alt="Phone Icon"
                        className="avatar me-2 py-2"
                        /> <h4 className='fs-2 fw-600 my-2 text-dark '>Whatsapp Now</h4></a>
                    
                    <h2 className='fs-4 fw-600 mt-2 p-1 text-dark'>Powered By</h2>
                    <h2 className='fs-2 fw-600 mb-2 p-1 text-dark'>Chhuttiyan.com</h2>
                  </div>
                </div>
              </div>

              <div className='position-absolute close-btn'>
                <button className='btn bg-transparent p-0 ' onClick={() => { setShow(false) }}><i className='bi bi-x fs-1 fw-bold text-dark '></i></button>
              </div>
            </div>
          </Modal.Body>

        </Modal>

      </section>
      <Booking_mobile />

      {/* END SECTION 1 */}
    </>
  )
}

export default Sec_1_Home
