import React from 'react'
import cardimg1 from '../../assets/img/background/cardimg1.jpg'
import cardimg2 from '../../assets/img/background/cardimg2.jpg'
import cardimg3 from '../../assets/img/background/caardimg3.jpg'

import star from '../../assets/img/icone/star.png'
import { useNavigate } from 'react-router-dom'
function Sec_3_Tourpack() {
   const navigate = useNavigate()
    const tour = [
        { img: cardimg2, country:'Thailand', url:'thailand'  },
        { img: cardimg1, country: 'Singapore', url: 'singapore' },
        { img: cardimg3, country: 'Croatia', url: 'croatia' },
        { img: cardimg3, country: 'London', url: 'london' },
        { img: cardimg3, country: 'USA', url: 'america' },
        { img: cardimg2, country: 'Serbia', url: 'singapore' },
    ]
    return (
        <>
            <section className='section-3 d-flex justify-content-center align-items-center py-5' >
                <div className="  header-padding d-flex flex-column justify-content-center">
                    <h2 className='fw-bold text-center'>Chhuttiyan.com - Holidays Defined</h2>
                    <p className='fs-2 my-5' style={{ textAlign: 'center' }}>Thailand Tour Package, Singapore Tour Package, London Tour Package, America Tour Package, Canada Tour Package, Japan Tour Package, Embassy, Consulates information, Cheap Flight Tickets, Bike & Car Rentals, Hotels...
                        Chhuttiyan.com is platform for travellers & travel companies to explore the best of products & reach millions of Clients.  </p>
                    <div className="row w-75 w-sm-100">
                        {tour.map((item, index) => (
                            <div className="col-sm-4 col-6 p-3  " key={index}>
                                <div className='py-3 h-100 d-flex flex-column justify-content-between  cardimg position-relative shadow shadow-md' style={{ background: `url(${item.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <div className="overlay"></div>
                                    <div className="p-2 position-relative">
                                        <button className="btn btn-theme fw-600 rounded rounded-4 fs-4">
                                            Explore the World
                                        </button>
                                    </div>
                                    <div className="p-2 text-center position-relative">
                                        <h2 className="fw-bold text-white fs-1">{item.country} Tour Packages</h2>
                                        <div className="d-flex">
                                            <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                            <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                            <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                            <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                            <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                        </div>
                                    </div>

                                    <div className="p-2 position-relative">
                                        <button className="btn btn-purple fw-600 rounded rounded-2 fs-3 " onClick={() => { navigate(`/${item.url.toLowerCase()+'-tour-packages'}`)}}>
                                            Read More
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sec_3_Tourpack
