import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import LazyImageWrapper from '../common/LazyImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import post from '../../assets/img/icone/post.png'
import compass from '../../assets/img/icone/compass.png'
import cycle from '../../assets/img/icone/bicycle.png'
import boat from '../../assets/img/icone/boat.png'

import { Accordion, InputGroup, Form, Nav, Tab, Tabs } from 'react-bootstrap';
import Header from '../common/Header'
import bga2 from '../../assets/img/background/bg4.jpg'
import packageads2 from '../../assets/img/thailand/packagead2.jpg'
import bangkok from '../../assets/img/thailand/bangkok.jpg'


import Section_2_Booking from '../components/Sec_2_Booking'

import Footer from '../common/Footer'
import Tour_popup from '../common/Tour_popup'
import Booking_pc from '../common/Booking-pc';
import Booking_mobile from '../common/Booking-mobile';


const Tour_packages = () => {
    const [file, setFile] = useState(null);
    const [key, setKey] = useState('Honeymoon');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [activepackageType, setActivePackageType] = useState('1');
    const [activepackage, setActivePackage] = useState('Thailand');
    const location = useLocation();
    const navigate = useNavigate()


    const slides = [
        { packtype:'Thailand',title: "Bangkok", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.6 },
        { packtype:'Thailand',title: "Pattaya", img: bangkok, price: 3999, oldPrice: 6999, rating: 4.7 },
        { packtype:'Thailand',title: "Phuket", img: bangkok, price: 3999, oldPrice: 6999, rating: 4.7 },
        { packtype:'Thailand',title: "Krabi", img: bangkok, price: 3999, oldPrice: 6999, rating: 4.7 },
        { packtype:'Singapore',title: "Singapore", img: bangkok, price: 4999, oldPrice: 8999, rating: 4.8 },
        { packtype:'Bali',title: "Bali", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.5 },
        { packtype:'Malaysia',title: "Malaysia", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.5 },
        // { packtype:'thailand',title: "Bali", img: bangkok, price: 2999, oldPrice: 5999, rating: 4.5 },
    ];
    // useEffect(() => {
    //     if (location.state?.section) {
    //         // const elementId = location.state.section.toLowerCase();
    //         const element = document.getElementById('section-tour-package');
    //         if (element) {
    //             element.scrollIntoView({ behavior: "smooth" });
    //         }
    //     }
    // }, [location.state]);

    useEffect(() => {
    
        if (location.state?.packtype) {
            setActivePackageType(location.state?.packtype)
        } else {
            setActivePackageType('1')
        }
    }, [location.state]);



    // Check window width and update the dropdown state
    const handleResize = () => {
        if (window.innerWidth > 575) {
            setIsSmallScreen(false); // On large screens, allow dropdown to be open
            setIsDropdownOpen(true); // Open dropdown by default
        } else {
            setIsSmallScreen(true); // On small screens, keep dropdown closed
            setIsDropdownOpen(false); // Keep dropdown closed by default
        }
    };

    useEffect(() => {
        // Initial check
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Toggle dropdown on small screens (below 575px)
    const toggleDropdown = () => {
        if (isSmallScreen) {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };

    const handlepackage = (e) => {
        setActivePackage(e.target.value)
    }
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://chhuttiyan.com/tour-packages" />
                <title> Thailand tour packages operators in sector 17, sector 34, sector 47 Chandigarh | www.chhuttiyan.com</title>
                <meta name="description" content="Thailand tour packages | thailand tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta name="keywords" content="Thailand tour packages |  thailand tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta property="og:title" content="Thailand tour packages |  thailand tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta property="og:description" content="Thailand tour packages |  thailand tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta property="og:type" content="website" />

            </Helmet>
            <Header />
            <section className='paddingtop padding-top-mobile section-tour-package bg-light position-relative d-flex flex-column justify-content-center ' style={{ backgroundImage: `url(${bga2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>

                <div className='my-2 p-3 text-center'>
                    <h2 className=' text-white'>{activepackageType == 1 ? "International" : "Domestic"} <span className='display-pc'>Tour Packages</span></h2>
                    <h2 className=' text-white display-mobile'>Tour Packages </h2>
                </div>
                <div className=" stick  header-padding display-pc">
                    <Booking_pc/>
                </div>
              
            </section>
           <Booking_mobile/>
            <Section_2_Booking />
            <section className='section-tour section-3   header-padding mt-5 ' id='toursection' style={{ backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className=''>
                    <motion.h2
                        className="fw-800"

                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -30 }}
                        transition={{
                            duration: 1,
                        }}
                    >
                        {activepackageType == 1 ? "International" : "Domestic"} Tour Packages
                    </motion.h2>
                    <h3 className="fs-2 my-3">Let Us Take You on a Journey to Explore Visa-Free Countries and Hidden Gems Worldwide</h3>
                    <div className='border rounded-4'>
                        <div className="row">
                            <div className="col-sm-3 col-12 border-end p-2">
                                <div className=' px-1 py-2'>
                                    <div class="dropdown">
                                        <button
                                            className="btn border px-3 dropdown-toggle fs-2 fw-600 px-0"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            aria-expanded={isDropdownOpen ? "true" : "false"}
                                            onClick={toggleDropdown}
                                        >
                                            Filter
                                        </button>
                                        <div className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                                            aria-labelledby="dropdownMenuButton1">
                                            <div className=' h-100 '>
                                                <Accordion className="mb-3" activeKey={activepackageType}>
                                                    <Accordion.Item eventKey="1" className="border-0 border-bottom" onClick={() => { setActivePackageType('1') }}>
                                                        <Accordion.Header className="p-0 ">
                                                            <div>
                                                                <h3 className="fs-2 fw-600">International</h3>
                                                                <h3 className="fs-4 fw-600">visa free countries</h3>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body className="px-2">
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className="fs-3"
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className="bg-transparent">
                                                                    <i className="bi bi-search fs-3 "></i>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="packageType"
                                                                        id="custom-switch"
                                                                        label="Thailand"
                                                                        className="fs-3"
                                                                        value="Thailand"
                                                                        onChange={handlepackage}
                                                                        checked={activepackage === 'Thailand'}
                                                                    />
                                                                    <h4 className="p-1 text-orange fs-4">Trending<i className="bi bi-fire"></i></h4>
                                                                </div>

                                                                <Form.Check
                                                                    type="radio"
                                                                    name="packageType"
                                                                    id="custom-switch"
                                                                    label="Singapore"
                                                                    className="fs-3"
                                                                    value="Singapore"
                                                                    onChange={handlepackage}
                                                                    checked={activepackage === 'Singapore'}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="packageType"
                                                                    id="custom-switch"
                                                                    label="Malaysia"
                                                                    className="fs-3"
                                                                    value="Malaysia"
                                                                    onChange={handlepackage}
                                                                    checked={activepackage === 'Malaysia'}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="packageType"
                                                                    id="custom-switch"
                                                                    label="Dubai"
                                                                    className="fs-3"
                                                                    value="Dubai"
                                                                    onChange={handlepackage}
                                                                    checked={activepackage === 'Dubai'}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="packageType"
                                                                    id="custom-switch"
                                                                    label="Serbia"
                                                                    className="fs-3"
                                                                    value="Serbia"
                                                                    onChange={handlepackage}
                                                                    checked={activepackage === 'Serbia'}
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name="packageType"
                                                                    id="custom-switch"
                                                                    label="London"
                                                                    className="fs-3"
                                                                    value="London"
                                                                    onChange={handlepackage}
                                                                    checked={activepackage === 'London'}
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="2" className="border-0 border-bottom" onClick={()=>{setActivePackageType('2')}}>
                                                        <Accordion.Header className="p-0">
                                                            <h3 className="fs-2 fw-600">Domestic</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className="px-2">
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className="fs-3"
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className="bg-transparent">
                                                                    <i className="bi bi-search fs-3 "></i>
                                                                </InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="packageType"
                                                                        id="custom-switch"
                                                                        label="Ladakh"
                                                                        className="fs-3"
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(61)</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="packageType"
                                                                        id="custom-switch"
                                                                        label="Rajasthan"
                                                                        className="fs-3"
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(57)</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="packageType"
                                                                        id="custom-switch"
                                                                        label="Himachal"
                                                                        className="fs-3"
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(37)</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="packageType"
                                                                        id="custom-switch"
                                                                        label="Spiti Valley"
                                                                        className="fs-3"
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(37)</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="packageType"
                                                                        id="custom-switch"
                                                                        label="Goa"
                                                                        className="fs-3"
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(37)</h3>
                                                                </div>
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                                <div className=' bottom-0 p-2'>
                                                    <img src={packageads2} className='img-fluid rounded rounded-3 mb-2' />
                                                    {/* <img src={bookingads} className='img-fluid rounded rounded-3' /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-9 col-12">
                                <div className="row ">
                                    {slides.filter(item =>item.packtype === activepackage).map((slide, index) => (
                                        <div className="col-lg-4 col-md-6 col-6">
                                            <div className="card p-3 border-0" key={index}>
                                                <div
                                                    className="cardtourimg  d-flex flex-column justify-content-center"
                                                    style={{
                                                        background: `url(${slide.img}) center/cover no-repeat`,
                                                        height: "200px",
                                                    }}
                                                >
                                                    <h2 className="text-white">{slide.title}</h2>
                                                    <button className="btn btn-theme fs-4">Explore</button>
                                                </div>
                                                <div className="card-body border border-gray shadow shadow-md p-smm-1 ">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <h2 className='fs-1 text-orange'>
                                                            ₹{slide.price} <small className='fs-4 text-dark'>/ night</small>
                                                        </h2>
                                                        <h5 className='fs-2'> {slide.rating}
                                                            <FontAwesomeIcon

                                                                icon={faStar}
                                                                color='#FFD700'
                                                                className='ms-1'
                                                            />
                                                        </h5>


                                                    </div>
                                                    <h2 className="text-muted text-decoration-line-through fs-3">
                                                        ₹{slide.oldPrice}
                                                    </h2>
                                                    <h5 className='fs-3 my-2 text-gray'>If you are looking for a memorable Chandigarh trip </h5>
                                                    <div className="d-flex justify-content-start my-3">
                                                        <img src={post} className='me-3 my-2 bookingicone touricone' height={40} />
                                                        <img src={compass} className='me-3 my-2 bookingicone touricone' height={40} />
                                                        <img src={cycle} className='me-3 my-2 bookingicone touricone' height={40} />
                                                        <img src={boat} className='me-3 my-2 bookingicone touricone' height={40} />
                                                    </div>
                                                    <button className="btn btn-purple fs-3" id='' onClick={() => { navigate("/thailand-tour-packages", { state: { section: 'toursection' } }); }}>Explore</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {/* <Section_7_Merchandise place="Thailand" /> */}
            <Footer />
            <Tour_popup />
        </>
    )
}

export default Tour_packages
