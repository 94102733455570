import React, { useRef } from 'react'
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bangkok from '../../assets/img/thailand/bangkok.jpg'

import { useNavigate } from 'react-router-dom';
const Sec_9_blog = () => {
    const navigate = useNavigate()
    const sliderRef = useRef(null);
    const slides = [
        { title: "Pattaya", vedio:'/video/video2.mp4', price: 3999, oldPrice: 6999, rating: 4.7 },
        { title: "Pattaya", vedio:'/video/video1.mp4', price: 3999, oldPrice: 6999, rating: 4.7 },
        { title: "Bangkok", vedio:'/video/video1.mp4', price: 2999, oldPrice: 5999, rating: 4.6 },
    ];
    const settings = {
        dots: false, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 1000, // Transition speed
        slidesToShow: 3, // Show 3 slides at a time
        slidesToScroll: 1, // Scroll 1 slide at a time
        autoplay: 3000,
        arrows: true,
        prevArrow: <button className="custom-prev">Prev</button>, // Custom previous button
        nextArrow: <button className="custom-next">Next</button>, // Custom next button
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (
        <>
            <section className=' d-flex justify-content-center align-items-center py-4 p-2 position-relative'>

                <div className=" d-flex flex-column justify-content-center w-75 w-sm-100">
                 
                    <div className="w-90 w-sm-100">
                        <Slider ref={sliderRef}  {...settings}>
                            {slides.map((slide, index) => (
                                <div className="card p-3 border-0 " key={index}>
                                    <div
                                        className="cardtourimg  d-flex flex-column justify-content-center">
                                        <video className='blogvideo' controls >
                                            <source src={slide.vedio} type="video/mp4" />
                                          
                                        </video>
                                    
                                        
                                    </div>
                                
                                </div>
                            ))}
                        </Slider>
                            <button
                            className="bg-transparent border-0 fs-1 me-3 position-absolute btn-prev"
                                onClick={() => sliderRef.current.slickPrev()} // Trigger previous slide
                            >
                                <i className="bi bi-arrow-left-circle text-orange"></i>
                            </button>
                            <button
                            className="bg-transparent border-0 fs-1  position-absolute btn-next"
                                onClick={() => sliderRef.current.slickNext()} // Trigger next slide
                            >
                                <i className="bi bi-arrow-right-circle text-orange  "></i>
                            </button>
                    </div>
                  
                </div>
            </section>
        </>
    )
}

export default Sec_9_blog
