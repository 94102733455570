import React, { useEffect, useState , useRef} from 'react'
import Header from './Header'
import Footer from './Footer'
import { Nav, Tab, Tabs } from 'react-bootstrap';
import { Form, Modal } from 'react-bootstrap';
import LazyImageWrapper from '../common/LazyImg';
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import avatar from '../../assets/img/icone/user.png'
import diving from '../../assets/img/icone/diving.png'
import hotel from '../../assets/img/icone/hotel.png'
import flight from '../../assets/img/icone/flight.png'
import packages from '../../assets/img/icone/packages.png'
import car from '../../assets/img/icone/car.png'
import bike from '../../assets/img/icone/bike.png'
import { GetUser,GetPassenger } from '../Utility/API';
 import Notify_popup from './Notify_popup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Account = () => {
    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);
    const navigate = useNavigate()
    const [modalid, setModalId] = useState(3)
    const [show, setShow] = useState(false)
    const [imgpreview,setImgPreview] = useState('')
    const [show2, setShow2] = useState(false)
    const [editDate, setEditDate] = useState(false)
    const [readonly, setreadOnly] = useState(true)
    const [editactive, setEditActive] = useState('border-0 ps-0')
    const [editactive2, setEditActive2] = useState('border-0 ps-0')
    const [updatebtn, setUpdateBtn] = useState('display-none')
    const [updatebtn2, setUpdateBtn2] = useState('display-none')
    const [editbtn, setEditBtn] = useState('display-block')
    const [editbtn2, setEditBtn2] = useState('display-block')
    const [displaypass, setDisplayPass] = useState('display-none')
    const [displaypassbtn, setDisplayPassBtn] = useState('display-block')
    const [profilebtn, setProfileBtn] = useState('display-none')
    const [passenger, setPassenger] = useState([]);
    const [form, setForm] = useState({});
    const [form2, setForm2] = useState({ 'title': 'Mr', 'gender': 'Male', 'middle_name': '', 'passport': '', 'passport_country': '' });

    var formData = new FormData();
    const formatDate = (dateString) => {
        if (!dateString) return ""; // Handle empty value
        const date = new Date(dateString);
        return date.toLocaleDateString("en-GB"); // Converts to DD-MM-YYYY
    };


    const handleEdit = (id) => {
        if (id == 1) {
            setEditActive('')
            setreadOnly(false)
            setEditBtn('display-none')
            setUpdateBtn('display-block')     
        } else if (id == 2) {
            setEditDate(true)
            setEditActive2('')
            setEditBtn2('display-none')
            setUpdateBtn2('display-block')   
        }

    }
    const handleCancelEdit = (id) => {
        if (id == 1) {
            setEditActive('border-0 ps-0')
            setreadOnly(true)
            setEditBtn('display-block')
            setUpdateBtn('display-none')
        }
        else if (2) {
            setEditDate(false)
            setEditActive2('')
            setEditBtn2('display-block')
            setUpdateBtn2('display-none') 
        }
  
    }


    const GetUserData = async () => {
        const userdata = await GetUser()
        setForm(userdata)
    }
    const GetPassengerData = async () => {
        const userdata = await GetPassenger()
        const passengerData = Array.isArray(userdata) ? userdata : [userdata];
        setPassenger(passengerData)
        console.log('passenger Data', passengerData)
    }
    useEffect(() => {
        GetUserData()
        GetPassengerData()
    }, [])
    useEffect(() => {
        setForm2({
            ...form,
            'userid': form.id
        })
    }, [form2])

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            if (file.size > 1 * 1024 * 1024) {
                alert('File size should not exceed 1MB');
                e.target.value = "";
                return;
            }
            setForm((prevForm) => ({
                ...prevForm,
                [name]: file,
            }));
            setForm2((prevForm) => ({
                ...prevForm,
                [name]: file,
            }));

            // Generate image preview URL
            const previewURL = URL.createObjectURL(file);
            setImgPreview(previewURL);

            e.target.value = "";
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target
        setForm((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    }
    const handleChange2 = (e) => {
        const { name, value } = e.target
        setForm2((prevForm) => ({
            ...prevForm,
            [name]: value,
        }));
    }

    const handleSubmit = async (e, id) => {
        e.preventDefault();
        if (form.confirmpass && form.password != form.confirmpass) {
            alert("Password Not Matched Please try Again ! ")
            return;
        }
        if (id == 4) {
            formData.append('image', form.image || ''); 
            formData.append('id', form.id || ''); 
            try {
                const response = await axios.post(`https://chhuttiyan.com/backend/public/updateuser/${id}`, formData)
                if (response.data.status == 'success') {
                    GetUserData()
                    setShow2(true)
                    setTimeout(() => {
                        setShow2(false)
                    }, 3000)
                }

            } catch (error) {

            } 
        }
        else if (id == 5) {
            formData.append('image', form.image || '');
            formData.append('form', JSON.stringify(form2) || '');
            try {
                const response = await axios.post(`https://chhuttiyan.com/backend/public/updateuser/${id}`, formData)
                if (response.data.status == 'success') {
                    GetUserData()
                    setShow(false)
                    setShow2(true)
                    setTimeout(() => {
                        setShow2(false)
                    }, 3000)
                }

            } catch (error) {

            }
        }
        else {
            try {
                const response = await axios.post(`https://chhuttiyan.com/backend/public/updateuser/${id}`, form)
                if (response.data.status == 'success') {
                    GetUserData()
                    setEditActive('border-0 ps-0')
                    setreadOnly(true)
                    setEditBtn('display-block')
                    setUpdateBtn('display-none')
                    setEditBtn2('display-block')
                    setUpdateBtn2('display-none')
                    setDisplayPassBtn('display-block')
                    setDisplayPass('display-none')
                    setShow2(true)
                    setTimeout(() => {
                        setShow2(false)
                    }, 3000)
                }

            } catch (error) {

            } 
         }
           
        
    }

    return (
        <>
            <Header />
            <section className='h-100vh paddingtop padding-top-mobile header-padding  mb-3'>
                <div className='mt-3'>
                    <h2 className='text-dark '>Account Settings</h2>
                    {/* <h2 className='text-dark fs-4'>{JSON.stringify(form)}</h2> */}
                 
                </div>
                <div className='mt-3'>
                    <Tabs
                        defaultActiveKey="0"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="0" title="My Profile">
                            <div className="row g-sm-0 g-3">
                                <div className="col-sm-8 pe-3 ">
                                    <form >
                                        <div className="row  shadow shadow-md rounded rounded-4 p-3 bg-white">
                                            <div className="col-12 p-3 ">
                                                <div className='d-flex justify-content-between '>
                                                    <h2 className="fs-2 text-purple mb-2">Profile Details</h2>
                                                    <h2 className="fs-2-sm text-gray mb-2">Chhuttiyan.com</h2>
                                                </div>
                                                {/* <div className='d-flex justify-content-end '>
                                                    <h2 className={`fs-2-sm text-purple mb-2 btn p-0  ${editbtn}`} onClick={()=>{handleEdit(1)}}><i class="bi bi-pencil-square me-2"></i>Edit</h2>
                                                </div> */}
                                            </div>
                                            <div className="col-sm-2 col-12 ">
                                                <LazyImageWrapper
                                                    src={imgpreview ? imgpreview : form.image ? `https://chhuttiyan.com/backend/public/uploads/${form.image ? form.image : avatar}`:avatar}
                                                    alt="Image"
                                                    className="profileimg"
                                                />
                                            </div>
                                            <div className="col-sm-10 col-12 ">
                                                <form onSubmit={()=>{handleSubmit(4)}}>
                                                    <div className='mt-3 text-sm-start '>
                                                        <div className="d-flex justify-content-between">
                                                           <button className='btn  fs-2-sm border text-blue fw-600 bg-light ' onClick={() => { fileInputRef.current.click(); setProfileBtn('display-block') }}>Upload Profile Photo</button>
                                                            <h2 className={`fs-2-sm text-purple mb-2 btn p-0  ${editbtn}`} onClick={() => { handleEdit(1) }}><i class="bi bi-pencil-square me-2"></i>Edit</h2>
                                                        </div>
                                                        <input type="file" className='form-control py-2 fs-3 fw-600 d-none' ref={fileInputRef} name='image' accept="image/*"  onChange={handleFileChange} required />
                                                        <h2 className='fs-4 text-gray fw-400 ms-2 mt-2'>Image Size Should Be  Less Then 500kb</h2>
                                                        <div className={`d-flex justify-content-start ${profilebtn}`}>
                                                            <h2 className={`fs-3 text-white mb-2 btn text-orange border me-2  w-25 `} type="submit" onClick={(e) => { setProfileBtn('display-none') }}>CLose</h2>
                                                        <h2 className={`fs-3 text-white mb-2 btn bg-orange w-25 `} type="submit" onClick={(e) => { handleSubmit(e,4) }}>Update</h2>
                                                        </div>
                                                   </div>
                                                </form>
                                            </div>
                                            <div className="col-sm-6 px-2 mt-3">
                                                <h2 className='fs-3 text-purple mb-2'>Name</h2>
                                                <input type="text" name='name' className={`form-control fs-2-sm ${editactive} `} value={form.name ? form?.name : ''} onChange={handleChange} readOnly={readonly} />
                                            </div>
                                            <div className="col-sm-6 px-2 mt-3">
                                                <h2 className='fs-3 mb-2 text-purple'>Email</h2>
                                                <input type="email" name='email' className={`form-control fs-2-sm ${editactive} `} value={form.email ? form?.email : ''} onChange={handleChange} readOnly={readonly} />
                                            </div>
                                            <div className="col-sm-6 px-2 mt-3">
                                                <h2 className='fs-3 mb-2 text-purple'>User Type</h2>
                                                <input type="email" name='usertype' className={`form-control fs-2-sm border-0 ps-0 `} value={form.usertype ? form?.usertype : ''}  readOnly={true} />
                                            </div>
                                            <div className="col-sm-6 px-2 mt-3 ">
                                                <h2 className='fs-3 text-purple mb-2'>Mobile No.</h2>
                                                <input type="number" name='phone' className={`form-control fs-2-sm ${editactive} `} value={form.phone ? form?.phone : ''} onChange={handleChange} readOnly={readonly} />
                                            </div>
                                            <div className={`col-sm-6 px-2 mt-3 ${updatebtn}`}>
                                                <h2 className={`fs-3 text-white mb-2 btn text-orange border me-2 bg-light w-25 `} onClick={()=>{handleCancelEdit(1)}}>Cancel</h2>
                                                <h2 className={`fs-3 text-white mb-2 btn bg-orange w-25 `} type="submit" onClick={(e) => { handleSubmit(e, 1) }}>Update</h2>
                                            </div>

                                        </div>
                                    
                                    <div className="row  shadow shadow-md rounded rounded-4 p-3 mt-3  bg-white">
                                        <div className="col-12 p-2 d-flex justify-content-between">
                                            <h2 className="fs-2 text-purple mb-2">Special Occation Offers</h2>
                                            <h2 className="fs-2 text-purple mb-2">{editDate}</h2>
                                                <h2 className={`fs-2-sm text-purple mb-2 btn p-0  ${editbtn2}`} onClick={() => { handleEdit(2) }}><i class="bi bi-pencil-square me-2"></i>Edit</h2>
                                        </div>
                                        <div className="col-sm-6 px-2 mt-2">
                                                <h2 className='fs-3 mb-2 text-purple'>DOB</h2>
                                                {editDate ? (
                                                    <input type="date" name='dob' onChange={handleChange} className="form-control fs-2-sm" />
                                                ) : (
                                                        <h2 className="fs-2-sm text-gray fw-400 mb-2">{form.dob != '0000-00-00' ? formatDate(form.dob) :'No Record Found'}</h2>
                                                )}
                                        </div>
                                        <div className="col-sm-6 px-2 mt-2">
                                            <h2 className='fs-3 text-purple mb-2'>Anniversary Date (if any) </h2>
                                                {editDate ? <input type="date" name='annivarsary_date' onChange={handleChange} className='form-control fs-2-sm ' /> : <h2 className="fs-2-sm text-gray fw-400 mb-2">{form.annivarsary_date != '0000-00-00' ? formatDate(form.annivarsary_date): 'No Record Found'}</h2>}
                                            </div>
                                            <div className={`col-sm-6 px-2 mt-3 ${updatebtn2}`}>
                                                <h2 className={`fs-3 text-white mb-2 btn text-orange border me-2 bg-light w-25 `} onClick={() => { handleCancelEdit(2) }}>Cancel</h2>
                                                <h2 className={`fs-3 text-white mb-2 btn bg-orange w-25 `} type="submit" onClick={(e) => { handleSubmit(e, 2) }}>Update</h2>
                                            </div>
                                        <div className="col-12 px-2 mt-3">
                                            <h2 className='fs-3 text-purple fw-400 mb-2 display-mobile'>Get Special Offer's on special occation</h2>
                                        </div>

                                    </div>
                                    </form>
                                </div>
                                <div className="col-sm-4 pe-3  ">
                                    <div className="row  shadow shadow-md rounded rounded-4 p-3 bg-white">
                                        <div className="col-12 p-2">
                                            <h2 className="fs-2 text-purple mb-2">Wallet Balance</h2>
                                        </div>
                                        <div className="col-12 px-2 mt-3">
                                            <h2 className='fs-1 text-purple fw-400 mb-2'>₹0</h2>
                                        </div>

                                        <div className="col-12  mt-3">
                                            <button className='btn mx-2 fs-2-sm border text-blue fw-600 bg-light '>Add Balance</button>
                                        </div>


                                    </div>
                                    <form onSubmit={(e)=>{handleSubmit(e,3)}}>
                                    <div className="row  shadow shadow-md rounded rounded-4 p-3 bg-white mt-3">
                                        <div className="col-12 p-2">
                                            <h2 className="fs-2 text-purple mb-2">Change Password</h2>
                                        </div>
                                        <div className="col-12 px-2 ">
                                            <h2 className='fs-3 text-purple fw-400 mb-2'>To protect your personal information, please change your password every few months.</h2>
                                        </div>
                                            <div className={`col-sm-12 px-2 mt-3 ${displaypass}`}>
                                                <h2 className='fs-3 text-purple mb-2'>New Password</h2>
                                                <input type="password" name='password' className={`form-control fs-2-sm  `}  onChange={handleChange} placeholder='New Password'/>
                                            </div>
                                            <div className={`col-sm-12 px-2 mt-3 ${displaypass}`}>
                                                <h2 className='fs-3 text-purple mb-2'>Confirm Password</h2>
                                                <input type="password" name='confirmpass' className={`form-control fs-2-sm  `} onChange={handleChange} placeholder='Confirm Password' />
                                            </div>
                    
                                        <div className="col-12  mt-3 d-flex justify-content-start">
                                                <button className={`btn mx-2 fs-2-sm border text-blue fw-600 bg-light ${displaypassbtn}`} type='button' onClick={() => { setDisplayPass('display-block'); setDisplayPassBtn('display-none') }}>Change Password</button>
                                                <button className={`btn mx-2 fs-2-sm border text-blue fw-600 bg-light ${displaypass} me-2`} type='button' onClick={() => { setDisplayPass('display-none'); setDisplayPassBtn('display-block') }}>Cancel</button>
                                            <button className={`btn mx-2 fs-2-sm border bg-orange fw-600 bg-light ${displaypass}`} type='submit' >Update Password</button>
                                        </div>

                                        {/* <div className="col-12 px-2 mt-3">
                                            <h2 className='fs-3 text-purple mb-2'>New Pasword</h2>
                                            <input type="text" className='form-control fs-2-sm ' placeholder='Enter New ' readOnly />
                                        </div> */}

                                        </div>
                                    </form>
                                    <div className="row  shadow shadow-md rounded rounded-4 p-3 bg-white mt-3">
                                        <div className="col-12 p-2">
                                            <h2 className="fs-2 text-purple ">Logout</h2>
                                        </div>
                                        <div className="col-12 px-2 ">
                                            <h2 className='fs-3 text-purple fw-400 '>Don’t forget to logout when you're finished.</h2>
                                        </div>


                                        <div className="col-12  mt-3">
                                            <button className='btn mx-2 fs-2-sm border text-blue fw-600 bg-light ' onClick={() => { sessionStorage.setItem('isAuth', ''); sessionStorage.setItem('uid', ''); setModalId('2'); setShow2(true); setTimeout(() => { setShow2(false); navigate('/')},3000)}}><i class="bi bi-box-arrow-left me-2"></i>Logout</button>
                                        </div>

                                        {/* <div className="col-12 px-2 mt-3">
                                            <h2 className='fs-3 text-purple mb-2'>New Pasword</h2>
                                            <input type="text" className='form-control fs-2-sm ' placeholder='Enter New ' readOnly />
                                        </div> */}

                                    </div>
                                </div>

                            </div>
                        </Tab>
                        <Tab eventKey="1" title="Passenger">
                            <div className="row g-sm-0 g-3">
                                <div className="d-flex justify-content-start mb-3">
                                    <h2 className='text-purple fs-2 my-2 me-2'>Passenger Details <i className='bi bi-people ms-2 '></i></h2>
                                    <button className='btn mx-2 fs-2-sm border text-blue fw-600 bg-light ' onClick={() => { setShow(true) }}>Add Passenger<i className='bi bi-plus-circle ms-2'></i></button>
                                </div>
                                {passenger.map((item, index) => (
                                    <div className="col-sm-6 pe-3  order-sm-1 order-2" key={index}>
                                        <div className="row  shadow shadow-md rounded rounded-4 p-3 bg-white">
                                            <div className="d-flex justify-content-between">
                                                <h4 className='fs-4 text-gray'>Passenger {index+1}</h4>
                                                <h4 className='fs-4 text-gray'> Chhuttiyan.com</h4>
                                            </div>
                                            <div className="col-sm-2 d-flex justify-content-center ">
                                                <LazyImageWrapper
                                                    src={form.image ? `https://chhuttiyan.com/backend/public/uploads/${form.image}` : avatar}
                                                    alt="Image"
                                                    className="profileimg"
                                                />
                                            </div>
                                            <div className="col-sm-10 px-2">
                                                <div className='mt-0'>
                                                    <div className="d-flex justify-content-between">
                                                        <h2 className="fs-2 text-purple ms-2 mt-2">{item.title}. {item.first_name} {item.middle_name} {item.family_name}</h2>
                                                        <h2 className="fs-2-sm text-purple mt-2"><i class="bi bi-pencil-square me-2"></i>View Detail</h2>
                                                    </div>
                                                    <h2 className='fs-2-sm fw-400 ms-2 mt-2'>DOB : {formatDate(item.dob)}</h2>
                                                    <h2 className='fs-2-sm fw-400 ms-2 mt-2'>Mobile No : {item.phone}</h2>
                                                    <h2 className='fs-2-sm fw-400 ms-2 mt-2'>Email : {item.email}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            
                            </div>
                        </Tab>
                        <Tab eventKey="3" title="Manage Booking">
                            <Tabs
                                defaultActiveKey="b1"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="b1" title={
                                    <div className=''>
                                        <img
                                            src={diving}
                                            alt="Profile Icon"
                                            className='bookingicone  my-2'
                                        />
                                        <h4 className='fs-3 border-top py-2 border-3 border-purple'>All <span className='fs-4'>(0)</span></h4>
                                    </div>

                                }>
                                    <div className='d-flex justify-content-center '>
                                        <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                            <h4 className='fs-1 '>No Record Found</h4>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="b2" title={
                                    <div className=''>
                                        <img
                                            src={packages}
                                            alt="Profile Icon"
                                            className='bookingicone my-2'
                                        />
                                        <h4 className='fs-3 border-top py-2 border-3 border-purple'>Tour Packages <span className='fs-4'>(0)</span></h4>
                                    </div>
                                }>
                                    <div className='d-flex justify-content-center'>
                                        <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                            <h4 className='fs-1 '>No Record Found</h4>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="b3" title={
                                    <div className=''>
                                        <img
                                            src={hotel}
                                            alt="Profile Icon"
                                            className='bookingicone my-2'
                                        />
                                        <h4 className='fs-3 border-top py-2 border-3 border-purple'>Hotels <span className='fs-4'>(0)</span></h4>
                                    </div>
                                }>
                                    <div className='d-flex justify-content-center'>
                                        <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                            <h4 className='fs-1 '>No Record Found</h4>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="b4" title={
                                    <div className=''>
                                        <img
                                            src={flight}
                                            alt="Profile Icon"
                                            className='bookingicone my-2'
                                        />
                                        <h4 className='fs-3 border-top py-2 border-3 border-purple'>Flights<span className='fs-4'>(0)</span></h4>
                                    </div>
                                }>
                                    <div className='d-flex justify-content-center'>
                                        <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                            <h4 className='fs-1 '>No Record Found</h4>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="b5" title={
                                    <div className=''>
                                        <img
                                            src={car}
                                            alt="Profile Icon"
                                            className='bookingicone my-2'
                                        />
                                        <h4 className='fs-3 border-top py-2 border-3 border-purple'>Cars <span className='fs-4'>(0)</span></h4>
                                    </div>
                                }>
                                    <div className='d-flex justify-content-center'>
                                        <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                            <h4 className='fs-1 '>No Record Found</h4>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="b6" title={
                                    <div className=''>
                                        <img
                                            src={bike}
                                            alt="Profile Icon"
                                            className='bookingicone my-2'
                                        />
                                        <h4 className='fs-3 border-top py-2 border-3 border-purple'>Bikes <span className='fs-4'>(0)</span></h4>
                                    </div>
                                }>
                                    <div className='d-flex justify-content-center'>
                                        <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                            <h4 className='fs-1 '>No Record Found</h4>
                                        </div>
                                    </div>
                                </Tab>

                            </Tabs>
                        </Tab>
                        <Tab eventKey="4" title="Exclusive Offers">
                            <div className='d-flex justify-content-center'>
                                <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                    <h4 className='fs-1 '>No Record Found</h4>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="5" title="Gift Card">
                            <div className='d-flex justify-content-center'>
                                <div className='bg-white p-4 shadow shadow-md rounded rounded-4'>
                                    <h4 className='fs-1 '>No Record Found</h4>
                                </div>
                            </div>
                        </Tab>

                    </Tabs>
                </div>
                <Modal show={show} onHide={() => { setShow(false) }} dialogClassName="modal-dynamic" centered>
                    <Modal.Header className='p-3'>
                        <div className='container-fluid px-0 d-sm-flex d-block justify-content-between'>
                            <h1 className='fs-2 fw-bold text-purple '>Add Passenger Details</h1>
                            <h1 className='fs-2 fw-bold text-gray '>Chhuttiyan.com</h1>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='p-3'>
                        {/* <h2 className='text-dark fs-4'>{JSON.stringify(form2)}</h2> */}
                        <form onSubmit={(e) => { handleSubmit(e,5)}}>
                        <div className="container-fluid px-0">
                            <div className='border-bottom py-2'>
                                    <LazyImageWrapper
                                        src={imgpreview ? imgpreview :avatar}
                                        alt="Image"
                                        className="profileimg mb-2"
                                    />
                                <div>
                                        <button className='btn  fs-2-sm border text-blue fw-600 bg-light ' onClick={() => { fileInputRef2.current.click(); setProfileBtn('display-block') }}>Upload Profile Photo</button>
                                        <input type="file" className='form-control py-2 fs-3 fw-600 d-none' ref={fileInputRef2} name='image' accept="image/*" onChange={handleFileChange}  />
                                    <h4 className='fs-4 mx-2 my-2'>image size should not exceed 500kb</h4>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12 p-2">
                                    <h4 className='fs-3 text-purple fw-600'>Basic details</h4>
                                </div>
                                <div className="col-sm-3 col-1 p-2">
                                    <h4 className='fs-4'>Title</h4>
                                        <select className='form-select border fs-4 py-2' name='title' onChange={handleChange2}>
                                        <option defaultValue>Mr</option>
                                        <option >Mrs</option>
                                        <option >Ms</option>
                                        <option >Dr</option>
                                    </select>
                                </div>
                                <div className="col-sm-3 col-5 p-2">
                                    <h4 className='fs-4'>First Name</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='First Name' name='first_name' onChange={handleChange2} />
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Middle Name</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='Middle Name' name='middle_name' onChange={handleChange2} />
                                </div>
                                <div className="col-sm-3 col-12 p-2">
                                    <h4 className='fs-4'>Family Name</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='Family Name' name='family_name' onChange={handleChange2} />
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>DOB</h4>
                                        <input type='date' className='form-control fs-4 py-2' placeholder='Email' name='dob' onChange={handleChange2} />
                                </div>
                                <div className="col-sm-3 col-6 p-2">
                                    <h4 className='fs-4'>Gender</h4>
                                        <select className='form-select fs-4 py-2 border border-gary' name='gender' onChange={handleChange2} >
                                        <option className='' defaultValue='Gender'>Gender</option>
                                        <option className=''>Male</option>
                                        <option className='' >Female</option>
                                    </select>
                                </div>
                                <div className="col-sm-3 p-2">
                                    <h4 className='fs-4'>Email</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='Email' name='email' onChange={handleChange2}/>
                                </div>
                                <div className="col-sm-3 p-2">
                                    <h4 className='fs-4'>Mobile No.</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='Mobile No.' name='phone' onChange={handleChange2}/>
                                </div>
                                <div className="col-12 p-2">
                                    <h4 className='fs-3 text-purple fw-600'>Passport Details (if any)</h4>
                                </div>
                                <div className="col-sm-3 p-2">
                                    <h4 className='fs-4'>Passport Number</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='Passport Number' name='passport' onChange={handleChange2}/>
                                </div>

                                <div className="col-sm-3 p-2">
                                    <h4 className='fs-4'>Passport Issuing Country</h4>
                                        <input type='text' className='form-control fs-4 py-2' placeholder='Passport Issuing Country' name='passport_country' onChange={handleChange2}/>
                                </div>
                                <div className="col-sm-12 p-2">

                                    <div className="d-flex justify-content-between mt-3">
                                        <button className="btn bg-orange fs-3 w-25 w-sm-100 rounded rounded-3" data-tooltip-id="my-tooltip" data-tooltip-content="Complete Relevent Information" type='submit' >Submit</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </form>
                    </Modal.Body >
                </Modal>
            </section>

            <Notify_popup show={show2} id={modalid} onClose={() => { setShow2(false)}} />
            <Footer />
        </>
    )
}

export default Account
