import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import LazyImageWrapper from '../common/LazyImg';
import packageads from '../../assets/img/background/bg4.jpg'
import singapore from '../../assets/img/singapore/package1.jpg'
import america from '../../assets/img/america/package1.jpg'
import london from '../../assets/img/UK/package1.jpg'
import malaysia from '../../assets/img/Croatia/package1.jpg'
import phone from '../../assets/img/icone/phone.png'
import whatapp from '../../assets/img/icone/whatapp.png'
import email from '../../assets/img/icone/email.png'
import star from '../../assets/img/icone/star.png'
import { useNavigate } from 'react-router-dom';
const Tour_popup = () => {
    const [visible, setVisible] = useState(true);
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            setShow(true)
            setTimeout(() => {
                setShow(false)
            },8000)
        },4000)
    }, [])
    
    return (
        <>
            <Modal show={show} onHide={()=>{setShow(false)}} dialogClassName="modal-dynamic" centered>

                <Modal.Body className='p-2 '>
                    <div className="d-sm-flex d-block  justify-content-between align-items-start position-relative">
                        <div className="row " style={{ background: `url(${packageads})`, backgroundSize: 'cover', backgroundPosition: 'center ' }}>
                      
                            <div className="col-sm-12 col-12  bg-opacity" >

                                <div className=' px-3 text-center order-1 '>
                                    <h2 className='fs-2 fw-600 my-2 text-white '>Introducing</h2>
                                    <h2 className=' fw-600 my-2 text-white '>Thailand - Exclusive</h2>
                                    <h2 className='fs-1 fw-600 my-2 text-white text-white '>Only In <span className='text-white fw-bold'>₹13,999/-</span></h2>
                                    {/* <div className="d-flex">
                                        <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                        <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                        <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                        <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                        <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                    </div> */}
                            
                                    <h2 className='fs-2 fw-600 my-2 mt-2 text-white text-white '>3N/4D With Breakfast, Local Touring </h2>
                                    <h2 className='fs-2 fw-600 my-2 text-white text-white '>Bangkok-Pattaya </h2>
                                    {/* <h2 className='fs-2 fw-600 my-2 text-white '>Airport to Airport </h2> */}
                                    <h2 className='fs-4 fw-600 my-2 text-white '>(Air-Ticket  ₹21,000/- Extra )</h2>
                                    <button className='btn fs-2 bg-orange text-white my-2 my-3 ' onClick={()=>{navigate('/thailand-tour-packages')}}>Book Now</button>
                                    <h2 className='fs-2-sm fw-600 my-2 text-white '>Most Popular packages</h2>
                                    <div className="d-flex justify-content-between">
                                        <div className='p-1 bg-white border  me-1 btn cardimgsmdiv' onClick={() => { navigate('/singapore-tour-packages') }}>
                                            <LazyImageWrapper
                                                src={singapore}
                                                alt="Phone Icon"
                                                className="cardimgsm "
                                            />
                                            <h2 className='fs-4 fw-600 my-2 text-dark '>Singapore</h2>
                                            <h2 className='fs-4 fw-800 my-0 text-dark '>₹21,999/-</h2>
                                        </div>
                                        <div className='p-1 bg-white border  mx-1 btn cardimgsmdiv' onClick={() => { navigate('/london-tour-packages') }}>
                                            <LazyImageWrapper
                                                src={london}
                                                alt="Phone Icon"
                                                className="cardimgsm "
                                            />
                                            <h2 className='fs-4 fw-600 my-2 text-dark '>London</h2>
                                            <h2 className='fs-4 fw-800 my-0 text-dark  '>₹89,999/-</h2>
                                        </div>
                                        <div className='p-1 bg-white border mx-1 btn cardimgsmdiv' onClick={() => { navigate('/america-tour-packages') }}>
                                            <LazyImageWrapper
                                                src={america}
                                                alt="Phone Icon"
                                                className="cardimgsm "
                                            />
                                            <h2 className='fs-4 fw-600 my-2 text-dark '>America</h2>
                                            <h2 className='fs-4 fw-800 my-0 text-dark  '>₹89,999/-</h2>
                                        </div>
                                        <div className='p-1 bg-white border ms-1 btn cardimgsmdiv' onClick={() => { navigate('/croatia-tour-packages') }}>
                                            <LazyImageWrapper
                                                src={malaysia}
                                                alt="Phone Icon"
                                                className="cardimgsm "
                                            />
                                            <h2 className='fs-4 fw-600 my-2 text-dark'>Croatia</h2>
                                            <h2 className='fs-4 fw-800 my-0 text-dark  '>₹29,999/-</h2>
                                        </div>
                                    </div>
                                    {/* <h2 className='fs-2 fw-600 my-2 text-white '>Need Help ?</h2> */}
                                    <h2 className='fs-2 fw-600 my-2 text-orange'>
                                        <span className='text-decoration-underline btn px-0'>
                                            <a href="tel:9530700321" style={{ textDecoration: "none" }}>
                                                <LazyImageWrapper
                                                    src={phone}
                                                    alt="Phone Icon"
                                                    className="bookingicone contacticone me-2"
                                                />

                                            </a></span>
                                        <span className='text-decoration-underline btn px-0'>
                                            <a
                                                href="https://wa.me/9530700321"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ textDecoration: "none" }}
                                            >
                                                <LazyImageWrapper
                                                    src={whatapp}
                                                    alt="WhatsApp Icon"
                                                    className="bookingicone contacticone mx-2"
                                                />
                                            </a></span>
                                        <span className='text-decoration-underline btn px-0'>

                                            <LazyImageWrapper
                                                src={email}
                                                alt="Image"
                                                className="bookingicone contacticone ms-2"

                                            /></span></h2>
                                    <h2 className='fs-4 fw-600 mt-2 p-1 text-white'>Powered By</h2>
                                    <h2 className='fs-2 fw-600 mb-2 p-1 text-white'>Chhuttiyan.com</h2>
                                </div>
                            </div>
                        </div>
                
                        <div className='position-absolute close-btn'>
                             <button className='btn bg-transparent p-0 ' onClick={()=>{setShow(false)}}><i className='bi bi-x fs-1 fw-bold text-white '></i></button>
                     </div>
                    </div>
                </Modal.Body>

            </Modal>

            {/* <div className='search-pc'>
                    <div
                        className={`btn bg-dark-purple floating-help text-white bg-blue px-2 shadow shadow-md `} >
                        <div className='d-flex flex-column me-3'>
                            <img src={logo} className='bookingicone' />
                            <i class="bi bi-facebook fs-2 mx-2"></i>
                            <i class="bi bi-youtube fs-2 mx-2"></i>
                            <i class="bi bi-instagram fs-2 mx-2"></i>
                            <i class="bi bi-twitter fs-2 mx-2"></i>
                            <i class="bi bi-pinterest fs-2 mx-2"></i>
                        </div>
           
                    </div>
            </div> */}
        </>
    )
}

export default Tour_popup
