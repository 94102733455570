import React from 'react'
import Header from './Header'
import Footer from './Footer'
const Terms_Condition = () => {
  return (
      <>
          <Header />
          <section className='h-100vh paddingtop padding-top-mobile header-padding mb-3'>
              <div className='mt-3'>
                  <h2 className='text-dark mt-5 mb-2 fs-1'>Terms And Policies</h2>

                  {/* Cancellation & Date Change Policy */}
                  <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>
                      <h1 className='fs-3 fw-bold'>Cancellation & Date Change</h1>
                      <h1 className='fs-3'>Package Cancellation Policy</h1>
                      <h1 className='fs-3 text-green'>Cancellation Possible Before 10 Days From Starting Date*</h1>
                      <h1 className='fs-3 text-gray'>After that, the package is Non-Refundable.</h1>
                      <ul>
                          <li className='my-3 fs-3 text-gray'>Cancellation requests must be submitted via email or customer support.</li>
                          <li className='my-3 fs-3 text-gray'>A processing fee may be applicable for cancellations.</li>
                          <li className='my-3 fs-3 text-gray'>Refunds for cancellations will be issued as per the original payment method.</li>
                      </ul>
                  </div>

                  {/* Refund Policy */}
                  <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>
                      <h1 className='fs-3'>Refund Policy</h1>
                      <h1 className='fs-3 text-green'>Refund Processing Time: 7-10 Business Days</h1>
                      <h1 className='fs-3 text-gray'>Refunds will be processed as per the original payment method.</h1>
                      <ul>
                          <li className='my-3 fs-3 text-gray'>Refunds are subject to verification of cancellation requests.</li>
                          <li className='my-3 fs-3 text-gray'>Any service charges applied during booking will be non-refundable.</li>
                          <li className='my-3 fs-3 text-gray'>Refund timelines may vary depending on the payment provider.</li>
                      </ul>
                  </div>

                  {/* Terms & Conditions */}
                  <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>
                      <h1 className='fs-3'>Terms & Conditions</h1>
                      <ul>
                          <li className='my-3 fs-3 text-gray'>All users must comply with the company policies.</li>
                          <li className='my-3 fs-3 text-gray'>The company reserves the right to modify terms at any time.</li>
                          <li className='my-3 fs-3 text-gray'>Failure to comply with policies may result in account suspension.</li>
                          <li className='my-3 fs-3 text-gray'>All disputes are subject to jurisdiction as per company policy.</li>
                      </ul>
                  </div>

                  {/* Privacy Policy */}
                  <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>
                      <h1 className='fs-3'>Privacy Policy</h1>
                      <ul>
                          <li className='my-3 fs-3 text-gray'>We prioritize user data protection and confidentiality.</li>
                          <li className='my-3 fs-3 text-gray'>No personal information is shared with third parties without consent.</li>
                          <li className='my-3 fs-3 text-gray'>Users have the right to request data deletion at any time.</li>
                          <li className='my-3 fs-3 text-gray'>We use encryption technology to secure sensitive user data.</li>
                      </ul>
                  </div>

                  {/* Delivery & Shipping Policy */}
                  <div className='p-3 shadow shadow-sm border mt-3 bg-white display-pc'>
                      <h1 className='fs-3'>Delivery & Shipping Policy</h1>
                      <ul>
                          <li className='my-3 fs-3 text-gray'>Orders are processed within 24-48 hours.</li>
                          <li className='my-3 fs-3 text-gray'>Shipping timelines vary based on location.</li>
                          <li className='my-3 fs-3 text-gray'>Customers will receive tracking details once the order is shipped.</li>
                          <li className='my-3 fs-3 text-gray'>Delays due to unforeseen circumstances will be communicated promptly.</li>
                      </ul>
                  </div>
              </div>
          </section>
          <Footer/>
      </>
  )
}

export default Terms_Condition
