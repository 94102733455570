import React, { useState, useEffect } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer';
import diving from '../../assets/img/icone/diving.png'
import hotel from '../../assets/img/icone/hotel.png'
import flight from '../../assets/img/icone/flight.png'
import packages from '../../assets/img/icone/packages.png'
import car from '../../assets/img/icone/car.png'
import cloths from '../../assets/img/icone/cloths.png'

import Section_2_Booking from '../components/Sec_2_Booking'
function UnderCon() {


    return (
        <>

            <div class="container-fluid container-1 px-0">
                <Header />
                <section className='paddingtop padding-top-mobile section-tour-package bg-light position-relative d-flex flex-column justify-content-center'>

                    <div className='my-2 p-3 text-center'>
                        <h2 className=' text-white'>Underconstruction</h2>
                        <h2 className='fs-2 text-white bg-opacity'>Feature Available Soon</h2>
                       
                    </div>


                    <div className=" stick  header-padding display-pc">
                        <div className="bg-white   booking-section">
                            <div className="row h-100">
                                <div className="col-2 border-end border-bottom border-white d-flex justify-content-center booking-left-corner bg-orange">
                                    <img src={diving} className='me-3 bookingicone diving' height={50} />
                                    <h4 className='fs-3'>ACTIVITIES</h4>
                                </div>
                                <div className="col-2 border-end border-gray d-flex justify-content-center">
                                    <img src={hotel} className='me-3 bookingicone' height={50} />
                                    <h4 className='fs-3'>HOTELS</h4>
                                </div>
                                <div className="col-2 border-end border-gray d-flex justify-content-center">
                                    <img src={flight} className='me-3 bookingicone' height={50} />
                                    <h4 className='fs-3'>FLIGHTS</h4>
                                </div>
                                <div className="col-2 border-end border-gray d-flex justify-content-center">
                                    <img src={packages} className='me-3 bookingicone' height={40} />
                                    <h4 className='fs-3'>PACKAGES</h4>
                                </div>
                                <div className="col-2 border-end border-gray d-flex justify-content-center">
                                    <img src={car} className='me-3 bookingicone' height={50} />
                                    <h4 className='fs-3'>CAR/BIKES </h4>
                                </div>
                                <div className="col-2  d-flex justify-content-center booking-right-corner">
                                    <img src={cloths} className='me-3 bookingicone' height={40} />
                                    <h4 className='fs-3'>Merchandise</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <Section_2_Booking />
                <Footer/>
            </div>
        </>
    )
}

export default UnderCon;
