import React, { useEffect, useState, useRef } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { useUser } from '../global_data/Userdata'
import Notify_popup from './Notify_popup';
import { encryptData } from '../Utility/Encryption';
import axios from 'axios'
const Signup = ({ show, userType, authtype, onClose, onClose2 }) => {
  const timerRef = useRef(null);
  const { setUser, userId } = useUser()
  const [show2, setShow2] = useState(false)
  const [form, setForm] = useState({})
  const [otpform, setOtpForm] = useState({});
  //button name
  const [Resendbtn, setResendBtn] = useState('Request OTP')
  const [otpsenddisable, setOtpSendDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const [logintype, setlogintype] = useState(authtype)
  const [activeKey, setActiveKey] = useState(authtype)

  //Messages
  const [verifybtn, SetVerifyBtn] = useState('Verify OTP')
  const [verifymsg, SetVerifyMsg] = useState('Verifying OTP Please Wait')
  const [verifysubmsg, setVerifySubMsg] = useState('')
  const [greeting, setGreeting] = useState('')

  // verify btn color
  const [verifycolor, setVerifyColor] = useState('bg-orange')

  // display states
  const [displayotp, setDisplayOtp] = useState('display-none')
  const [display1, setDisplay1] = useState('display-block')
  const [display2, setDisplay2] = useState('display-none')
  const [display3, setDisplay3] = useState('display-block')
  const [display4, setDisplay4] = useState('display-none')
  const [display5, setDisplay5] = useState('display-block')
  const [display6, setDisplay6] = useState('display-none')
  const [display7, setDisplay7] = useState('display-none')
  const [display8, setDisplay8] = useState('display-none')
  const [display9, setDisplay9] = useState('display-block')
  const [display10, setDisplay10] = useState('display-block')
  const [display11, setDisplay11] = useState('display-block')



  // **************** Authentication Proccess ***********************
  useEffect(() => {
    setForm({
      ...form,
      'usertype': userType
    })
  }, [userType])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    })
  }
  const RegisterForm = () => {
    document.getElementById("registerform").reset();
  }
  const LoginForm = () => {
    document.getElementById("loginform").reset();
  }
  const handleSubmit = async (e, id) => {
    e.preventDefault();
    setVerifySubMsg(``)
    setResendBtn("OTP Sent")
    setOtpSendDisabled(true)
    setDisplayOtp('display-block')
    setCountdown(60);
    if (timerRef.current) clearInterval(timerRef.current);

    timerRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
          clearInterval(timerRef.current);
          setResendBtn("Request OTP");
          setOtpSendDisabled(false);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);


    if (id == 1) {
      setDisplay3('display-none')
      setDisplay4('display-block')
      setGreeting('')
      SetVerifyMsg('Please Wait..')
      try {
        const response = await axios.post('https://chhuttiyan.com/backend/public/sendotp', form)
        if (response.data.status === 'success') {
          SetVerifyMsg('OTP Sent Please Check')
          setOtpForm({
            'otpid': response.data.message
          })
        }
      }
      catch (error) {

      }
      finally {

      }
    } else if (id == 2) {
      if (form.password == form.cpass) {
        try {
          const response = await axios.post('https://chhuttiyan.com/backend/public/register', form)
          if (response.data === 'success') {
            //  alert('registered Successfully')
            setResendBtn('Request OTP')
            clearInterval(timerRef.current);
            SetVerifyMsg('')
            setGreeting(`Hello ${form.name}`)
            setActiveKey('login')
            setDisplay1('display-block')
            setDisplay2('display-none')
            setVerifyColor('bg-orange')
            setOtpSendDisabled(false)
            RegisterForm()
          }
        } catch (error) {

        }
      } else {
        SetVerifyMsg(`Password Not Matched`)
      }
    }
    else if (id == 3) {
      setGreeting('')
      SetVerifyMsg('Please Wait')
      setDisplay3('display-none')
      setDisplay4('display-block')
      try {
        const response = await axios.post('https://chhuttiyan.com/backend/public/loginuser', form)
        if (response.data.message === 'success') {
          const encryptedUserData = encryptData(response.data.data.id);
          console.log('encripted_data', encryptedUserData)
          SetVerifyMsg(`Login successful`)
          sessionStorage.setItem('isAuth', true)
          sessionStorage.setItem('uid', encryptedUserData)
          setTimeout(() => {
            onClose2()
            setShow2(true)
            setTimeout(() => {
              setShow2(false)
            }, 5000)
          }, 1000)
        }
        else if (response.data === 'error_password') {
          SetVerifyMsg('Invalid Password !')
        }
        else if (response.data.message === 'usertype_error') {
          setDisplay11('display-show')
          SetVerifyMsg('User Type Not Matched')
          setVerifySubMsg(`Please Login As ${response.data.data.usertype}`)
        }
        else if (response.data === 'error_user') {
          SetVerifyMsg('User Not Found!')
        }
      } catch (error) {

      }
    }
    else if (id == 4) {
      if (form.password == form.cpass) {
        try {
          const response = await axios.post('https://chhuttiyan.com/backend/public/resetpassword', form)
          if (response.data.status === 'success') {
            SetVerifyMsg(`Reset Successfully`)
            LoginForm()
            setTimeout(() => {
              setDisplay4('display-none')
              setDisplay3('display-block')
              setDisplay5('display-block')
              setDisplay7('display-none')
              setDisplay8('display-none')
              setDisplay9('display-block')
              setDisplay10('display-block')

            }, 1000)

          }
        } catch (error) {

        }
      } else {
        SetVerifyMsg(`Password Not Matched`)
      }
    }

  }
  const handleOtpChange = (e) => {
    const { name, value } = e.target;

    const updatedOtpForm = {
      ...otpform,
      [name]: value,
      email: form.email,
      usertype: userType,

    };
    setOtpForm(updatedOtpForm);
  }

  const handleSubmitOtp = async (e, id) => {
    e.preventDefault();
    setDisplay3('display-none')
    setDisplay4('display-block')
    SetVerifyBtn("Verifying")
    setVerifySubMsg(``)
    try {
      const response = await axios.post(`https://chhuttiyan.com/backend/public/otpverify/${id}`, otpform)
      if (response.data == 'success_register') {
        setVerifyColor('bg-green')
        SetVerifyBtn("Verified")
        setTimeout(() => {
          SetVerifyMsg(`Register As ${userType}`)
          setDisplay1('display-none')
          setDisplay2('display-block')
        }, 1000)

      }
      else if (response.data == 'user_exist') {

        SetVerifyMsg('User Alraedy Exist')
        setActiveKey('login')
      }
      else if (response.data.message == 'success_login') {
        SetVerifyMsg(`Login successful`)
        setUser(response.data.data.id)
        sessionStorage.setItem('isAuth', true)
        setTimeout(() => {

          onClose2()
          setShow2(true)
          setTimeout(() => {
            setShow2(false)
          }, 5000)
        }, 1000)
      }

      else if (response.data == 'User_error') {
        SetVerifyMsg('User Not Exist')
      }
      else if (response.data.message == 'Usertype_error') {
        SetVerifyMsg('User Type Not Matched')
        setVerifySubMsg(`Please Login As ${response.data.data.usertype}`)
      }
      else if (response.data.message == 'reset_password') {
        SetVerifyMsg(`Reset Password`)
        setDisplay9('display-none')
        setDisplay8('display-block')
        // SetVerifyMsg('User Type Not Matched')
      }
      else if (response.data == "invalid_otp") {
        SetVerifyMsg('Invalid OTP')
        SetVerifyBtn("Verify Again")
      }
    }
    catch (error) {

    }
    finally {

    }
  }
  useEffect(() => {
    if (authtype == 'register') {
      setActiveKey("register");
      setlogintype("Register");
    } else {
      setActiveKey("login");
      setlogintype("Login");
    }
  }, [authtype])
  const handleLoginType = (key) => {
    setActiveKey(key); // Update the active tab state
    if (key === 'register') {
      setlogintype("Register");
    } else {
      setlogintype("Login");
    }
  };



  const handleClose = () => {
    setShow2(false)
  }


  return (
    <>
      <Modal show={show} onHide={onClose} centered className='signup-modal '>

        <div className="bg-white shadow shadow-lg signin-div">
          <div className="row border">
            <div className="col-sm-7 col-12 bg-dark-purple" >
              <div className='d-flex flex-column justify-content-center h-100 position-relative'>
                <h1 className='fs-3 mb-2 text-white' >Welcome To Chhuttiyan.com</h1>
                <h1 className={`fs-1 text-white ${display3}`} style={{ textShadow: '2px 2px 30px' }}>{logintype} As {activeKey == 'register' ? form.usertype : userType}</h1>
                <h1 className={`fs-1 text-white ${display4}`} style={{ textShadow: '2px 2px 30px' }}>{verifymsg}{greeting}</h1>
                <h1 className={`fs-2 text-white ${display11}`} style={{ textShadow: '2px 2px 30px' }}>{verifysubmsg}</h1>
                <div className='position-absolute close-btn display-mobile'>
                  <button className='btn bg-transparent bg-transparent p-1 py-0' onClick={onClose}><i className='bi bi-x fs-1 fw-bold text-white '></i></button>
                </div>
              </div>
              {/* <img src={packageads} className='me-1  h-100 img-fluid' /> */}

            </div>
            <div className="col-sm-5 col-12 p-3">
              <div className='px-3'>
                <Tabs
                  activeKey={activeKey}
                  id="uncontrolled-tab-example"
                  className="mb-3"
                  onSelect={handleLoginType}
                >
                  <Tab eventKey="login" title="Login">
                    <form onSubmit={(e) => handleSubmit(e, 1)} id='loginform'>
                      <div className={`row g-3 mt-3 ${display9}`} >
                        <div className={`col-12 ${display7}`}>
                          <h1 className='fs-3 mb-2'>Verify Email To Reset Password</h1>
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Enter Your Email</h1>
                          <input type='Email' name='email' className='fs-3 p-2 form-control' placeholder='Enter Your Email' onChange={handleChange} required />
                        </div>
                        <div className={`col-12 ${display5}`}>
                          <div class="input-group mb-3">
                            <input type="text" class="form-control fs-3 p-2" placeholder="Enter OTP" name='otp' onChange={handleOtpChange} />
                            <div class="input-group-append">
                              <span class="input-group-text fs-3 p-2 border bg-orange text-center w-100 px-3 btn" id="basic-addon2" onClick={(e) => { handleSubmitOtp(e, 2) }}>Verify</span>
                            </div>
                          </div>
                        </div>
                        <div className={`col-12 ${display7}`}>
                          <div class="input-group mb-3">
                            <input type="text" class="form-control fs-3 p-2" placeholder="Enter OTP" name='otp' onChange={handleOtpChange} />
                            <div class="input-group-append">
                              <span class="input-group-text fs-3 p-2 border bg-orange text-center w-100 px-3 btn" id="basic-addon2" onClick={(e) => { handleSubmitOtp(e, 3) }}>Verify</span>
                            </div>
                          </div>
                        </div>
                        <div className={`col-12 ${display6}`}>
                          <div class="input-group mb-3">
                            <input type="password" class="form-control fs-3 p-2" placeholder="Enter Password" name='password' onChange={handleChange} />
                            <div class="input-group-append">
                              <span class="input-group-text fs-3 p-2 border bg-orange text-center w-100 px-3 btn" id="basic-addon2" ><i className='bi bi-eye'></i></span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-0">
                          <div className="d-flex justify-content-between">
                            <h1 className={`fs-4 ${display5} btn  fw-600 px-0`} onClick={() => { setDisplay5('display-none'); setDisplay6('display-block') }}>Login With Password</h1>
                            <h1 className={`fs-4 ${display6} btn  fw-600 px-0`} onClick={() => { setDisplay5('display-block'); setDisplay6('display-none') }}>Login With OTP</h1>
                            <h1 className={`fs-4 ${display10} btn  fw-600 px-0`} onClick={() => { setDisplay7('display-block'); setDisplay5('display-none'); setDisplay10('display-none') }} >Forget Password</h1>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <button className={`btn bg-orange fs-3 ${display5} ${display7}`} type="submit">Request OTP</button>
                            <button className={`btn  fs-3 text-white bg-orange ${display6}`} type='btn' onClick={(e) => handleSubmit(e, 3)}>Login</button>
                            <h1 className={`fs-4 text-gray ${display5}`}>Resend  In {countdown} Sec</h1>
                          </div>
                        </div>
                      </div>
                      <div className={`row mt-3 g-3 ${display8}`}>
                        <div className="col-12">
                          <h1 className='fs-2 mb-2'>Set New Password</h1>
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Password</h1>
                          <input type='Email' className='fs-3 p-2 form-control' name="password" placeholder='Password' onChange={handleChange} />
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Confirm Password</h1>
                          <input type='number' className='fs-3 p-2 form-control' name="cpass" placeholder='Confirm Password' onChange={handleChange} />
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <button className='btn bg-orange fs-3' type='button' onClick={(e) => { handleSubmit(e, 4) }} >Save </button>
                            {/* <button className='btn bg-orange fs-3' type='button' onClick={() => { setDisplay2('display-none'); setDisplay1('display-block')}}>back</button> */}

                          </div>
                        </div>
                      </div>
                    </form>
                  </Tab>
                  <Tab eventKey="register" title="Register">
                    <form onSubmit={(e) => { handleSubmit(e, 1) }} id="registerform">
                      <div className={`row g-3 mt-3 ${display1}`}>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Register As</h1>
                          <select className='fs-3 border form-select' name='usertype' onChange={handleChange} required>
                            <option defaultValue='Guest'>Guest User</option>
                            {/* <option value="Corporate">Corporate</option>
                            <option value="Hotel">Hotels </option>
                            <option value="Institution">Institution</option> */}
                          </select>
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Name</h1>
                          <input type='text' className='fs-3 p-2 form-control' name="name" placeholder='Enter Your Name' onChange={handleChange} />
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Mobile No.</h1>
                          <input type='number' className='fs-3 p-2 form-control' name="phone" placeholder='Enter Your Mobile No.' onChange={handleChange} />
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Email</h1>
                          <input type='Email' className='fs-3 p-2 form-control' name="email" placeholder='Enter Your Email' onChange={handleChange} />
                        </div>

                        <div className={`col-12 ${displayotp}`}>
                          <div class="input-group mb-3">
                            <input type="text" class="form-control fs-3 p-2" name="otp" placeholder="Enter OTP" aria-label="Recipient's username" aria-describedby="basic-addon2" onChange={handleOtpChange} />
                            <div class="input-group-append">
                              <span class={`input-group-text fs-3 p-2 border ${verifycolor} text-center w-100 px-4 `} id="basic-addon2" type="button" onClick={(e) => { handleSubmitOtp(e, 1) }}>{verifybtn}</span>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <button className='btn bg-orange fs-3' type='submit' disabled={otpsenddisable}>{Resendbtn}</button>
                            <h1 className='fs-4 text-gray'>Resend  In {countdown} Sec</h1>
                          </div>
                        </div>
                      </div>
                      <div className={`row mt-3 g-3 ${display2}`}>
                        <div className="col-12">
                          <h1 className='fs-2 mb-2'>Set New Password</h1>
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Password</h1>
                          <input type='password' className='fs-3 p-2 form-control' name="password" placeholder='Password' onChange={handleChange} />
                        </div>
                        <div className="col-12">
                          <h1 className='fs-3 mb-2'>Confirm Password</h1>
                          <input type='password' className='fs-3 p-2 form-control' name="cpass" placeholder='Confirm Password' onChange={handleChange} />
                        </div>

                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <button className='btn bg-orange fs-3' type='button' onClick={(e) => { handleSubmit(e, 2) }} >Register</button>
                            {/* <button className='btn bg-orange fs-3' type='button' onClick={() => { setDisplay2('display-none'); setDisplay1('display-block')}}>back</button> */}

                          </div>
                        </div>
                      </div>
                    </form>
                  </Tab>

                </Tabs>
              </div>

            </div>
          </div>
          <div className='position-absolute close-btn display-pc'>
            <button className='btn bg-transparent bg-orange p-1 py-0' onClick={onClose}><i className='bi bi-x fs-1 fw-bold text-white '></i></button>
          </div>
          <div className='position-absolute ' style={{ top: '8%', left: "30%" }}>
            {/* {JSON.stringify(form)} */}
            {/* {userType} */}
            {/* {activeKey} */}
            {/* <h2 className='fw-600'>Welcome To Chhuttiyan.com</h2> */}
          </div>
        </div>
      </Modal>
      <Notify_popup show={show2} id={1} onClose={handleClose} />
    </>
  )
}

export default Signup
