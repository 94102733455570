import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GTM = () => {
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-PSJQZX66' });
    }, []);

    return null;
};

export default GTM;