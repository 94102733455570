import React from 'react'
import menu from '../../assets/img/icone/menu.png'
import logo from '../../assets/img/logo/logo2.png';
import { useNavigate, NavLink } from 'react-router-dom';
import cardimg3 from '../../assets/img/thailand/bangkok.jpg'
import { Dropdown } from 'react-bootstrap';
import star from '../../assets/img/icone/star.png'
function Header_Canvas() {

    const navigate = useNavigate()
    const handleScroll = (e, id) => {
        e.preventDefault();
        const closeButton = document.querySelector('[data-bs-dismiss="offcanvas"]');
        if (closeButton) {
            closeButton.click();
        }

        navigate('/')
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "end" });
            }
        }, 300)
    };
    return (
        <>
            <div className='nav-mobile custom-scrollbar'>

                <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                    <img src={menu} className='' height={40} />
                </button>

                <div class="offcanvas offcanvas-start bg-theme" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div class="offcanvas-header">
                        <h2 className='fs-1'> <img src={logo} className='' height={40} /> Chhuttiyan.com</h2>
                        <button type="button" class="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <ul class="list-unstyled" >
                            <li className='border p-2' onClick={(e) => handleScroll(e, 'home')}><a class=" fs-2 text-white fw-600 " href="#">Home</a></li>
                            <li className='border p-2' >
                                <Dropdown>
                                <Dropdown.Toggle className='text-white bg-transparent border-0 fs-2 fw-600 px-0' id="dropdown-basic">
                                    Tour Packages
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { window.open('/tour-packages', { state: { packtype: '1' } }) }} className='fs-3 border-bottom'>International Packages</Dropdown.Item>
                                        <Dropdown.Item onClick={() => { window.open('/tour-packages', { state: { packtype: '2' } }) }} className='fs-3'>Domestic Packages</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown></li>
                            <li className='border p-2' >
                                <Dropdown>
                                <Dropdown.Toggle className='text-white bg-transparent border-0 fs-2 fw-600 px-0' id="dropdown-basic">
                                   Hotels
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                        <Dropdown.Item  className='fs-3 border-bottom'>International Hotels</Dropdown.Item>
                                        <Dropdown.Item  className='fs-3'>Domestic Hotels</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown></li>
                            
                            <li className='border p-2' onClick={(e) => handleScroll(e, 'ourvision')}><a class=" fs-2 text-white fw-600" href="#" >Flight Booking</a></li>
                            
                          
                            <li className="border p-2">
                                <Dropdown>
                                    <Dropdown.Toggle className='text-white bg-transparent border-0 fs-2 fw-600 px-0' id="dropdown-basic">
                                        List Your Business
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item className='fs-3 border-bottom'>List Your Hotel<span className='fs-4 text-green ms-3'>Free</span></Dropdown.Item>
                                        <Dropdown.Item className='fs-3'>List Your Company <span className='fs-4 text-green ms-3'>Free</span></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className='border p-2' onClick={(e) => handleScroll(e, 'ourvision')}><a class=" fs-2 text-white fw-600" href="#" >Merchandise</a></li>
                            <li className="nav-item dropdown border ">
                                <NavLink className="nav-link  fs-2 mx-2   text-white fw-600 px-0" aria-current="page" >Franchise </NavLink>
                            </li>
                            <li className='border p-2 d-flex' ><input type="text" className='form-control' placeholder='Search..' /><button className='btn bg-orange  ms-2'>Search</button></li>

                        </ul>
                        <div className='my-2 text-center bg-white p-3 '>
                            <h1 className='fs-1 text-orange fw-800'>Trending</h1>
                            <h1 className='fs-2 '></h1>
                            <div className="row w-75 w-sm-100">
                                <div className="col-sm-4 col-12 p-3 " >
                                    <div className='py-3 h-100 d-flex flex-column justify-content-between  cardimg position-relative' style={{ background: `url(${cardimg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        <div className="overlay"></div>
                                        <div className="p-2 position-relative">
                                            <button className="btn btn-theme fw-600 rounded rounded-4 fs-4">
                                                Explore the World
                                            </button>
                                        </div>
                                        <div className="p-2 text-center position-relative">
                                            <h2 className="fw-bold text-white fs-1">Thailand Tour Packages</h2>
                                            <div className="d-flex">
                                                <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                                <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                                <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                                <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                                <img src={star} className='me-1 my-2 ratingstars' height={40} />
                                            </div>
                                        </div>
                                        <div className="p-2 position-relative">
                                            <button className="btn btn-purple fw-600 rounded rounded-2 fs-3 ">
                                                Read More
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <h1 className='fs-1 text-orange fw-800 text-end'>View All <i className='bi bi-arrow-right'></i></h1>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Header_Canvas
