
import React, { useState, useEffect, useRef } from 'react'

import { Helmet } from 'react-helmet';
import Footer from '../common/Footer';
import {useUser} from '../global_data/Userdata'
import Sec_1_Home from '../components/Sec_1_Home';
import Sec_2_Booking from '../components/Sec_2_Booking';
import Sec_3_Tourpack from '../components/Sec_3_Tourpack';
import Sec_4_Tourslider from '../components/Sec_4_Tourslider';
import Sec_5_Tourpack from '../components/Sec_5_Tourpack';
import Sec_6_Trending from '../components/Sec_6_Trending';
import Sec_7_Merchan from '../components/Sec_7_Merchan';
import Sec_8_Contact from '../components/Sec_8_Contact';
import Tour_popup from '../common/Tour_popup';
import Sec_9_blog from '../components/Sect_9_blog';
import Career_popup from '../common/Career_popup';
function Home() {
    const [location, setLocation] = useState(null);
    // useEffect(() => {
    //     fetch("https://ipapi.co/json/") 
    //         .then(response => response.json())
    //         .then(data => {
    //             console.log("User Location:", data);
    //             setLocation(data);
    //         })
    //         .catch(error => console.error("Error fetching location:", error));
    // }, []);
    // useEffect(() => {
    //     if (navigator.geolocation) {
    //         navigator.geolocation.getCurrentPosition(
    //             (position) => {
    //                 console.log("Latitude:", position.coords.latitude);
    //                 console.log("Longitude:", position.coords.longitude);
    //             },
    //             (error) => console.error("Error fetching GPS location:", error)
    //         );
    //     } else {
    //         console.log("Geolocation is not supported by this browser.");
    //     }
    // }, []);
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://chhuttiyan.com/" />
                <title> Thailand tour packages operators in sector 17, sector 34, sector 47 Chandigarh | www.chhuttiyan.com</title>
                <meta name="description" content="Thailand tour packages | Thailand tour packages operators in sector 17, sector 34, sector 47 Chandigarh | tour packages operators in chandigarh |  www.chhuttiyan.com" />
                <meta name="keywords" content="Thailand tour packages | Thailand tour packages operators in sector 17, sector 34, sector 47 Chandigarh | tour packages operators in chandigarh |  www.chhuttiyan.com" />
                <meta property="og:title" content="Thailand tour packages | Thailand tour packages operators in sector 17, sector 34, sector 47 Chandigarh | tour packages operators in chandigarh |  www.chhuttiyan.com" />
                <meta property="og:description" content="Thailand tour packages | Thailand tour packages operators in sector 17, sector 34, sector 47 Chandigarh | tour packages operators in chandigarh |  www.chhuttiyan.com" />
                <meta property="og:type" content="website" />

            </Helmet>
            {/* START SECTION 1 */}
            {/* <div>
                {location ? (
                    <div>
                        <h2>Visitor Details:</h2>
                        <p>IP Address: {location.ip}</p>
                        <p>City: {location.city}</p>
                        <p>Region: {location.region}</p>
                        <p>Country: {location.country_name}</p>
                        <p>ISP: {location.org}</p>
                    </div>
                ) : (
                    <p>Loading location...</p>
                )}
            </div> */}
            <Sec_1_Home/>
             {/* <Sec_Home/> */}
            {/* END SECTION 1 */}


            {/* START SECTION 2 */}
            <Sec_2_Booking/>
            {/* END SECTION 2 */}


            {/* START SECTION 3 */}
            <Sec_3_Tourpack/>
            {/* END SECTION 3*/}



            {/* START SECTION 4 */}
            <Sec_4_Tourslider />
            {/* END SECTION 4*/}

            {/* START SECTION 5*/} 
            <Sec_5_Tourpack/>
            {/* END SECTION 5*/}


            {/* Start SECTION 6*/}
            <Sec_6_Trending/>
            {/* END SECTION-6  */}

            {/* START SECTION 7*/}
           <Sec_7_Merchan place='Thailand'/>
            {/* END SECTION 7*/}
            {/* Start SECTION 8*/}
            <Sec_8_Contact/>
            {/* END SECTION-8 */}

            <Tour_popup />
            <Career_popup/>
            <Sec_9_blog/>
            <Footer />

        </>
    )
}

export default Home
