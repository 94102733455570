import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY; 

// Encrypt data
export const encryptData = (data) => {
    const ciphertext = CryptoJS.AES.encrypt(
        JSON.stringify(data),
        SECRET_KEY
    ).toString();
    return ciphertext;
};

// Decrypt data
export const decryptData = (ciphertext) => {
    try {
        const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
        const originalData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return originalData;
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
};
