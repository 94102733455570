import React from 'react'
import trend1 from '../../assets/img/Hotel/trend1.png'
import { useNavigate } from 'react-router-dom'
function Footer() {
    const navigate = useNavigate()
  return (
      <footer className='section-6 bg-theme d-flex' id='footer'>
          <div className="row g-3 w-100">
              <div className="col-12 p-2 px-3  px-2">
                  {/* <h1 className='text-bold text-white fs-2 footerheading'>Chandigarh Residents Progressive Front</h1> */}
              </div>
              <div className="col-12 p-2 px-3">
                  <div className="row g-3">
                      <div className="col-lg-3 col-md-4 col-sm-4 col-12 order-sm-1 order-1 px-2">
                          <div className='p-3 pb-1 px-0'>
                              <div className=' pb-2 border-gray'>
                                  <p className='mb-2 fs-3 rounded rounded-1  py-1  fw-bold' style={{ textAlign: 'start' }}>Chhuttiyan.com</p>
                                  <p className='fs-4 fw-600 text-white '  style={{textAlign:'justify'}} >Thailand - Bangkok, Pattaya, Phuket, Krabi Tour Package | Singapore Tour Package | Serbia Tour Package | Croatia Tour Package | London Tour Package | America - New York, California | Canada - Montreal, Toronto Tour Package | Japan, South Korea Tour Package | Advertise your products, reach millions of clients, choose from hundreds of products & explore thousands of destinations, all at Chhuttiyan.com</p>
                                  <p className='fs-3 fw-600 text-white' >Our Special Leh - Ladakh Motorcycle Tour, Ask Now  +91-9530700321</p>
                                    <button className='bg-orange fs-4 p-2 rounded rounded-3' onClick={()=>{navigate('/contact')}}>Contact</button>
                              </div>
                              <div className='nav-mobile'>
                              <h3 className='text-white fw-800 mb-2 fs-3 px-0 py-1 btn w-100 py-0 my-0 text-start' >CHHUTTIYAN.COM</h3>
                              <p className='fs-4 fw-600 text-white mb-0' >
                                  <i class="bi bi-geo-alt me-1 fs-2"></i>
                                  CORPORATE OFFICE:
                                  </p>
                                  <p className='fs-4 ms-3'>  Show Room - 17, Sector 125,
                                      (Old Sunny Enclave) Extn. 5,
                                      Mohali - 140301
                                      Punjab</p>
                                  <p className='fs-4 fw-600 text-white ' ><i class="bi bi-telephone me-2 fs-2"></i> Mobile No :-  +91-9530700321</p>
                                  <p className='fs-4 fw-600 text-white' ><i class="bi bi-envelope me-2 fs-2"></i> Business@Chhuttiyan.com</p>
                                  <p className='fs-4 fw-600 text-white' ><i class="bi bi-globe me-2 fs-2"></i> www.chhuttiyan.com</p>
                                  <h3 className='text-white fw-800 mb-2 fs-3 px-2 py-1 btn w-100  my-0 text-start btn  bg-orange ' >CAREER WITH CHHUTTIYAN.COM</h3>
                                  <p className='fs-4 fw-600 text-white' >Chhuttiyan.com is an equal employer</p>
                                  <div className="d-flex justify-content-start" >
                                      <i class="bi bi-facebook fs-1 mx-2"></i>
                                      <i class="bi bi-youtube fs-1 mx-2"></i>
                                      <i class="bi bi-instagram fs-1 mx-2"></i>
                                      <i class="bi bi-twitter fs-1 mx-2"></i>
                                      <i class="bi bi-pinterest fs-1 mx-2"></i>
                                      <i class="bi bi-telegram fs-1 mx-2"></i>
                                  </div>
                          </div>
                          </div>

                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 col-12 order-sm-2 order-2 px-2 px-smm-1">
                          <div className="p-2 px-2  px-smm-1">
                              <div className=' p-3 px-2 '>
                                  <h4 className='text-white fs-3'>BLOG POSTS</h4>
                                  <div className="row g-3 my-2  w-100 ">
                                      <div className="col-12">
                                          <div className="row">
                                              <div className="col-3">
                                                  <img src={trend1} className='me-1 img-fluid' />
                                              </div>
                                              <div className="col-9">
                                                  <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                                                      <h2 className="fs-3 fw-400">Travel with us this year</h2>
                                                      <h2 className="fs-3 text-orange my-2">Nov 29, 2024</h2>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-12">
                                          <div className="row">
                                              <div className="col-3">
                                                  <img src={trend1} className='me-1 img-fluid' />
                                              </div>
                                              <div className="col-9">
                                                  <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                                                      <h2 className="fs-3 fw-400">New destinations for you</h2>
                                                      <h2 className="fs-3 text-orange my-2">Nov 29, 2024</h2>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-12">
                                          <div className="row">
                                              <div className="col-3">
                                                  <img src={trend1} className='me-1 img-fluid' />
                                              </div>
                                              <div className="col-9">
                                                  <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                                                      <h2 className="fs-3 fw-400">Travel with us this year</h2>
                                                      <h2 className="fs-3 text-orange my-2">Nov 29, 2024</h2>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-12">
                                          <div className="row">
                                              <div className="col-3">
                                                  <img src={trend1} className='me-1 img-fluid' />
                                              </div>
                                              <div className="col-9">
                                                  <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                                                      <h2 className="fs-3 fw-400">Travel with us this year</h2>
                                                      <h2 className="fs-3 text-orange my-2">Nov 29, 2024</h2>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>

                                      </div>
                              </div>
                              {/* <h4 className='text-white fw-light fs-4 mt-3' style={{ textAlign: 'justify', wordSpacing: '-3px' }}>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quibusdam maxime cumque porro, laboriosam, ut quo rerum illum vero labore numquam odit voluptatem sequi tempore perferendis natus expedita? Voluptates, placeat ullam! Lorem ipsum, dolor sit amet consectetur atus expedita? Voluptates </h4> */}
                          </div>

                      </div>
                      <div className="col-lg-3 col-md-4 col-sm-4 col-12 order-sm-3 order-4 px-0" >
                          <div className='p-3 pb-4  border-gray'>
                               <h4 className='text-white fs-3'>FRANCHISE</h4>
                              <h3 className=' text-white   fs-3  px-0 py-1 btn w-100 py-0 my-0 text-start'>Start your tour and travel business with just investment of Rs 5 Lakhs with chhuttiyan.com</h3>
                              <h3 className=' text-white  mb-2 fs-3  px-0 py-1 btn w-100 py-0 my-0' style={{ textAlign: 'start' }} onClick={() => { window.open('/department', '_blank') }}>PRODUCTS / SERVICES</h3>

                              <div className="row g-2">
                                  <div className="col-3">
                                      <h2 className="fs-4 border p-2 m-1">Tour</h2>
                                  </div>
                                  <div className="col-3">
                                      <h2 className="fs-4 border p-2 m-1">Travel</h2>
                                  </div>
                                  <div className="col-3">
                                      <h2 className="fs-4 border p-2 m-1">Hotels</h2>
                                  </div>
                                  <div className="col-3">
                                      <h2 className="fs-4 border p-2 m-1">Flights</h2>     
                                  </div>
                                  <div className="col-4">
                                      <h2 className="fs-4 border p-2 m-1">Franchise</h2>     
                                  </div>
                                  <div className="col-4 d-flex align-items-center">
                                      <h2 className="fs-4 border p-2 m-1">Embassies</h2>     
                                  </div>
                                  <div className="col-4 d-flex align-items-center">
                                      <h2 className="fs-4 border p-2 ">Holidays</h2>     
                                  </div>
                                  <div className="col-6">
                                      <h2 className="fs-4 border p-2 m-1">Bike Rentals</h2>     
                                  </div>
                                  <div className="col-6">
                                      <h2 className="fs-4 border p-2 m-1">Car Rentals</h2>     
                                  </div>
                                  <div className="col-6">
                                      <h2 className="fs-4 border p-2 m-1">Food Chain</h2>     
                                  </div>
                                  <div className="col-3">
                                      <h2 className="fs-4 border p-2 m-1">Music</h2>     
                                  </div>
                                  <div className="col-3 d-flex align-items-center">
                                      <h2 className="fs-4 border p-2 m-1">Ladakh</h2>     
                                  </div>
                                  <div className="col-6">
                                      <h2 className="fs-4 border p-2 m-1">Advertisement</h2>     
                                  </div>
                                  <div className="col-6">
                                      <h2 className="fs-4 border p-2 m-1">Investors</h2>     
                                  </div>
                              </div>
            
                          </div>
                   
                      </div>
                      <div className="col-md-3 col-sm-2 col-12  order-sm-4 order-3 px-2 nav-pc">
                          <div className='p-3 pb-4  border-gray '>
                              <h3 className='text-white fw-800 mb-2 fs-3 px-0 py-1 btn w-100 py-0 my-0 text-start' >CHHUTTIYAN.COM</h3>
                              
                              <p className='fs-4 fw-600 text-white' >
                                  <i class="bi bi-geo-alt me-2"></i>
                                  Corporate Office:
                                  Show Room - 17, Sector 125,
                                  (Old Sunny Enclave) Extn. 5,
                                  Mohali - 140301
                                  Punjab</p>
                              <p className='fs-4 fw-600 text-white' ><i class="bi bi-telephone me-2"></i> Mobile No :-  +91-9530700321</p>
                              <p className='fs-4 fw-600 text-white' ><i class="bi bi-envelope me-2"></i> Business@Chhuttiyan.com</p>
                              <p className='fs-4 fw-600 text-white' ><i class="bi bi-globe me-2"></i> www.chhuttiyan.com</p>
                              <h3 className='text-white fw-800 mb-2 fs-3 px-2 py-1 btn w-100  my-0 text-start btn  bg-orange ' >CAREER WITH CHHUTTIYAN.COM</h3>
                              <p className='fs-4 fw-600 text-white' >Chhuttiyan.com is an equal employer</p>
                              <div className="d-flex justify-content-start" >
                                  <i class="bi bi-facebook fs-2 mx-2"></i>
                                  <i class="bi bi-youtube fs-2 mx-2"></i>
                                  <i class="bi bi-instagram fs-2 mx-2"></i>
                                  <i class="bi bi-twitter fs-2 mx-2"></i>
                                  <i class="bi bi-pinterest fs-2 mx-2"></i>
                                  <i class="bi bi-telegram fs-2 mx-2"></i>
                              </div>
                          </div>
            
                      </div>
                  
            
                  </div>
              </div>

              <div className="col-12 p-2 px-3 px-2 border-top border-gray d-sm-flex d-block justify-content-between pt-3">
                  <h1 className=' text-white fs-5  pb-2'>Copyright © All rights reserved  by <span className='text-white'>Sundlass Corporate (OPC) Pvt Ltd</span></h1>
                  {/* <h1 className=' text-white fs-5  pb-2'>Copyright © All rights reserved  by <span className='text-white'>Chhuttiyan.com</span></h1> */}
                  <div className='d-flex'>
                      <h1 className=' text-white fs-5 me-2 btn p-0' onClick={()=>{navigate('/terms_and_condition')}}><span className='text-white'>Terms And Condition</span></h1>
                      <h1 className=' text-white fs-5 mx-2'>|</h1>
                      <h1 className=' text-white fs-5 mx-2' onClick={() => { navigate('/terms_and_condition') }}>Privacy</h1>
                      <h1 className=' text-white fs-5 mx-2 btn p-0'>|</h1>
                      <h1 className=' text-white fs-5 '>Website designed and hosted by <span className='text-white'> PKS Infotech Pvt Ltd</span></h1>
                  </div>

              </div>
          </div>

      </footer>
  )
}

export default Footer
