import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import LazyImageWrapper from '../common/LazyImg';
import { Accordion, InputGroup, Form, Nav, Tab, Tabs } from 'react-bootstrap';
import Header from '../common/Header'
import bga2 from '../../assets/img/Croatia/croatia_bg.jpg'
import packageimg2 from '../../assets/img/Croatia/package1.jpg'
import packageads2 from '../../assets/img/thailand/packagead2.jpg'


import Section_2_Booking from '../components/Sec_2_Booking'
import Section_7_Merchandise from '../components/Sec_7_Merchan'
import Footer from '../common/Footer'
import Tour_popup from '../common/Tour_popup'
import { radio } from '@mui/material';
import Booking_pc from '../common/Booking-pc';
import Booking_mobile from '../common/Booking-mobile';

const Singapore_package = () => {
    const [file, setFile] = useState(null);
    const [key, setKey] = useState('Honeymoon');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate()
    useEffect(() => {
        if (location.state?.section) {
            // const elementId = location.state.section.toLowerCase();
            const element = document.getElementById('section-tour-package');
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        }
    }, [location.state]);


    const handleFileChange = (e) => {
        const pdfFile = e.target.files[0];
        if (pdfFile && pdfFile.type === "application/pdf") {
            const pdfURL = URL.createObjectURL(pdfFile);
            setFile(pdfURL);
        } else {
            alert("Please select a valid PDF file");
        }
    };

    // Check window width and update the dropdown state
    const handleResize = () => {
        if (window.innerWidth > 575) {
            setIsSmallScreen(false); // On large screens, allow dropdown to be open
            setIsDropdownOpen(true); // Open dropdown by default
        } else {
            setIsSmallScreen(true); // On small screens, keep dropdown closed
            setIsDropdownOpen(false); // Keep dropdown closed by default
        }
    };

    useEffect(() => {
        // Initial check
        handleResize();

        // Add event listener on window resize
        window.addEventListener("resize", handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    // Toggle dropdown on small screens (below 575px)
    const toggleDropdown = () => {
        if (isSmallScreen) {
            setIsDropdownOpen(!isDropdownOpen);
        }
    };
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://chhuttiyan.com/croatia-tour-packages" />
                <title> Croatia  tour package operators in sector 17, sector 34, sector 47 Chandigarh | www.chhuttiyan.com</title>
                <meta name="description" content="Croatia  tour packages | Croatia  tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta name="keywords" content="Croatia  tour packages |  Croatia  tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta property="og:title" content="Croatia  tour packages |  Croatia  tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta property="og:description" content="Croatia  tour packages |  Croatia  tour packages operator in chandigarh | www.chhuttiyan.com" />
                <meta property="og:type" content="website" />

            </Helmet>
            <Header />
            <section className='paddingtop padding-top-mobile section-tour-package bg-light position-relative d-flex flex-column justify-content-center' style={{ backgroundImage: `url(${bga2})`, backgroundSize: 'cover', backgroundPosition: 'center ' }}>

                <div className='my-2 p-3 bg-opacity'>
                    <h2 className=' text-white'>Croatia Tour Package - Inter city Coach, Promotional Activity</h2>
                    <h2 className=' text-white fs-2 fw-800'><span className='text-decoration-underline btn fs-2 text-white onhover fw-800 p-0' onClick={() => { navigate('/tour-package-operators') }}>Tour Package Operators</span>,<span className='text-decoration-underline btn fs-2 text-white onhover fw-800 p-0' onClick={() => { navigate('/corporates') }}>Corporates</span>  - Call Now For Booking of Group Tours - 9530700321</h2>
                </div>
                <div className=" stick  header-padding display-pc">
                   <Booking_pc/>
                </div>
            </section>
            <Booking_mobile/>
            <Section_2_Booking />
            <section className='section-tour section-3   header-padding mt-5 ' id='toursection' style={{ backgroundPosition: 'center', backgroundSize: 'cover' }}>
                <div className=''>
                    <motion.h2
                        className="fw-800"

                        initial={{ opacity: 0, y: 30 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -30 }}
                        transition={{
                            duration: 1,
                        }}
                    >
                        Croatia  Tour Packages
                    </motion.h2>
                    <p className="fs-2 mt-2 fw-600 text-gray mb-0">Croatia  Tour Package for Corporates, Groups, Agents, Travel Companies, Tour Operators In Chandigarh</p>
                    <p className="fs-2  mb-3  fw-600 text-gray">Luxury Escapes and Budget-Friendly Adventures: Tailored Croatia  Experiences for Every Traveler</p>
                    <div className='border rounded-4'>
                        <div className="row">
                            <div className="col-sm-3 col-12 border-end p-2">
                                <div className=' px-1 py-2'>
                                    <div class="dropdown">
                                        <button
                                            className="btn border px-3 dropdown-toggle fs-2 fw-600 px-0"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            aria-expanded={isDropdownOpen ? "true" : "false"}
                                            onClick={toggleDropdown}
                                        >
                                            Filter
                                        </button>
                                        <div className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                                            aria-labelledby="dropdownMenuButton1">
                                            <div className=' h-100 '>
                                                <Accordion className='mb-3' defaultActiveKey="1">

                                                    <Accordion.Item eventKey="1" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">Package Type</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name='packageType'
                                                                        id="custom-switch"
                                                                        label="Honeymoon"
                                                                        className='fs-3'
                                                                    />
                                                                    <h4 className='p-1 text-orange fs-4'>Trending<i class="bi bi-fire"></i></h4>
                                                                </div>

                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Solo Tour"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Group Tour"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Family Tour"
                                                                    className='fs-3'
                                                                />
                                                                <div className="d-flex justify-content-between">

                                                                    <Form.Check
                                                                        type="radio"
                                                                        name='packageType'
                                                                        id="custom-switch"
                                                                        label="All Women Package"
                                                                        className='fs-3  '
                                                                    />
                                                                    <h4 className='p-1 text-orange  fs-4'>Popular</h4>
                                                                </div>
                                                                <div className="d-flex justify-content-between">

                                                                    <Form.Check
                                                                        type="radio"
                                                                        name='packageType'
                                                                        id="custom-switch"
                                                                        label="All Men Package"
                                                                        className='fs-3'
                                                                    />
                                                                    <h4 className='p-1 text-orange fs-4'>Popular</h4>
                                                                </div>

                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Educational Tour"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Shopping Exclusive"
                                                                    className='fs-3'
                                                                />
                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="0" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">City</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name='packageType'
                                                                        id="custom-switch"
                                                                        label="Puket"
                                                                        className='fs-3'
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(61)</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name='packageType'
                                                                        id="custom-switch"
                                                                        label="Bangkok"
                                                                        className='fs-3'
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(57)</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name='packageType'
                                                                        id="custom-switch"
                                                                        label="Pattaya"
                                                                        className='fs-3'
                                                                    />
                                                                    <h3 className="fs-3 fw-light">(37)</h3>
                                                                </div>

                                                            </Form>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">Customizable</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Customizable Packages"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Group Package"
                                                                    className='fs-3'
                                                                />

                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">Premium Packages</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Premium Packages"
                                                                    className='fs-3'
                                                                />


                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">Corporate Packages</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Events"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Shows"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Events"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Conference"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Incentive Tour"
                                                                    className='fs-3'
                                                                />


                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">School/Colleges</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Events"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Shows"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Events"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Conference"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Incentive Tour"
                                                                    className='fs-3'
                                                                />


                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="5" className='border-0 border-bottom'>
                                                        <Accordion.Header className='p-0'>
                                                            <h3 className="fs-2 fw-600">Customizable</h3>
                                                        </Accordion.Header>
                                                        <Accordion.Body className='px-2'>
                                                            <InputGroup className="mb-3">
                                                                <Form.Control
                                                                    className='fs-3'
                                                                    placeholder="Search"
                                                                    aria-label="Search"
                                                                    aria-describedby="basic-addon1"
                                                                />
                                                                <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="bi bi-search fs-3 "></i></InputGroup.Text>
                                                            </InputGroup>
                                                            <Form>
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Customizable Packages"
                                                                    className='fs-3'
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    name='packageType'
                                                                    id="custom-switch"
                                                                    label="Group Package"
                                                                    className='fs-3'
                                                                />

                                                            </Form>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                                <div className=' bottom-0 p-2'>
                                                    <img src={packageads2} className='img-fluid rounded rounded-3 mb-2' />
                                                    {/* <img src={bookingads} className='img-fluid rounded rounded-3' /> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-sm-9 col-12">
                                <div className="row " >
                                    <div className="col-12 border-bottom py-3 p-3" >
                                        <h1 className='fs-2 fw-bold mb-0'>Croatia  Tour Package </h1>
                                    </div>
                                    <div className="col-sm-6 col-12 btn text-start mt-2" >
                                        <div className="card p-3 border-0 mt-2 shadow shadow-md " >
                                            <div className=""  >
                                                <LazyImageWrapper
                                                    src={packageimg2}
                                                    alt="Image"
                                                    className="img-fluid "
                                                />
                                            </div>
                                            <div className="card-body  border-gray px-2 pb-1">
                                                <div className=" pb-3 ">
                                                    <h3 className='fs-2 fs-sm-2'>Epic Croatia  Vacation </h3>
                                                    <div className='d-flex justify-content-between align-items-start  mt-2'>
                                                        <h3 className='fs-2 fs-sm-2 text-gray fw-600'>3N Singapore</h3>
                                                        <h3 className='fs-4 p-1 ms-3 border border-purple border-2 text-purple fw-600 rounded-3' >3N/4D</h3>
                                                    </div>
                                                </div>

                                                <div className='border  rounded rounded-3 p-2 my-2'>
                                                    <h4 className='fs-3 fw-400 text-orange '>Inclusive of  3 & 4 star Hotels on twin sharing basis with Bouffet Breakfast, Inter city Coach, Promotional Activity with Lunch all Transfers in private cars included, Call - 9530700321</h4>
                                                </div>
                                                <div className='border  rounded rounded-3 p-2'>
                                                    <h4 className='fs-3 fw-400 text-green '>Get Upto 50% discount on first booking for limited customers</h4>
                                                </div>

                                                <div className='p-2 my-2'>
                                                    <h2 className='fs-2 fs-sm-2'>₹29,999/<span className='text-gray fs-4 fw-400 border-pass'>₹59,998</span><span className='text-gray fs-4 fw-400 ms-2'>per person</span></h2>
                                                    <h2 className='fs-4 fw-400 text-gray'>Inclusive of all taxes</h2>
                                                </div>
                                            </div>
                                            <div className='card-footer bg-white  py-3 d-flex align-items-start justify-content-between'>
                                                <button className='btn fs-3 btn-purple' onClick={() => { window.open('/croatia-package-booking/4', '_blank') }}>Book Now</button>
                                                <h2 className='fs-4 fw-400 text-gray'>Customizable</h2>
                                            </div>
                                        </div>  
                                        <div className="card p-3 border-0 mt-2 shadow shadow-md " >
                                            <div className=""  >
                                                <LazyImageWrapper
                                                    src={packageimg2}
                                                    alt="Image"
                                                    className="img-fluid "
                                                />
                                            </div>
                                            <div className="card-body  border-gray px-2 pb-1">
                                                <div className=" pb-3 ">
                                                    <h3 className='fs-2 fs-sm-2'>Epic Croatia  Vacation </h3>
                                                    <div className='d-flex justify-content-between align-items-start  mt-2'>
                                                        <h3 className='fs-2 fs-sm-2 text-gray fw-600'>3N Singapore</h3>
                                                        <h3 className='fs-4 p-1 ms-3 border border-purple border-2 text-purple fw-600 rounded-3' >3N/4D</h3>
                                                    </div>
                                                </div>

                                                <div className='border  rounded rounded-3 p-2 my-2'>
                                                    <h4 className='fs-3 fw-400 text-orange '>Inclusive of  3 & 4 star Hotels on twin sharing basis with Bouffet Breakfast, Inter city Coach, Promotional Activity with Lunch all Transfers in private cars included, Call - 9530700321</h4>
                                                </div>
                                                <div className='border  rounded rounded-3 p-2'>
                                                    <h4 className='fs-3 fw-400 text-green '>Get Upto 50% discount on first booking for limited customers</h4>
                                                </div>

                                                <div className='p-2 my-2'>
                                                    <h2 className='fs-2 fs-sm-2'>₹29,999/<span className='text-gray fs-4 fw-400 border-pass'>₹59,998</span><span className='text-gray fs-4 fw-400 ms-2'>per person</span></h2>
                                                    <h2 className='fs-4 fw-400 text-gray'>Inclusive of all taxes</h2>
                                                </div>
                                            </div>
                                            <div className='card-footer bg-white  py-3 d-flex align-items-start justify-content-between'>
                                                <button className='btn fs-3 btn-purple' onClick={() => { window.open('/croatia-package-booking/4', '_blank') }}>Book Now</button>
                                                <h2 className='fs-4 fw-400 text-gray'>Customizable</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 btn text-start">

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Section_7_Merchandise place="Croatia" />
            <Footer />
            <Tour_popup />
        </>
    )
}

export default Singapore_package
