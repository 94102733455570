import React from 'react'
import LazyImageWrapper from '../common/LazyImg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import thaicloth1 from '../../assets/img/Marchandise/thaicloth1.jpg'
import thaicloth2 from '../../assets/img/Marchandise/thaicloth2.jpg'
import thaicloth3 from '../../assets/img/Marchandise/thaicloth3.jpg'
import thaicloth4 from '../../assets/img/Marchandise/thaicloth4.jpg'
import thaicloth5 from '../../assets/img/Marchandise/thaicloth5.jpg'
import thaicloth6 from '../../assets/img/Marchandise/thaicloth6.jpg'
import thaicloth7 from '../../assets/img/Marchandise/thaicloth7.jpg'
import thaicloth8 from '../../assets/img/Marchandise/thaicloth8.jpg'
const Merchandise = () => {
    const slides = [
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth1, price: 599, oldPrice: 1199, rating: 4.6 },
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth2, price: 499, oldPrice: 999, rating: 4.7 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth3, price: 599, oldPrice: 1199, rating: 4.8 },
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth4, price: 399, oldPrice: 799, rating: 4.8 },
        { title: "Chhuttiyan – Women's Summer Wear & Accessories", img: thaicloth5, price: 499, oldPrice: 999, rating: 4.8 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth6, price: 499, oldPrice: 999, rating: 4.8 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth7, price: 599, oldPrice: 1199, rating: 4.8 },
        { title: "Chhuttiyan – Men's Summer Wear & Accessories", img: thaicloth8, price: 399, oldPrice: 799, rating: 4.8 },
    ];
  return (
    <>
        
              {slides.map((slide, index) => (
                  <div className='col-lg-3 col-md-6 col-6'>
                      <div className="card px-2 border-0" key={index}>
                          <div className=" "  >
                              <LazyImageWrapper
                                  src={slide.img}
                                  alt="Image"
                                  className="img-fluid"
                              />
                          </div>
                          <div className="card-body border border-gray">
                              <h2 className='fs-4 text-gray'>
                                  {slide.title}
                              </h2>

                         
                              <h5 className='fs-4 my-1'><span className='me-1'>{slide.rating}</span>
                                  {[...Array(5)].map((_, i) => (
                                      <FontAwesomeIcon
                                          key={i}
                                          icon={faStar}
                                          color={i < slide.rating ? "#FFD700" : "#D3D3D3"}
                                          className='fs-4'
                                      />
                                  ))}
                              </h5>
                              <h2 className='fs-2 text-orange '>
                                  ₹{slide.price} <small className='fs-4 text-dark border-pass'>/  ₹{slide.oldPrice}</small>
                              </h2>

                              <div className="d-flex justify-content-between">
                                  <h2 className='fs-2 mb-2 text-green fw-500 display-mobile'>
                                      50% OFF
                                  </h2>
                              <h5 className='fs-3 mb-2 text-gray'>Hot Deal</h5>
                              </div>
                       
                              <div className="d-flex justify-content-between w-100 pe-2">
                                  <button className="btn btn-purple fs-3">Add Now</button>
                                  <i className="bi bi-heart fs-2"></i>
                              </div>

                          </div>
                      </div>
                  </div>
              ))}
         
    </>
  )
}

export default Merchandise
