import React,{useState} from 'react'
import diving from '../../assets/img/icone/diving.png'
import hotel from '../../assets/img/icone/hotel.png'
import flight from '../../assets/img/icone/flight.png'
import packages from '../../assets/img/icone/packages.png'
import car from '../../assets/img/icone/car.png'
import bike from '../../assets/img/icone/bike.png'
import cloths from '../../assets/img/icone/cloths.png'
import about from '../../assets/img/icone/about.png'
import { useNavigate } from 'react-router-dom'
const Booking_pc = () => {
    const navigate = useNavigate()
    const [carimg, setCarImg] = useState(car)
    const [color1, setColor1] = useState('text-dark')
    const [color2, setColor2] = useState('text-dark')
  return (
    <>
          <div className="bg-white   booking-section">
              <div className="row h-100">
                  <div className="col-2 border-end border-bottom border-white d-flex justify-content-center booking-left-corner bg-orange">
                      <img src={diving} className='me-3 bookingicone diving' height={50} />
                      <h4 className='fs-3'>ACTIVITIES</h4>
                  </div>
                  <div className="col-2 border-end border-gray d-flex justify-content-center">
                      <img src={hotel} className='me-3 bookingicone' height={50} />
                      <h4 className='fs-3'>HOTELS</h4>
                  </div>
                  {/* <div className="col-2 border-end border-gray d-flex justify-content-center">
                      <img src={flight} className='me-3 bookingicone' height={50} />
                      <h4 className='fs-3'>FLIGHTS</h4>
                  </div> */}
                  <div className="col-2 border-end border-gray d-flex justify-content-center btn" onClick={() => { navigate("/tour-packages", { state: { section: 'toursection' } }) }}>
                      <img src={packages} className='me-3 bookingicone' height={40} />
                      <h4 className='fs-3'>PACKAGES</h4>
                  </div>
                  <div className="col-2 border-end border-gray d-flex justify-content-center">
                      <img src={carimg} className='me-3 bookingicone' height={50} />
                      <h4 className='fs-3' onMouseLeave={() => { setColor1('text-dark'); setColor2('text-dark') }} onClick={() => { navigate('/chhuttiyan-cabs') }}><span className={`btn fs-3 p-2 ${color1}`} onMouseEnter={() => { setCarImg(car); setColor1('text-white'); setColor2('text-dark') }} >CAR</span>/<span className={`btn fs-3 p-2 ${color2}`} onMouseEnter={() => { setCarImg(bike); setColor2('text-white'); setColor1('text-dark') }} onClick={() => { navigate('/chhuttiyan-bikes') }}>BIKES</span> </h4>
                  </div>
                  <div className="col-2 border-end border-gray d-flex justify-content-center ">
                      <img src={cloths} className='me-3 bookingicone' height={40} />
                      <h4 className='fs-3'>Merchandise</h4>
                  </div>
                  <div className="col-2  d-flex justify-content-center booking-right-corner">
                      <img src={about} className='me-3 bookingicone' height={40} />
                      <h4 className='fs-3'>About US</h4>
                  </div>
              </div>
          </div>
    </>
  )
}

export default Booking_pc
