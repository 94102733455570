import axios from "axios";
import React from "react";
import { decryptData } from "./Encryption";
export const GetUser = async () => {
    const userid = decryptData(sessionStorage.getItem('uid'))
    try {
        const response = await axios.get(`https://chhuttiyan.com/backend/public/getuser/${userid}`)
        if (response.data.status == 'success') {
            return response.data.data
        } else {
            console.log('user not found ')
        }

    } catch (error) {
        console.log(error)
    }
}
export const GetPassenger = async () => {
    const userid = decryptData(sessionStorage.getItem('uid'))
    try {
        const response = await axios.get(`https://chhuttiyan.com/backend/public/getpassenger/${userid}`)
        if (response.data.status == 'success') {
            return response.data.data
        } else {
            console.log('user not found ')
        }

    } catch (error) {
        console.log(error)
    }
}
