import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Home from './component/pages/Home';
import UnderConstruction from './component/pages/Undercon';
import Tour_package from './component/pages/Thailand_packages';
import Book_packages from './component/pages/Tour_Booking';
import ScrollToTop from './component/Utility/ScrolltoTop';
import Gmt from './component/Utility/GMT';
import Tour_packages from './component/pages/Tour_pack_general';
import Contact from './component/pages/Contact';
import Signup from './component/common/Signup';
import Career from './component/pages/Career';
import { UserProvider, useUser } from './component/global_data/Userdata';
import Singapore_package from './component/pages/Singapore_package';
import Uk_packages from './component/pages/Uk_package';
import Croatia_package from './component/pages/Croatia_package';
import America_package from './component/pages/America_package';
import Account from './component/common/Account';
import Payment from './component/Test/Payment';
import { useEffect } from 'react';
import Terms_Condition from './component/common/Terms_Condition';
import PaymentResponse from './component/Test/Payment_res';
function App() {

  const isAuth = sessionStorage.getItem('isAuth')

  // useEffect(() => {
  //   console.log('userid', sessionStorage.getItem('uid'))
  //   console.log('userid', userId)
  // })

  return (
    <UserProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Gmt />
        <Routes>
          {isAuth ? <Route path='/' element={<Home />} /> : <Route path='/' element={<Home />} />}
          <Route path='/thailand-tour-packages' element={<Tour_package />} />
          <Route path='/singapore-tour-packages' element={<Singapore_package />} />
          <Route path='/london-tour-packages' element={<Uk_packages />} />
          <Route path='/croatia-tour-packages' element={<Croatia_package />} />
          <Route path='/america-tour-packages' element={<America_package />} />
          <Route path="/:slug-package-booking/:packageid" element={<Book_packages />} />
          <Route path='/tour-packages' element={<Tour_packages />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/career' element={<Career />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/payment-response' element={<PaymentResponse />} />
          <Route path='/terms_and_condition' element={<Terms_Condition />} />
          <Route path="/thailand.php" element={<Navigate to="/thailand-tour-packages" replace />} />
          <Route path="/about.php" element={<Navigate to="/thailand-tour-packages" replace />} />
          <Route path="/contact.php" element={<Navigate to="/thailand-tour-packages" replace />} />
          <Route path="/embassies.php.php" element={<Navigate to="/thailand-tour-packages" replace />} />
          {isAuth ? (
            <>

              <Route path='/' element={<Home />} />
              <Route path='/thailand-tour-packages' element={<Tour_package />} />
              <Route path="/:slug-package-booking/:packageid" element={<Book_packages />} />
              <Route path='/tour-packages' element={<Tour_packages />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/account-settings' element={<Account />} />

            </>
          ) : (
            <Route path="/:slug" element={<UnderConstruction />} />
          )}
        </Routes>

      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
