import React, { useEffect , useState} from 'react'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";

function Sec_2_Booking() {
    const [date, setDate] = useState(new Date());
    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src =
            "https://tp.media/content?currency=inr&trs=377842&shmarker=596532.596532&show_hotels=true&powered_by=true&locale=en&searchUrl=search.hotellook.com&primary_override=%238F50FA&color_button=%238F50FA&color_icons=%238F50FA&secondary=%23FFFFFF&dark=%23262626&light=%23FFFFFF&special=%23C4C4C4&color_focused=%238F50FA&border_radius=10&plain=true&promo_id=7873&campaign_id=101";
        script.charset = "utf-8";

        const container = document.getElementById("tp-widget-container");
        if (container) {
            container.appendChild(script);
        }

        return () => {
            if (container) {
                container.innerHTML = "";
            }
        };
    }, []);

    https://tp.media/content?currency=usd&trs=377842&shmarker=596532&show_hotels=false&powered_by=true&locale=en&searchUrl=www.aviasales.com%2Fsearch&primary_override=%238F50FAff&color_button=%238F50FA&color_icons=%238F50FA&dark=%23262626&light=%23FFFFFF&secondary=%23FFFFFF&special=%23C4C4C4&color_focused=%238F50FA&border_radius=10&no_labels=&plain=true&promo_id=7879&campaign_id=100
    return (
        <>
            <section className='section-2 bg-orange d-flex justify-content-center' id="booking-section">

                <div className=" header-padding w-100 w-md-75 w-sm-100">
                    {/* <div id="tp-widget-container" className="tp-widget-wrapper"></div> */}
                    <div className="row g-smm-2  w-100 bg-white p-4 " >
                        <div className='col-md-4 col-12 px-2 '>
                            <h4 className='text-dark fs-4'>City Or Hotel</h4>
                            <input type='text' className='form-control fs-3' placeholder='City Or Hotel' />
                        </div>
                        <div className='col-md-2 col-12  px-2'>
                            <h4 className='text-dark fs-4'>Check In</h4>
                            {/* <input type='date' className='form-control fs-3 text-gray' placeholder='Check In' /> */}
                            <div className="input-group">
                                <Flatpickr
                                    id="date-picker"
                                    data-enable-time
                                    value={date}
                                    onChange={(selectedDates) => {
                                        setDate(selectedDates[0]); // Update the date
                                    }}
                                    className="form-control fs-3 text-gray"
                                    style={{ backgroundColor: "#ffff !important" }}
                                    options={{
                                        dateFormat: "d-m-Y", // Customize the date format
                                        minDate: "today", // Disable past dates
                                        altInput: true, // Enables an alternative input field
                                        altFormat: "d-m-Y", // Format for the placeholder
                                        placeholder: "Select a date", // Placeholder text
                                    }}
                                />
                                <span className="input-group-text bg-white border">
                                    <i className="bi bi-calendar-fill fs-3 text-purple"></i>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-2 col-12  px-2'>
                            <h4 className='text-dark fs-4'>Check Out</h4>
                            <div className="input-group">
                                <Flatpickr
                                    id="date-picker"
                                    data-enable-time
                                    value={date}
                                    onChange={(selectedDates) => {
                                        setDate(selectedDates[0]); // Update the date
                                    }}
                                    className="form-control fs-3 text-gray"
                                    style={{ backgroundColor: "#ffff !important" }}
                                    options={{
                                        dateFormat: "d-m-Y", // Customize the date format
                                        minDate: "today", // Disable past dates
                                        altInput: true, // Enables an alternative input field
                                        altFormat: "d-m-Y", // Format for the placeholder
                                        placeholder: "Select a date", // Placeholder text
                                    }}
                                />
                                <span className="input-group-text bg-white border">
                                    <i className="bi bi-calendar-fill fs-3 text-purple"></i>
                                </span>
                            </div>
                        </div>
                        <div className="col-md-2 col-12 px-2">
                            <h4 className="text-dark fs-4">Guests</h4>
                            <div className="dropdown">
                                <button
                                    className="btn form-select text-start border fs-3"
                                    type="button"
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    Guests
                                </button>
                                <ul className="dropdown-menu w-100 p-2" aria-labelledby="dropdownMenuButton1">
                                    <li className="d-flex justify-content-between">
                                        <h4 className="fs-3">Adults</h4>
                                        <div
                                            className="d-flex"
                                            onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
                                        >
                                            <button className="btn "><i class="bi bi-dash-square fs-3 text-purple"></i></button>
                                            <h4 className="fs-3 mx-1">{0}</h4>
                                            <button className="btn "><i class="bi bi-plus-square fs-3 text-purple"></i></button>
                                        </div>
                                    </li>
                                    <li className="d-flex justify-content-between">
                                        <h4 className="fs-3">Children</h4>
                                        <div
                                            className="d-flex"
                                            onClick={(event) => event.stopPropagation()} // Prevent dropdown from closing
                                        >
                                            <button className="btn "><i class="bi bi-dash-square fs-3 text-purple"></i></button>
                                            <h4 className="fs-3 mx-1">{0}</h4>
                                            <button className="btn "><i class="bi bi-plus-square fs-3 text-purple"></i></button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-2 col-12 px-2 '>
                            <h4 className='text-gray fw-400 fs-4'>Upto 30% discount</h4>
                            <button className='fs-3 btn btn-purple  w-100'>Search</button>
                        </div>
                    </div>
                  
                </div>
            </section>
        </>
    )
}

export default Sec_2_Booking
