import React from 'react'
import Header from '../common/Header'

const Career = () => {
  return (
      <>
          <Header/>
          <section className='h-100vh paddingtop padding-top-mobile header-padding  mb-3'>
              <div className='mt-3'>
                  <h2 className='text-dark mt-5 mb-2'>Career And Profession</h2>
                  <div>
                      <h2 className='fs-2 fw-400'>Explore Your Potential & Make Career With Chhuttiyan.com, Best Professional Atmosphere For Growth.</h2>
                  </div>
                  <div className="d-flex justify-content-between mt-3">
                      <h2 className='fs-1 text-dark mb-1'>Job Profile</h2>
                      <h2 className='btn fs-2 btn-purple mb-1 ' onClick={() => {
                          document.getElementById('development')?.scrollIntoView({ behavior: 'smooth' });
                      }}> Apply For Web development </h2>
                  </div>
                  <div className='border p-2 px-3' id='tourisum'>
                      <div className="d-flex justify-content-between">
                      <h2 className='fs-1 text-dark mb-1'>1. Tourism  And Hospitality </h2>
    
                      </div>

                      <h2 className='fs-2 text-dark mb-2'>About the Job And Responsibilities </h2>
                      <h2 className='fs-2 text-dark '>1. Retail Sales </h2>
                      <h2 className='fs-2 text-dark '>2. Corporate Sales </h2>
                      <h2 className='fs-2 text-dark mb-3'>3. Institution Sales </h2>
                          
                      <h2 className='fs-2 text-dark mb-2 '>Salary : <span className='fw-400'>₹25000-₹35000/-  per month</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Commission : <span className='fw-400'>Upto ₹50000/-  per month</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Job Location : <span className='fw-400'>Chandigarh, India ( onsite )</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Experience : <span className='fw-400'>Min 1 year</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Vacancy : <span className='fw-400'>10</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Responsibilities :</h2>
                      <h2 className='fs-2 text-dark mb-2 fw-600'>Outbound Tele calling: </h2>
                      <ul className=''>
                          <li className='fs-2 fw-light'> The primary responsibility of this role is to sell our back-office outsourcing solutions to potential customers.</li>
                          <li className='fs-2 fw-light'> Make a high volume of outbound calls to potential customers.</li>
                          <li className='fs-2 fw-light'> Engage prospects in meaningful conversations to understand their business needs.</li>
                          <li className='fs-2 fw-light'> Present and explain the benefits of PV’s back-office outsourcing solutions.</li>
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 fw-600'>Sales Pitch and Proposal: </h2>
                      <ul className=''>
                          <li className='fs-2 fw-light'> Follow up on leads and maintain a consistent sales pipeline.</li>
                          <li className='fs-2 fw-light'> Propose customized back-office solutions that address specific customer pain points.</li>
                          <li className='fs-2 fw-light'> Deliver compelling sales pitches tailored to the needs of each prospect.</li>
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 fw-600'>Relationship Building: </h2>
                      <ul className=''>
                          <li className='fs-2 fw-light'> Provide excellent customer service to ensure customer satisfaction and loyalty.</li>
                          <li className='fs-2 fw-light'> Act as a point of contact for customer queries and concerns.</li>
                          <li className='fs-2 fw-light'> Build and maintain strong relationships with potential and existing customers.</li>
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 fw-600'>Sales Target Achievement: </h2>
                      <ul className=''>
                          <li className='fs-2 fw-light'> Meet or exceed monthly and quarterly sales targets.</li>
                          <li className='fs-2 fw-light'> Track and report on sales performance metrics.</li>

                      </ul>
                      <h2 className='fs-2 text-dark mb-2 '>Requirements</h2>

                      <h2 className='fs-2 text-dark mb-2 fw-600'>Qualifications: </h2>
                      <ul className=''>
                          <li className='fs-2 fw-light'> Minimum 12th Grade</li>
                          <li className='fs-2 fw-light'> B.S.C In Tourisum And Hospitality</li>
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 fw-600'>Experience: </h2>
                      <ul className=''>
                          <li className='fs-2 fw-light'> Proven experience in Tele calling sales, preferably in selling B2B solutions.</li>
                          <li className='fs-2 fw-light'> Experience in selling outsourcing solutions or related services is a plus.</li>
                          <li className='fs-2 fw-light'> Minimum of 1 years’ experience in similar roles.</li>
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 fw-600' >Skills:</h2>
                      <ul className='' id='development' >
                          <li className='fs-2 fw-light'> Resilience and ability to handle rejection positively.</li>
                          <li className='fs-2 fw-light'> Ability to understand and explain technical solutions to non-technical audiences.</li>
                          <li className='fs-2 fw-light'> Strong persuasion and negotiation abilities.</li>
                      </ul>
                      <button className='btn fs-2 btn-purple mb-3 fw-600 ' onClick={() => { window.open('https://docs.google.com/forms/d/e/1FAIpQLSehFmTXG1ZgW9LziktZNKI59y6ekICEJegaMruMnO82I0bMCg/viewform', '_blank') }}>Apply Now</button>
                  </div>
                  <div className='border p-2 px-3 my-3' >
                      <h2 className='fs-1 text-dark mb-1'>2. Web Development </h2>
                      {/* <button className='btn fs-2 btn-purple mb-3 fw-600 display-mobile' onClick={() => { window.open('https://docs.google.com/forms/d/e/1FAIpQLSczyzwKwwbNyxvJ-b6K-tSPgCZtUHDmzzYmVndT0woe_QKgyg/viewform?usp=dialog', '_blank') }}>Apply Now</button> */}
                      <h2 className='fs-2 text-dark mb-3'>About the job</h2>
                      <h2 className='fs-2 text-dark mb-2 '>Salary : <span className='fw-400'>₹25000-₹35000/-  per month</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Commission : <span className='fw-400'>Upto ₹25000-₹50000/-  per month</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Job Location : <span className='fw-400'>Chandigarh, India ( onsite )</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Experience : <span className='fw-400'>Min 1 year</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Vacancy : <span className='fw-400'>10</span> </h2>
                      <h2 className='fs-2 text-dark mb-2 '>Responsibilities :</h2>
             
                      <ul className=''>
                          <li className='fs-2 fw-light'> Develop And Build Responsive Web Pages Using HTML5, CSS, React js, Php Ci4, BootStrap 5, API Integration , REST API </li>
                          <li className='fs-2 fw-light'> Write clean and efficient HTML, CSS, and JavaScript to create user interfaces and ensure cross-browser compatibility </li>
                          <li className='fs-2 fw-light'> Proficient working knowledge of Git.</li>
                          <li className='fs-2 fw-light'> Working experience with React. (Redux would be a plus)</li>
                          <li className='fs-2 fw-light'> Develop and maintain automated testing frameworks to ensure high-quality code</li>
                          <li className='fs-2 fw-light'>Keep up-to-date with emerging trends and technologies in web development</li>
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 '>Required Skills :</h2>
             
                      <ul className=''>
                          <li className='fs-2 fw-light'>Tech Skills :- React js, HTML5, CSS, Bootstrap, Php, Rest API, Axios, API Integration, Troubleshooting  </li>
                          <li className='fs-2 fw-light'>Soft Skills :- Excellent Communication SKills</li>
                
                        
                      </ul>
                      <h2 className='fs-2 text-dark mb-2 '>Qualification :</h2>
             
                      <ul className=''>
                          <li className='fs-2 fw-light'> B.E / B.Tech In Computer Science  </li>
                          <li className='fs-2 fw-light'> Diploma In Computer Science  </li>
                          <li className='fs-2 fw-light'> 0 Backlogs </li>
                          <li className='fs-2 fw-light'> Min CGPA 7</li>
                        
                      </ul>
                    
                      <button className='btn fs-2 btn-purple mb-3 fw-600 ' onClick={() => { window.open('https://docs.google.com/forms/d/e/1FAIpQLSczyzwKwwbNyxvJ-b6K-tSPgCZtUHDmzzYmVndT0woe_QKgyg/viewform?usp=dialog', '_blank') }}>Apply Now</button>
                  </div>
                  
              </div>
              </section>
    </>
  )
}

export default Career
