import React from 'react';
import ReactDOM from 'react-dom/client';
import { ClerkProvider } from "@clerk/clerk-react";
import './index.css';
import './font.css';
import './mediaquery.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';  // Import BrowserRouter for routing
import { HelmetProvider } from 'react-helmet-async';  // Import HelmetProvider to manage <head> data for SEO

// Create the root element for the app
const root = ReactDOM.createRoot(document.getElementById('root'));
const frontendApi = "https://api.clerk.dev"
const publishableKey = "pk_test_dG91Y2hpbmctZ3JhY2tsZS0zMC5jbGVyay5hY2NvdW50cy5kZXYk"
// Rendering the app inside the BrowserRouter
root.render(
  <ClerkProvider frontendApi={frontendApi} publishableKey={publishableKey}>
  <HelmetProvider>
    <App />
    </HelmetProvider>
  </ClerkProvider>
);




