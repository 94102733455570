import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import trend1 from '../../assets/img/Hotel/trend1.png'
import trend2 from '../../assets/img/Hotel/trend_2.png'
import trend3 from '../../assets/img/Hotel/trend_3.png'
import trend4 from '../../assets/img/Hotel/trend_4.png'
import trend5 from '../../assets/img/Hotel/trend_5.png'
import trend6 from '../../assets/img/Hotel/trend_6.png'
import trend7 from '../../assets/img/Hotel/trend_7.png'
import trend8 from '../../assets/img/Hotel/trend_8.png'
const Sec_6_Trending = () => {
    const slide = [
        { img: trend1, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.7 },
        { img: trend2, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.8 },
        { img: trend3, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend4, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.5 },
        { img: trend5, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.4 },
        { img: trend6, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.4 },
        { img: trend7, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 }
   
    ]
    const slide2 = [
        { img: trend1, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.7 },
        { img: trend2, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.8 },
        { img: trend3, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend4, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.5 },
        { img: trend5, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.4 },
        { img: trend6, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.4 },
        { img: trend7, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 },
        { img: trend8, name: 'GRAND HOTEL', price: '999', city: 'Marid, Spain', rating: 4.6 }
   
    ]

    return (
        <>
            <section className='section-3 d-flex flex-column justify-content-center align-items-center py-5 bg-light' id='Trending'>
                <div className="w-75 w-md-90 h-100 d-flex flex-column justify-content-between px-2 mb-5">
                    <h2 className='fw-bold '>International Hotels </h2>
                    <p className='fs-2 mt-2 mb-sm-5 mb-0' style={{ textAlign: 'center' }}> International Hotels & Resorts Starting at Just <span className='text-orange fs-2'>₹999!</span>  </p>
                    <div className="row g-6 my-2  w-100 ">
                        {slide.map((item, index) => (
                            <div className="col-sm-3 col-6 mt-0 mt-5 px-2">
                                <div className="row shadow shadow-md">
                                    <div className="col-3">
                                        <img src={item.img} className='me-1  h-100 img-fluid' />
                                    </div>
                                    <div className="col-9 ">
                                        <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                                            <div className="d-sm-flex d-block justify-content-between w-100">
                                            <h2 className="fs-3">{item.name}</h2>
                                                {/* <h2 className="fs-3 fw-600">{item.rating}<FontAwesomeIcon
                                                    icon={faStar}
                                                    color='#FFD700'
                                                    className='ms-1'
                                                /></h2> */}
                                               
                                            </div>
                                            <h2 className="fs-3 text-orange my-2">From ₹{item.price}</h2>
                                            <h2 className="fs-4 text-gray fw-400">{item.city}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
                <div className="w-75 w-md-90 h-100 d-flex flex-column justify-content-between px-2 mt-5">
                    <h2 className='fw-bold '>Domestic Hotels </h2>
                    <p className='fs-2 mt-2 mb-sm-5 mb-0' style={{ textAlign: 'center' }}> Domestic Hotels & Resorts Starting at Just <span className='text-orange fs-2'>₹999!</span>  </p>
                    <div className="row g-6 my-2  w-100 ">
                        {slide2.map((item, index) => (
                            <div className="col-sm-3 col-6 mt-0 mt-5 px-2">
                                <div className="row shadow shadow-md">
                                    <div className="col-3">
                                        <img src={item.img} className='me-1  h-100 img-fluid' />
                                    </div>
                                    <div className="col-9 ">
                                        <div className='h-100 px-3 d-flex flex-column align-items-start justify-content-between'>
                                            <div className="d-sm-flex d-block justify-content-between w-100">
                                            <h2 className="fs-3">{item.name}</h2>
                                                {/* <h2 className="fs-3 fw-600">{item.rating}<FontAwesomeIcon
                                                    icon={faStar}
                                                    color='#FFD700'
                                                    className='ms-1'
                                                /></h2> */}
                                               
                                            </div>
                                            <h2 className="fs-3 text-orange my-2">From ₹{item.price}</h2>
                                            <h2 className="fs-4 text-gray fw-400">{item.city}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>
            </section>
        </>
    )
}

export default Sec_6_Trending
