import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import LazyImageWrapper from '../common/LazyImg';
import { NavLink, useNavigate } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap';
import Header_Canvas from './Header_Canvas';
import logo from '../../assets/img/logo/logo2.png';
import { Accordion } from 'react-bootstrap';
import Signup from './Signup';
import Notify_popup from './Notify_popup';
import avatar from '../../assets/img/icone/user.png'
import { GetUser } from '../Utility/API';

function Header({ refresh }) {
    const navigate = useNavigate()
    const isAuth = sessionStorage.getItem('isAuth')
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [form , setForm] = useState({});


    const [show, setShow] = useState(false)
    const [show2, setShow2] = useState(false)
    const [usertype, setUsertype] = useState('Guest')
    const [authtype, setAuthType] = useState('login')
    function scrollToSection(targetId) {
        const element = document.getElementById(targetId);

        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }



    // Prevent dropdown from closing when interacting with the accordion
    const handleAccordionClick = (e) => {
        e.stopPropagation(); // Stop event propagation
    };

    const handleClose = () => {
        setShow(false)
        window.location.reload()
    }
    const handleClose2 = () => {
        setShow(false)
    }
    const handleLogout = () => {
        sessionStorage.setItem('isAuth', '')
        sessionStorage.setItem('uid', '')
        setShow2(true)
        setTimeout(() => {
            setShow2(false)
            navigate('/')
        },2000)
    }

    const handleCloselogout = () => {
        setShow2(false)
    }
    const GetUserData = async () => {
        const userdata = await GetUser()
        setForm(userdata)
    }
    useEffect(() => {
        GetUserData()
    }, [])
    return (
        <>

            <header>

                <div className="container-fluid d-flex justify-content-between   p-4 py-1 header-padding  bg-theme">
                    <div className='d-flex py-1'>

                        <h4 href='tel:9814104562' className=" mb-0 pe-2 text-white fs-4 fs-sm-4 fw-light p-1 " ><i class="bi bi-telephone-fill"></i> +91 9530700321 </h4>
                        <h4 className=" me-2  p-2 text-white fs-4 fs-sm-4 fw-light border-4  display-none" >|</h4>
                        <div className=" d-none ">
                            <i class="bi bi-facebook fs-3 mx-2 socialicone"></i>
                            <i class="bi bi-youtube fs-3 mx-2 socialicone"></i>
                            <i class="bi bi-instagram fs-3 mx-2 socialicone"></i>
                            <i class="bi bi-twitter fs-3 mx-2 socialicone"></i>
                            <i class="bi bi-pinterest fs-3 mx-2 socialicone"></i>
                        </div>
                    </div>
                    <div className=''>
                        <div className='d-flex '>
                            {/* <h4 className=" me-2  p-2 text-white fs-4 fw-light border-4  " >Login</h4> */}
                            <Dropdown className='display-pc'>
                                <Dropdown.Toggle className='t me-2  p-2 bg-transparent border-0 text-white fs-4 fs-sm-4 fw-light border-4 ' id="dropdown-basic">
                                    Language <i class="bi bi-translate"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-3" className='fs-3 border-bottom py-2'>English</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>Hindi</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2" className='fs-3 border-bottom py-2'>Telugu</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2" className='fs-3 pt-2 pb-0'>Russian</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <h4 className=" me-2  p-2 text-white fs-4 fs-sm-4 fw-light border-4  display-pc" >|</h4>
                            <h4 className=" me-2  pe-2 text-white fs-4 fs-sm-4  fw-light mb-0 me-0 display-pc" >Franchise</h4>
                            <h4 className="mb-0 me-2  p-2 text-white fs-4 fs-sm-4 fw-light border-4   display-pc" >|</h4>
                            {isAuth ?
                                <Dropdown align="end">
                                    <Dropdown.Toggle className=' me-2  p-2 bg-transparent border-0 text-white fs-4 fs-sm-4 fw-light border-4 ' id="dropdown-basic">
                                        <i class="bi bi-person-circle me-2"></i>My Account
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='py-0'>
                                        <Dropdown.Item className='fs-2 border-bottom p-2 '>
                                            <div className="d-flex">
                                                <div>
                                                    <LazyImageWrapper
                                                        src={`https://chhuttiyan.com/backend/public/uploads/${form ? form.image : avatar}`}
                                                        alt="Image"
                                                        className="avatar rounded-circle"
                                                    />
                                                </div>
                                                <div>
                                                    <h4 className="fs-2-sm ms-2">{form?form.name:'Your Name'}</h4>
                                                    <h4 className="fs-3 ms-2">{form ? form.email : 'Your Name'}</h4>
                                                </div>
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='fs-3 p-2 py-3 border-bottom' onClick={() => { window.open('/account-settings') }}>
                                            <h4 className="fs-3 ms-2"><i class="bi bi-gear me-2"></i>Account Settings</h4>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='fs-3 p-2 py-3 border-bottom'>
                                            <h4 className="fs-3 ms-2"><i class="bi bi-bookmark-check me-2"></i>My Booking</h4>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='fs-3 p-2 py-3 border-bottom'>
                                            <h4 className="fs-3 ms-2"><i class="bi bi-info-circle me-2"></i>Support</h4>
                                        </Dropdown.Item>
                                        <Dropdown.Item className='fs-3 p-2 py-3 btn' onClick={handleLogout}>
                                            <h4 className="fs-3 ms-2"><i class="bi bi-box-arrow-left me-2"></i>Logout</h4>
                                        </Dropdown.Item>
                                        {/* <Dropdown.Item className='fs-3 p-2 bg-orange rounded-top-3 text-center' onClick={handleLogout}>
                                        <h4 className="fs-2-sm ms-2">Logout</h4>
                                    </Dropdown.Item> */}
                                    </Dropdown.Menu>
                                </Dropdown>
                                : <Dropdown>
                                    <Dropdown.Toggle className=' me-2  p-2 bg-transparent border-0 text-white fs-4 fs-sm-4 fw-light border-4 ' id="dropdown-basic">
                                        Login
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='py-0'>
                                        <Dropdown.Item className='fs-3 border-bottom  bg-light fw-bold text-dark' disabled={true} >Login As </Dropdown.Item>
                                        <Dropdown.Item className='fs-3 border-bottom btn py-2' onClick={() => { setShow(true); setUsertype('Guest'); setAuthType("login") }}>Guest User</Dropdown.Item>
                                        {/* <Dropdown.Item className='fs-3 border-bottom btn py-2' onClick={() => { setShow(true); setUsertype('Corporate'); setAuthType("login") }}>Corporate</Dropdown.Item>
                                        <Dropdown.Item className='fs-3 border-bottom btn py-2' onClick={() => { setShow(true); setUsertype('Hotels'); setAuthType("login") }}>Hotels</Dropdown.Item>
                                        <Dropdown.Item className='fs-3 border-bottom btn py-2' onClick={() => { setShow(true); setUsertype('Institution'); setAuthType("login") }}>Institutions</Dropdown.Item> */}
                                        <Dropdown.Item className='fs-3 bg-orange fw-bold  rounded-top-3' onClick={() => { setShow(true); setUsertype('Guest'); setAuthType("register") }}>Create Account</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>}

                            {/* <h4 className="mb-0 me-2  p-2 text-white fs-4 fs-sm-4 fw-light border-4  " >|</h4>
                            <h4 className=" me-2  pe-2 text-white fs-4 fs-sm-4  fw-light mb-0 me-0" >Register</h4> */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid border d-flex justify-content-between px-0 border-none py-2 headerstatic">
                    <div className='header-padding d-flex justify-content-between  w-25 w-md-100'>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center justify-content-start'>
                                <img src={logo} className='me-1 mainlogo' height={100} />
                                <h1 className="fw-800 text-white logo-text">Chhuttiyan.Com</h1>
                            </div>

                        </div>
                        <Header_Canvas />
                    </div>
                    <div>

                        <nav className="navbar navbar-expand-lg navbar-light py-0 nav-pc header-padding" >
                            <div className="container-fluid px-0 h-100">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse h-100" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 h-100">
                                        <div className='header-transparent d-flex  '>
                                            <li className="nav-item dropdown ">
                                                <NavLink to='/' className="nav-link text-white  fs-3 mx-2 fw-600" aria-current="page" >Home</NavLink>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <Dropdown
                                                    show={dropdownOpen}
                                                    onToggle={(isOpen) => setDropdownOpen(isOpen)}
                                                >
                                                    <Dropdown.Toggle
                                                        className="text-white bg-transparent border-0 fs-3 fw-600"
                                                        id="dropdown-basic"
                                                    >
                                                        Tour Packages
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu className='py-0'>
                                                        <Dropdown.Item className="fs-3 border-bottom bg-white px-1" onClick={handleAccordionClick}>
                                                            <Accordion className=''>
                                                                {/* Accordion Item 1 */}
                                                                <Accordion.Item eventKey="0" onClick={handleAccordionClick}>
                                                                    <Accordion.Header>
                                                                        <h3 className="fs-3">International Packages</h3>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <ul className='list-unstyled'>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/thailand-tour-packages') }}>Thailand</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/singapore-tour-packages') }}>Singapore</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/malaysia-tour-packages') }}>Malaysia</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/serbia-tour-packages') }}>Serbia</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/dubai-tour-packages') }}>Dubai</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/london-tour-packages') }}>UK-London</li>
                                                                            <li className='fs-3  py-2 pb-0 onhover' onClick={() => { navigate('/london-tour-packages') }}>USA</li>
                                                                        </ul>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>

                                                                {/* Accordion Item 2 */}
                                                                <Accordion.Item eventKey="1" onClick={handleAccordionClick}>
                                                                    <Accordion.Header>
                                                                        <h3 className="fs-3">Domestic Packages</h3>
                                                                    </Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <ul className='list-unstyled'>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/leh-ladakh-tour-packages') }}>Leh Ladakh</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/rajasthan-tour-packages') }}>Rajasthan</li>
                                                                            <li className='fs-3 border-bottom py-2 onhover' onClick={() => { navigate('/himachal-tour-packages') }}>Himachal</li>
                                                                            <li className='fs-3 border-bottom py-2 pb-0 onhover' onClick={() => { navigate('/spiti-valley-tour-packages') }}>Spiti Valley</li>
                                                                            <li className='fs-3 border-bottom py-2 pb-0 onhover' onClick={() => { navigate('/goa-tour-packages') }}>Goa</li>
                                                                            <li className='fs-3 '>
                                                                                <select className='form-select fs-3 px-0 text-dark'>
                                                                                    <option defaultValue>Motor Cycle Tour</option>
                                                                                    <option >Rajasthan</option>
                                                                                    <option >Leh Ladakh</option>
                                                                                    <option >Spiti Valley</option>
                                                                                    <option >Deccan Plateau</option>
                                                                                </select>
                                                                            </li>

                                                                        </ul>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="nav-item dropdown ">
                                                <Dropdown>
                                                    <Dropdown.Toggle className='text-white bg-transparent border-0 fs-3 fw-600' id="dropdown-basic">
                                                        Hotels
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className='fs-3 border-bottom'>International Hotels</Dropdown.Item>
                                                        <Dropdown.Item className='fs-3'>Domestic Hotels</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                {/* <NavLink className="nav-link text-white  fs-3 mx-2 fw-600" aria-current="page" onClick={() => scrollToSection('packages')} >Tour Packages</NavLink> */}
                                            </li>
                                            {/* <li className="nav-item dropdown ">
                                                <NavLink className="nav-link  fs-3 mx-2   text-white fw-600" aria-current="page" >Flight Booking</NavLink>
                                            </li> */}
                                            <li className="nav-item  header-transparent d-flex socialevent  px-2 rounded rounded-3">
                                                <Dropdown>
                                                    <Dropdown.Toggle className='text-white bg-transparent border-0 fs-3 fw-600' id="dropdown-basic">
                                                        List Your Business
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item className='fs-3 border-bottom'>List Your Hotel<span className='fs-4 text-green ms-3'>Free</span></Dropdown.Item>
                                                        <Dropdown.Item className='fs-3'>List Your Company <span className='fs-4 text-green ms-3'>Free</span></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                            <li className="nav-item dropdown ">
                                                <NavLink className="nav-link  fs-3 mx-2   text-white fw-600" aria-current="page" onClick={() => scrollToSection('Merchandise')}>Merchandise</NavLink>
                                            </li>
                                            <li className="nav-item dropdown  " onClick={() => navigate('/career')}>
                                                <NavLink className="nav-link  fs-3 mx-2   text-white fw-600" aria-current="page" >Career</NavLink>
                                            </li>
                                            {/* <li className="nav-item  header-transparent d-flex socialevent ">
                                                <NavLink className="nav-link  fs-3 mx-2  text-white fw-600" aria-current="page" href="#" >
                                                     Company </NavLink>
                                            </li> */}
                                            <li className="nav-item  header-transparent d-flex socialevent ms-2 ">
                                                <i class="bi bi-search me-2 fs-3 text-white"></i>
                                            </li>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>

                <Signup show={show} userType={usertype} authtype={authtype} onClose={handleClose} onClose2={handleClose2} />
                <Notify_popup show={show2} id={2} onClose={handleCloselogout} />
            </header>
        </>
    )
}

export default Header
